import React from 'react';
import {
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import Configs from 'configs';
import {
  Container,
  HideWithKeyboard,
  ModalConfirm,
  NavigationAction,
  Shape,
  Text,
  UserItem,
  Visitor,
} from 'components';
import {
  Icon,
  Input,
  TopNavigation,
  Button,
  Layout,
  Tooltip,
  useTheme,
} from '@ui-kitten/components';
import {
  useNavigation,
  DrawerActions,
  NavigationProp,
} from '@react-navigation/native';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useTranslation } from 'react-i18next';
import { useBoolean, useKeyboard, useLayout, useModal } from 'hooks';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';

import { Images } from 'assets/images';
import {
  EKeyAsyncStorage,
  IVisitor,
  IVisitReason,
  MobileAppOptions,
} from 'constants/types';
import { MainStackParamList } from 'navigation/types';
import { RefreshControl } from 'react-native-web-refresh-control';
import {
  deleteVisitorById,
  getAllVisitors,
  setIsDelete,
  setIsLoadingVisitor,
  visitorSelector,
} from 'store/slices/visitorSlice';
import { listSearch } from 'utils/listSearch';
import { setAppLoading } from 'store/slices/appSlice';
import { FlashList } from '@shopify/flash-list';

const initReason: IVisitReason = {
  id: 0,
  reason: 'All',
  optionDefaultReason: false,
};

const VisitorsScreen: React.FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const { navigate } = useNavigation<NavigationProp<MainStackParamList>>();
  const { visible: modalRef, hide, show } = useModal();
  const { bottom, height } = useLayout();
  const { t } = useTranslation(['common', 'visitor']);
  const { visitors, isLoadingVisitor, isDelete } =
    useAppSelector(visitorSelector);
  const { keyboardShown, keyboardHeight } = useKeyboard();
  const { getItem } = useAsyncStorage(EKeyAsyncStorage.visitors);
  const { getItem: getUser } = useAsyncStorage(EKeyAsyncStorage.user);

  const [visible, { on, off }] = useBoolean(false);
  const [search, setSearch] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [visitor, setVisitor] = React.useState<IVisitor>();
  const [visitorsFilter, setVisitorsFilter] = React.useState<IVisitor[]>([]);
  const [reasonFilter, setReasonFilter] =
    React.useState<IVisitReason>(initReason);
  const [user, setUser] = React.useState<MobileAppOptions>();

  React.useEffect(() => {
    (async () => {
      const _user = await getUser();

      if (_user) {
        setUser(JSON.parse(_user));
      }
    })();
  }, []);

  React.useEffect(() => {
    if (user !== undefined) {
      for (const reason of user.visitReasons) {
        if (reason.optionDefaultReason) {
          setReasonFilter(reason);
        }
      }
    }
  }, [user]);

  React.useEffect(() => {
    (async () => {
      dispatch(setIsLoadingVisitor(true));
      await new Promise((resolve) => setTimeout(resolve, 500));
      let results: IVisitor[] = [];
      const visitorsCache = await getItem();

      if (visitorsCache) {
        results = listSearch(JSON.parse(visitorsCache), search);

        if (reasonFilter.reason === 'All') {
          setVisitorsFilter(results);
        } else {
          results = results.filter(
            (i) =>
              i.visitReasonId === reasonFilter.id ||
              i.visitReason?.id === reasonFilter.id,
          );
          setVisitorsFilter(results);
        }
      }
      setTimeout(() => {
        dispatch(setIsLoadingVisitor(false));
        dispatch(setIsDelete(false));
      }, 500);
    })();
  }, [search, reasonFilter, isDelete]);

  const handlerSearch = React.useCallback(
    (e: string) => {
      setSearch(e);
      if (!e) {
        setVisitorsFilter(visitors);
        return;
      }
      setReasonFilter(initReason);
    },
    [visitors],
  );

  const handleRefetch = React.useCallback(async () => {
    setLoading(true);
    dispatch(getAllVisitors({ force: true, refresh: true }));
    setLoading(false);
  }, []);

  const handleDelete = React.useCallback(() => {
    hide();
    dispatch(setAppLoading(true));
    if (visitor && visitor.id) {
      dispatch(deleteVisitorById({ id: visitor.id }));
    }
  }, [visitor]);

  const handleCreateVisitor = React.useCallback(() => {
    navigate('Visitors', {
      screen: 'VisitorNewUpdate',
      params: { id: undefined },
    });
  }, []);

  // const renderItem = React.useCallback(
  //   (type: string | number, item: IVisitor) => {
  //     return (
  //       <View style={styles.item}>
  //         <Visitor
  //           onPress={() => {
  //             if (item.id) {
  //               navigate('Visitors', {
  //                 screen: 'VisitorProfile',
  //                 params: { id: item.id },
  //               });
  //             }
  //           }}
  //           onDelete={() => {
  //             setVisitor(item);
  //             setTimeout(() => {
  //               show();
  //             }, 200);
  //           }}
  //           onEdit={() =>
  //             navigate('Visitors', {
  //               screen: 'VisitorNewUpdate',
  //               params: { id: item.id },
  //             })
  //           }
  //           onInvite={() =>
  //             navigate('Invitations', {
  //               screen: 'InviteNew',
  //               params: { visitor: item, path: 'VisitorsScreen' },
  //             })
  //           }
  //           item={item}
  //         />
  //       </View>
  //     );
  //   },
  //   [],
  // );

  const renderItem = React.useCallback(({ item }: { item: IVisitor }) => {
    return (
      <View style={styles.item}>
        <Visitor
          onPress={() => {
            if (item.id) {
              dispatch(setIsLoadingVisitor(true));
              navigate('Visitors', {
                screen: 'VisitorProfile',
                params: { id: item.id },
              });
            }
          }}
          onDelete={() => {
            setVisitor(item);
            setTimeout(() => {
              show();
            }, 200);
          }}
          onEdit={() =>
            navigate('Visitors', {
              screen: 'VisitorNewUpdate',
              params: { id: item.id },
            })
          }
          onInvite={() =>
            navigate('Invitations', {
              screen: 'InviteNew',
              params: { visitor: item, path: 'VisitorsScreen' },
            })
          }
          item={item}
        />
      </View>
    );
  }, []);

  const renderLoadingItem = React.useCallback(() => {
    return (
      <View style={styles.item}>
        <Visitor isLoading item={undefined} />
      </View>
    );
  }, []);

  const renderToggleButton = React.useCallback(() => {
    return (
      <TouchableOpacity
        onPress={on}
        style={[
          styles.buttonFilter,
          { backgroundColor: theme['background-filter-color'] },
        ]}
      >
        <View style={styles.flexOne}>
          <Text category="b8" marginRight={12} numberOfLines={1}>
            {reasonFilter.reason}
          </Text>
        </View>
        <Icon
          pack="assets"
          name="filter"
          style={{ tintColor: theme['icon-basic-color'] }}
        />
      </TouchableOpacity>
    );
  }, [reasonFilter, theme]);

  return (
    <Container level="2" style={styles.container}>
      <TopNavigation
        style={styles.topNavigation}
        appearance="control"
        //title={t('visitor:find_visitor')}
        accessoryLeft={
          <View style={styles.header}>
            <NavigationAction
              icon="menu"
              status="basic"
              onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
            />
            <UserItem />
          </View>
        }
        accessoryRight={
          <Tooltip
            anchor={renderToggleButton}
            visible={visible}
            placement="bottom"
            onBackdropPress={off}
            contentContainerStyle={styles.contentContainerStyle}
            style={styles.tooltip}
          >
            <View>
              {user &&
                [initReason, ...user.visitReasons].map((i, idx) => {
                  return (
                    <TouchableOpacity
                      activeOpacity={0.7}
                      key={idx}
                      onPress={() => {
                        setReasonFilter(i);
                        off();
                      }}
                    >
                      <Text
                        marginBottom={idx < user.visitReasons.length ? 16 : 0}
                        category={i === reasonFilter ? 'b2' : 'b1'}
                        numberOfLines={1}
                      >
                        {i.reason}
                      </Text>
                    </TouchableOpacity>
                  );
                })}
            </View>
          </Tooltip>
        }
      />
      {isLoadingVisitor ? (
        <>
          <Layout level="2">
            <Input
              style={[
                styles.search,
                { backgroundColor: theme['background-basic-color-1'] },
              ]}
              value={search}
              size="large"
              textStyle={styles.textStyle}
              clearButtonMode="while-editing"
              placeholder={t('visitor:search_name')}
              onChangeText={handlerSearch}
              accessoryLeft={
                <Icon style={styles.icon} pack="assets" name="search" />
              }
            />
          </Layout>
          <FlashList
            data={Array(10).fill(10)}
            renderItem={renderLoadingItem}
            estimatedItemSize={80}
            keyExtractor={(i, index) => `${index}`}
            contentContainerStyle={{
              paddingBottom:
                Platform.OS === 'ios'
                  ? keyboardShown
                    ? keyboardHeight
                    : bottom + 58
                  : keyboardShown
                  ? 0
                  : bottom + 58,
            }}
          />
        </>
      ) : visitors.length ? (
        <>
          <Layout level="2">
            <Input
              style={[
                styles.search,
                { backgroundColor: theme['background-basic-color-1'] },
              ]}
              value={search}
              size="large"
              textStyle={styles.textStyle}
              clearButtonMode="while-editing"
              placeholder={t('visitor:search_name')}
              onChangeText={handlerSearch}
              accessoryLeft={
                <Icon style={styles.icon} pack="assets" name="search" />
              }
            />
          </Layout>
          <FlashList
            data={visitorsFilter}
            renderItem={renderItem}
            estimatedItemSize={80}
            keyExtractor={(i) => `${i.uuid}${i.id}`}
            refreshing={loading}
            onRefresh={handleRefetch}
            contentContainerStyle={{
              paddingBottom:
                Platform.OS === 'ios'
                  ? keyboardShown
                    ? keyboardHeight
                    : bottom + 58
                  : keyboardShown
                  ? 0
                  : bottom + 58,
            }}
          />
          {/* <RecyclerListView
            dataProvider={visitorProvider}
            layoutProvider={layoutProvider}
            rowRenderer={renderItem}
            renderAheadOffset={200}
            scrollViewProps={{
              contentContainerStyle: [
                {
                  paddingBottom:
                    Platform.OS === 'ios'
                      ? keyboardShown
                        ? keyboardHeight
                        : bottom + 58
                      : keyboardShown
                      ? 0
                      : bottom + 58,
                },
              ],
              indicatorStyle: 'black',
              refreshControl: (
                <RefreshControl
                  refreshing={loading}
                  onRefresh={handleRefetch}
                />
              ),
            }}
          /> */}
        </>
      ) : (
        <ScrollView
          refreshControl={
            <RefreshControl refreshing={loading} onRefresh={handleRefetch} />
          }
        >
          <View style={[styles.empty, { marginTop: height / 5 }]}>
            <Image
              source={Images.image_no_visitors}
              style={{ width: 187, height: 145 }}
            />
            <Text category="b2" marginTop={24}>
              It's More Fun with Friends
            </Text>
            <Text
              marginTop={4}
              marginBottom={16}
              category="c2"
              marginHorizontal={24}
              center
            >
              Press Add button or link below to add your person-circles
            </Text>
            <Button children="Add Visitor" onPress={handleCreateVisitor} />
          </View>
        </ScrollView>
      )}
      <HideWithKeyboard
        style={{
          right: 8,
          bottom: bottom + 58,
          position: 'absolute',
        }}
      >
        <Shape
          icon="add"
          status={Configs.ADD_BUTTON_STATUS}
          size="large"
          onPress={handleCreateVisitor}
        />
      </HideWithKeyboard>
      {visitor && (
        <ModalConfirm
          visible={modalRef}
          onBackdropPress={hide}
          title={`${t('common:confirm')}`}
          description={
            `${t('common:delete')} ` +
            `${visitor?.firstName !== null ? `${visitor?.firstName}` : ''}` +
            ` ${visitor?.lastName}?`
          }
          buttonLeft={{ onPress: hide, title: t('common:cancel') }}
          buttonRight={{ onPress: handleDelete, title: t('common:ok') }}
        />
      )}
    </Container>
  );
};

export default VisitorsScreen;

const styles = StyleSheet.create({
  container: {
    paddingBottom: 0,
  },
  search: {
    borderBottomWidth: 0,
    borderRadius: 8,
    shadowColor: 'rgba(29, 30, 44, 0.1)',
    shadowOffset: {
      width: 1,
      height: 12,
    },
    shadowOpacity: 0.2,
    shadowRadius: 12,
    elevation: 10,
    marginHorizontal: 24,
    marginVertical: 16,
  },
  icon: {
    marginHorizontal: 16,
  },
  empty: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  item: {
    marginBottom: 24,
  },
  viewSearch: {
    marginBottom: 16,
  },
  textStyle: {
    height: '100%',
  },
  tooltip: {
    borderWidth: 1,
    borderColor: 'transparent',
    width: 190,
    alignItems: 'flex-start',
  },
  buttonFilter: {
    flexDirection: 'row',
    width: 120,
    paddingVertical: 10,
    borderRadius: 6,
    paddingHorizontal: 12,
  },
  flexOne: {
    flex: 1,
  },
  contentContainerStyle: {
    marginTop: 8,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  topNavigation: {
    marginTop: 12,
  },
});
