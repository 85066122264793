import React from 'react';
import { StyleSheet, View } from 'react-native';
import { TopNavigation } from '@ui-kitten/components';
import { Container, NavigationAction, Text } from 'components';
import { WebView } from 'react-native-webview';
import Configs from 'configs';

import { Camera } from 'expo-camera';

const RegisterScreen = React.memo(() => {
  const url = Configs.REGISTER_LINK;
  const INJECTED_JAVASCRIPT = `(function() {
	window.ReactNativeWebView.postMessage(JSON.stringify(window.location));
    })();`;

  const [hasPermission, setHasPermission] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async () => {
      const { status } = await Camera.requestCameraPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  if (hasPermission === null) {
    return <View />;
  }

  if (hasPermission === false) {
    return (
      <View style={styles.container}>
        <Text>No access to camera</Text>
      </View>
    );
  }

  return (
    <Container>
      <TopNavigation accessoryLeft={<NavigationAction />} />
      {url && (
        <WebView
          source={{ uri: url }}
          originWhitelist={['*']}
          showsVerticalScrollIndicator={false}
          javaScriptEnabledAndroid={true}
          injectedJavaScript={INJECTED_JAVASCRIPT}
          mediaPlaybackRequiresUserAction={false}
          allowsInlineMediaPlayback
          javaScriptEnabled
        />
      )}
    </Container>
  );
});

export default RegisterScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
