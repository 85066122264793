import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import dayjs from 'dayjs';
import 'dayjs/locale/en';

//En
import common from './en/common.json';
import sign_in from './en/sign_in.json';
import home from './en/home.json';
import account_settings from './en/account_settings.json';
import visitor from './en/visitor.json';
import invite_visitor from './en/invite_visitor.json';
import calendar from './en/calendar.json';
import share_invite from './en/share_invite.json';
import request from './en/request.json';
import notification from './en/notification.json';

export const defaultNS = 'common';
declare module 'react-i18next' {
  interface CustomTypeOptions {
    defaultNS: 'common';
    resources: {
      common: typeof common;
      sign_in: typeof sign_in;
      home: typeof home;
      request: typeof request;
      account_settings: typeof account_settings;
      visitor: typeof visitor;
      invite_visitor: typeof invite_visitor;
      calendar: typeof calendar;
      share_invite: typeof share_invite;
      notification: typeof notification;
    };
  }
}

export const resources = {
  en: {
    common,
    request,
    sign_in,
    home,
    account_settings,
    visitor,
    invite_visitor,
    calendar,
    share_invite,
    notification,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: 'en',
  compatibilityJSON: 'v3',
  defaultNS,
  resources,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: true,
  },
});

i18n.on('languageChanged', (lng) => {
  dayjs.locale(lng);
});
