import React from 'react';
import { AuthStackParamList } from './types';
import createStackNavigator from './createStackNavigator';

import SignIn from 'screens/auth/SignIn';
import RegisterScreen from 'screens/auth/RegisterScreen';
import PrivacyPolicy from 'screens/auth/PrivacyPolicy';

const Stack = createStackNavigator<AuthStackParamList>();

const AuthNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName="SignIn"
    >
      <Stack.Screen name="SignIn" component={SignIn} />
      <Stack.Screen name="RegisterScreen" component={RegisterScreen} />
      <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicy} />
    </Stack.Navigator>
  );
};

export default AuthNavigator;
