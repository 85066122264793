export class ServerUnreachableError extends Error {

    constructor(message: string) {
        super(message);

        // because we are extending a built-in class
        Object.setPrototypeOf(this, ServerUnreachableError.prototype);
    }

    getErrorMessage() {
        return 'Something went wrong: ' + this.message;
    }
}
