import React from 'react';
import {
  View,
  StyleSheet,
  Animated,
  TouchableOpacity,
  ViewStyle,
  Platform,
  Image,
} from 'react-native';
import { useTheme, Icon } from '@ui-kitten/components';
import { useAppTheme, useLayout } from 'hooks';
import { useTranslation } from 'react-i18next';
import parsePhoneNumber from 'libphonenumber-js';

import Text from './Text';
//@ts-ignore
import UserAvatar from 'react-native-user-avatar';
import * as ImageCache from 'react-native-expo-image-cache';

import { IVisitor } from 'constants/types';
import { RectButton, Swipeable } from 'react-native-gesture-handler';
import { Skeleton } from 'moti/skeleton';

interface VisitorProps {
  isLoading?: boolean;
  style?: ViewStyle;
  item?: IVisitor;
  onPress?(): void;
  onDelete?(): void;
  onEdit?(): void;
  onInvite?(): void;
}

const Visitor: React.FC<VisitorProps> = ({
  isLoading,
  item,
  style,
  onPress,
  onDelete,
  onEdit,
  onInvite,
}) => {
  const theme = useTheme();
  const { theme: themes } = useAppTheme();
  const { width } = useLayout();
  const { t } = useTranslation();
  const refSwipeable = React.useRef<Swipeable>(null);

  const widthAction = 60 * (width / 375);

  const Spacer = ({ height = 16 }) => <View style={{ height }} />;

  if (isLoading || !item) {
    return (
      <View style={[styles.container, style]}>
        <View
          style={[
            styles.content,
            { backgroundColor: theme['background-basic-color-2'] },
          ]}
        >
          <Skeleton colorMode={themes} radius={12} height={42} width={42} />
          <View style={{ marginLeft: 12 }}>
            <Skeleton colorMode={themes} height={16} width="50%" />
            <Spacer height={4} />
            <Skeleton colorMode={themes} height={16} width="90%" />
          </View>
        </View>
      </View>
    );
  }

  const { firstName, lastName, mobileNumber, thumbnail } = item;

  const renderRightActions = () => {
    return (
      <>
        <RectButton
          style={[{ width: widthAction }]}
          onPress={() => {
            refSwipeable.current?.close();
            onInvite && onInvite();
          }}
          activeOpacity={0.7}
        >
          <Animated.View
            style={[
              styles.editAction,
              { backgroundColor: theme['color-primary-500'] },
            ]}
          >
            <View style={styles.iconView}>
              <Icon pack="assets" name="calendarFill" style={styles.icon20} />
            </View>
            <Text category="c5" status="white" marginTop={6}>
              {t('invite')}
            </Text>
          </Animated.View>
        </RectButton>
        <RectButton
          style={[{ width: widthAction, zIndex: 1 }]}
          onPress={() => {
            refSwipeable.current?.close();
            onEdit && onEdit();
          }}
          activeOpacity={0.7}
        >
          <Animated.View
            style={[
              styles.editAction,
              { backgroundColor: theme['color-info-500'] },
            ]}
          >
            <Icon pack="assets" name="edit" />
            <Text category="c5" status="white" marginTop={6}>
              {t('edit')}
            </Text>
          </Animated.View>
        </RectButton>
        <RectButton
          style={[{ width: widthAction, zIndex: 1 }]}
          onPress={() => {
            refSwipeable.current?.close();
            onDelete && onDelete();
          }}
          activeOpacity={0.54}
        >
          <Animated.View
            style={[
              styles.deleteAction,
              { backgroundColor: theme['color-danger-500'] },
            ]}
          >
            <Icon pack="assets" name="trash" />
            <Text category="c5" status="white" marginTop={6}>
              {t('delete')}
            </Text>
          </Animated.View>
        </RectButton>
      </>
    );
  };

  function getFormattedMobileNumber() {
    if (typeof mobileNumber === 'string') {
      return parsePhoneNumber(mobileNumber)?.format('INTERNATIONAL');
    }
  }

  return (
    <View style={[styles.container, style]}>
      <Swipeable
        ref={refSwipeable}
        friction={2}
        useNativeAnimations={false}
        enableTrackpadTwoFingerGesture
        rightThreshold={50}
        renderRightActions={renderRightActions}
      >
        <TouchableOpacity
          activeOpacity={1}
          onPress={onPress}
          style={[
            styles.content,
            { backgroundColor: theme['background-basic-color-2'] },
          ]}
        >
          {thumbnail ? (
            Platform.OS === 'web' ? (
              <Image source={{ uri: thumbnail }} style={styles.avatar} />
            ) : (
              <ImageCache.Image uri={thumbnail} style={styles.avatar} />
            )
          ) : (
            <UserAvatar
              size={56}
              style={styles.avatar}
              src="" // visitor avatar uri
              name={`${firstName} ${lastName}`}
              colors={[
                theme['color-primary-500'],
                theme['color-info-500'],
                theme['color-warning-500'],
                theme['color-danger-500'],
                theme['background-basic-color-6'],
                theme['background-basic-color-7'],
                theme['background-basic-color-8'],
                theme['background-basic-color-13'],
                theme['background-basic-color-14'],
              ]}
            />
          )}
          <View>
            <View style={{ paddingRight: 90 }}>
              <Text category="b2" numberOfLines={1} ellipsizeMode="tail">
                {firstName && `${firstName}`} {lastName}
              </Text>
            </View>
            <Text category="c1" marginTop={2} status="placeholder">
              {getFormattedMobileNumber()}
            </Text>
          </View>
        </TouchableOpacity>
      </Swipeable>
    </View>
  );
};

export default Visitor;

const styles = StyleSheet.create({
  container: {},
  deleteAction: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: -10,
  },
  editAction: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: -1,
  },
  avatar: {
    width: 56,
    height: 56,
    borderRadius: 20,
    marginRight: 12,
    overflow: 'hidden',
  },
  content: {
    marginLeft: 24,
    height: 56,
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon20: {
    width: 20,
    height: 20,
  },
  iconView: {
    marginTop: 4,
    width: 24,
    height: 24,
  },
});
