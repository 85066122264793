import React from 'react';
import { StyleSheet } from 'react-native';
import { Button, Modal, Layout } from '@ui-kitten/components';
import { EvaStatus } from '@ui-kitten/components/devsupport';
import { useTranslation } from 'react-i18next';

import Text from './Text';
import { useLayout } from 'hooks';

interface ModalProcessProps {
  visible: boolean;
  onBackdropPress(): void;
  title: string;
  description: string;
  button?: {
    title?: string;
    onPress?: () => void;
    status?: EvaStatus;
  };
}

function ModalProcess({
  title,
  description,
  button,
  visible,
  onBackdropPress,
}: ModalProcessProps) {
  const { width } = useLayout();
  const { t } = useTranslation('common');

  return (
    <Modal
      visible={visible}
      onBackdropPress={onBackdropPress}
      backdropStyle={[
        styles.container,
        {
          backgroundColor: 'rgba(30, 31, 32, 0.86)',
        },
      ]}
    >
      <Layout level="2" style={[styles.modal, { width: width - 48 }]}>
        <Text marginHorizontal={32} category="h6" center>
          {title}
        </Text>
        <Text marginTop={8} center marginHorizontal={24} category="b1">
          {description}
        </Text>
        <Button
          size="large"
          style={styles.button}
          onPress={button?.onPress}
          status={button?.status || 'basic'}
        >
          {button?.title || t('ok').toString()}
        </Button>
      </Layout>
    </Modal>
  );
}

export default React.forwardRef(ModalProcess) as (
  props: ModalProcessProps & {
    ref?: React.ForwardedRef<{ show: () => void; hide: () => void }>;
  },
) => ReturnType<typeof ModalProcess>;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  modal: {
    borderRadius: 24,
    paddingTop: 48,
    paddingBottom: 40,
    alignSelf: 'center',
    marginHorizontal: 16,
    paddingHorizontal: 16,
  },
  buttonView: {
    paddingHorizontal: 32,
    marginTop: 32,
  },
  button: {
    marginTop: 16,
  },
});
