import React from 'react';
import {
  View,
  StyleSheet,
  Animated,
  ViewStyle,
  TouchableOpacity,
} from 'react-native';
import { useTheme, Icon } from '@ui-kitten/components';
import { useAppTheme, useLayout } from 'hooks';
import { useTranslation } from 'react-i18next';

import Text from './Text';

import dayjs from 'dayjs';
import { ICredential } from 'constants/types';
import { RectButton, Swipeable } from 'react-native-gesture-handler';
import { Skeleton } from 'moti/skeleton';

interface CredentialProps {
  isLoading?: boolean;
  style?: ViewStyle;
  item?: ICredential;
  onCancel?(): void;
  onShare?(): void;
}

const Credential: React.FC<CredentialProps> = ({
  isLoading,
  item,
  style,
  onCancel,
  onShare,
}) => {
  const { width } = useLayout();
  const theme = useTheme();
  const { theme: themes } = useAppTheme();
  const { t } = useTranslation();
  const refSwipeable = React.useRef<Swipeable>(null);

  const widthAction = 81 * (width / 375);

  if (isLoading || !item) {
    return (
      <View style={[styles.container, style]}>
        <View
          style={[
            styles.content,
            { backgroundColor: theme['background-basic-color-1'] },
          ]}
        >
          <Skeleton colorMode={themes} height={16} width="50%" />
          <View style={styles.bottom}>
            <Skeleton colorMode={themes} height={16} width="100%" />
          </View>
        </View>
      </View>
    );
  }

  const { pin, activeDate, expiryDate, readerType } = item;

  const renderRightActions = () => {
    const handleShare = () => {
      refSwipeable.current?.close();
      onShare && onShare();
    };

    return (
      <>
        <RectButton
          style={[{ width: widthAction, marginBottom: 24 }]}
          onPress={handleShare}
          activeOpacity={0.54}
        >
          <Animated.View
            style={[
              styles.shareAction,
              { backgroundColor: theme['color-info-500'] },
            ]}
          >
            <Icon pack="assets" name="share" />
            <Text category="c5" status="white" marginTop={6}>
              {t('share')}
            </Text>
          </Animated.View>
        </RectButton>
        <RectButton
          style={[{ width: widthAction, marginBottom: 24 }]}
          onPress={() => {
            refSwipeable.current?.close();
            onCancel && onCancel();
          }}
          activeOpacity={0.54}
        >
          <Animated.View
            style={[
              styles.cancelAction,
              { backgroundColor: theme['color-danger-500'] },
            ]}
          >
            <Icon pack="assets" name="cancel" />
            <Text category="c5" status="white" marginTop={6}>
              {t('cancel')}
            </Text>
          </Animated.View>
        </RectButton>
      </>
    );
  };

  return (
    <View style={[styles.container, style]}>
      <Swipeable
        ref={refSwipeable}
        friction={2}
        useNativeAnimations={false}
        enableTrackpadTwoFingerGesture
        rightThreshold={50}
        renderRightActions={renderRightActions}
      >
        <TouchableOpacity
          activeOpacity={1}
          onPress={() => refSwipeable.current?.openRight()}
          style={[
            styles.content,
            { backgroundColor: theme['background-basic-color-1'] },
          ]}
        >
          <Text category="c1" status="danger">
            {readerType}
            {!!pin && (
              <Text marginLeft={8} category="c2-t" status="placeholder">
                {`   ${pin}`}
              </Text>
            )}
          </Text>
          <View style={styles.bottom}>
            <Text category="c2">When</Text>
            <View
              style={[
                styles.dot,
                { backgroundColor: theme['background-basic-color-4'] },
              ]}
            />
            <Text category="c2">
              {dayjs(activeDate).format('YYYY/MM/DD')} -{' '}
              {dayjs(expiryDate).format('YYYY/MM/DD')}
            </Text>
          </View>
        </TouchableOpacity>
      </Swipeable>
    </View>
  );
};

export default Credential;

const styles = StyleSheet.create({
  container: {
    marginRight: 24,
  },
  cancelAction: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: -1,
  },
  shareAction: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: -1,
  },
  content: {
    marginLeft: 24,
    height: 76,
    borderRadius: 16,
    padding: 16,
    shadowColor: 'rgba(29, 30, 44, 0.61)',
    marginBottom: 24,
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 5,
  },
  bottom: {
    flexDirection: 'row',
    marginTop: 8,
    alignItems: 'center',
  },
  dot: {
    width: 3,
    height: 3,
    borderRadius: 3,
    marginHorizontal: 8,
  },
});
