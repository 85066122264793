import { EV_TRACK_ENDPOINT } from 'api/api';
import { ERequest, request } from 'utils/request';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {IPushTokenPayload, IResponse, MobileAppOptions} from 'constants/types';

/**
 * Auth Login
 */
type AuthPayload = {
  username: string;
  password: string;
};

type AuthResult = {
  token: string;
  options: MobileAppOptions;
};

export const requestAuthLogin = async (
  payload: AuthPayload,
): Promise<AuthResult | undefined> => {
  AsyncStorage.clear();

  const url = `${EV_TRACK_ENDPOINT}/auth`;

  const { data } = await request(ERequest.POST, url, payload);

  return data;
};

/**
 * Auth Refresh
 */

type AuthRefreshResult = {
  token: string;
  options: MobileAppOptions;
};

export const requestAuthRefresh = async (): Promise<
  AuthRefreshResult | undefined
> => {
  const url = `${EV_TRACK_ENDPOINT}/auth/refresh`;

  const { data } = await request(ERequest.GET, url);

  return data;
};

/**
 * User Profile
 */

type UserProfilePayload = {
  passwordOld: string;
  password: string;
  passwordConfirm: string;
};

export const requestUserProfile = async (
  payload: UserProfilePayload,
): Promise<IResponse | undefined> => {
  try {
    const url = `${EV_TRACK_ENDPOINT}/profile`;

    const { data } = await request(ERequest.POST, url, payload);

    return data;
  } catch (error) {
    console.log('error', error);
  }
};


/**
 * Account Deactivation
 */
type AccountDeactivationRequest = {
  countersign: string;
};
export const requestAccountDeactivation = async (
    payload: AccountDeactivationRequest,
): Promise<IResponse | undefined> => {
  try {
    const url = `${EV_TRACK_ENDPOINT}/profile/deactivate`;

    const { data } = await request(ERequest.POST, url, payload);

    return data;
  } catch (error) {
    console.log('error', error);
  }
};


/**
 * Set Push Token on Server
 */
export const postPushTokenUpdate = async (
    payload: IPushTokenPayload,
): Promise<IResponse | undefined> => {
  try {
    const url = `${EV_TRACK_ENDPOINT}/auth/push-token`;
    const { data } = await request(ERequest.POST, url, payload);
    return data;
  } catch (error) {
    console.log('error', error);
  }
};
