export const listSearch = (list: any[], search: string) => {
    const results = [];
    /* Search Visitors for matching searchTerm */
    for (const item of list) {
        // Concat String Null Safe
        let probe = '';
        if (item.firstName != null) {
            probe = probe + item.firstName;
        }
        if (item.lastName != null) {
            probe = probe + item.lastName;
        }

        // drop all whitespace and drop case and https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
        probe = probe.replace(/\s/g, '').toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        search = search.replace(/\s/g, '').toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        // is searchTerm in probe
        if (probe.indexOf(search) > -1) {
            results.push(item);
        }
    }
    return results;
};
