import {PhoneNumber} from "expo-contacts";
import parsePhoneNumber from "libphonenumber-js";

export function extractContactNumber(phoneNumbers: PhoneNumber[] | undefined, defaultCountryCode: string | undefined) {
  if (!phoneNumbers) {
    return '';
  }

  let mobileNumber;
  // try and find mobile label number first
  for (const phoneNumber of phoneNumbers) {
    if (phoneNumber.label === 'mobile') {
      mobileNumber = parsePhoneNumber(
          //@ts-ignore
          phoneNumber.number,
          //@ts-ignore
          phoneNumber.countryCode?.toUpperCase() ? phoneNumber.countryCode?.toUpperCase() : defaultCountryCode,
      )?.format('INTERNATIONAL');
    }
  }

  // fallback and use isPrimary on android
  if (!mobileNumber) {
    for (const phoneNumber of phoneNumbers) {
      if (phoneNumber.isPrimary) {
        mobileNumber = parsePhoneNumber(
            //@ts-ignore
            phoneNumber.number,
            //@ts-ignore
            phoneNumber.countryCode?.toUpperCase() ? phoneNumber.countryCode?.toUpperCase() : defaultCountryCode,
        )?.format('INTERNATIONAL');
      }
    }
  }

  // fallback to first number if mobile label is not found
  if (!mobileNumber) {
    mobileNumber = parsePhoneNumber(
        //@ts-ignore
        phoneNumbers[0].number,
        //@ts-ignore
        phoneNumbers[0].number.countryCode?.toUpperCase() ? phoneNumbers[0].number.countryCode?.toUpperCase() : defaultCountryCode,
    )?.format('INTERNATIONAL');
  }

  // use unformatted number if we were not able to parse number
  return mobileNumber ? mobileNumber : phoneNumbers[0].number;
}
