import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';
import { AppThunk } from 'store/store';
import {
  IApproveRequest,
  IApprovalAuthorisationRequest,
} from 'constants/types';
import {
  requestListPendingVisitorRegistrations,
  requestApproveVisitor,
  requestDenyVisitor,
} from 'api/process';
import { setAppLoading } from './appSlice';
import dayjs from 'dayjs';
import { navigate } from 'navigation/RootNavigation';
import { Toast } from 'react-native-toast-notifications';

export interface IProcessState {
  openModalRequest: boolean;
  listPending: IApproveRequest[];
  isLoadedListPending: boolean;
}

export const initialState: IProcessState = {
  listPending: [],
  openModalRequest: false,
  isLoadedListPending: false,
};

export const processSlice = createSlice({
  name: 'process',
  initialState,
  reducers: {
    setListPending: (
      state: IProcessState,
      { payload }: PayloadAction<IApproveRequest[]>,
    ) => {
      state.listPending = payload;
    },
    setOpenModalRequest: (
      state: IProcessState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.openModalRequest = payload;
    },
    setIsLoadedListPending: (
      state: IProcessState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isLoadedListPending = payload;
    },
  },
});

export const { setListPending, setOpenModalRequest, setIsLoadedListPending } =
  processSlice.actions;

export const processSelector = (state: RootState) => state.process;

export const getListPending =
  ({
    refresh = false,
    initialization,
  }: {
    refresh?: boolean;
    initialization?: boolean;
  }): AppThunk =>
  async (dispatch) => {
    console.log('IN getListPending API');
    try {
      if (!refresh) {
        dispatch(setAppLoading(true));
      }
      const result = await requestListPendingVisitorRegistrations();

      if (result) {
        if (!refresh) {
          dispatch(setAppLoading(false));
        }
        dispatch(setListPending(result));

        if (initialization && result.length > 0) {
          setTimeout(() => {
            navigate('Requests');
          }, 1000);
        }
        dispatch(setIsLoadedListPending(true));
      }
    } catch (error) {
      dispatch(setAppLoading(false));
    }
  };

export const approveVisitor =
  (payload: IApprovalAuthorisationRequest): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoading(true));
      const result = await requestApproveVisitor(payload);

      if (result) {
        Toast.show('Visitor Approved Successfully', { type: 'success' });
        // set timeout to debounce
        setTimeout(() => {
          dispatch(getListPending({ refresh: true }));
          dispatch(setAppLoading(false));
        }, 1000);
      }
    } catch (error) {
      dispatch(setAppLoading(false));
      dispatch(getListPending({ refresh: true }));
    }
  };

export const denyVisitor =
  (payload: IApprovalAuthorisationRequest): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoading(true));
      const result = await requestDenyVisitor(payload);

      if (result) {
        Toast.show('Visitor Denied Entry', { type: 'danger' });
        setTimeout(() => {
          dispatch(setAppLoading(false));
          dispatch(getListPending({ refresh: true }));
        }, 1000);
      }
    } catch (error) {
      dispatch(setAppLoading(false));
      dispatch(getListPending({ refresh: true }));
    }
  };

export const removeApprovalResult =
  (): AppThunk => async (dispatch, getState) => {
    const { listPending } = processSelector(getState());
    const list = listPending.filter((i) => {
      return i.timeout >= dayjs().unix();
    });

    if (listPending.length != list.length) {
      dispatch(setListPending(list));
    }
  };

export default processSlice.reducer;
