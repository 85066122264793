import React from 'react';
import {
  StyleSheet,
  FlatList,
  View,
  Platform,
  TouchableOpacity,
} from 'react-native';
import { Icon, Tooltip, TopNavigation, useTheme } from '@ui-kitten/components';
import {
  Container,
  HStack,
  NavigationAction,
  Text,
  UserItem,
  ModalCalendar,
} from 'components';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useTranslation } from 'react-i18next';
import { useBoolean, useDrawer, useLayout } from 'hooks';

import dayjs from 'utils/dayjs';
import keyExtractor from 'utils/keyExtractor';
import { EDirection, EEvent, FILTER_ENUM, IEntry } from 'constants/types';
import {
  getAllEvents,
  requestSelector,
  setEventType,
  setRange,
} from 'store/slices/requestSlide';
import { eventTypes } from 'constants/data';

const EventsScreen = React.memo(() => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { openDrawer } = useDrawer();
  const { bottom } = useLayout();
  const { t } = useTranslation(['common', 'home', 'request']);
  const { events, eventType, range } = useAppSelector(requestSelector);

  const [visible, { on, off }] = useBoolean(false);
  const [showModal, { on: onShow, off: offShow }] = useBoolean(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async () => {
      if (eventType.type !== FILTER_ENUM.CUSTOM_DATE) {
        dispatch(getAllEvents({ refresh: false, force: false }));
      }
    })();
  }, [eventType]);

  const handleRefetch = React.useCallback(() => {
    setLoading(true);
    if (eventType.type === FILTER_ENUM.CUSTOM_DATE) {
      dispatch(
        getAllEvents({
          from: range.startDate,
          until: range.endDate,
          refresh: true,
          force: true,
        }),
      );
    } else {
      dispatch(getAllEvents({ refresh: true, force: true }));
    }
    setLoading(false);
  }, [range, eventType]);

  const renderItem = React.useCallback(({ item }: { item: IEntry }) => {
    const { datetime, description, type, direction } = item;

    const getEvent = ({
      type,
      direction,
    }: {
      type: EEvent;
      direction: EDirection;
    }) => {
      switch (type) {
        case EEvent.EVENT_ACCESS_GRANTED:
          if (direction === EDirection.IN) {
            return EDirection.IN;
          } else if (direction === EDirection.OUT) {
            return EDirection.OUT;
          } else {
            return EDirection.GRANTED;
          }
        case EEvent.EVENT_ACCESS_OFFLINE:
          if (direction === EDirection.IN) {
            return 'OFFLINE IN';
          } else if (direction === EDirection.OUT) {
            return 'OFFLINE OUT';
          } else {
            return 'OFFLINE';
          }
        case EEvent.VISITOR_REGISTRATION:
          return 'NEW INVITE';
        default:
          return type;
      }
    };

    return (
      <HStack
        justify="flex-start"
        level="1"
        style={[styles.activity, styles.shadow]}
      >
        <View style={styles.row}>
          <View>
            <Text category="c3-m" marginTop={4}>
              {dayjs(datetime).format('DD MMM YYYY HH:mm')}
            </Text>
            <Text
              category="c2"
              marginTop={8}
              status={
                type === EEvent.EVENT_ACCESS_GRANTED &&
                direction == EDirection.IN
                  ? 'green'
                  : type === EEvent.EVENT_ACCESS_GRANTED &&
                    direction == EDirection.OUT
                  ? 'danger'
                  : type === EEvent.EVENT_ACCESS_OFFLINE
                  ? 'danger'
                  : type === EEvent.VISITOR_REGISTRATION
                  ? 'blue'
                  : 'basic'
              }
            >
              {getEvent({ type: type, direction: direction })}
            </Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text marginLeft={16} category="c1" status="placeholder">
              {description}
            </Text>
          </View>
        </View>
      </HStack>
    );
  }, []);

  const renderToggleButton = React.useCallback(() => {
    return (
      <TouchableOpacity
        onPress={on}
        style={[
          styles.buttonFilter,
          { backgroundColor: theme['background-filter-color'] },
        ]}
      >
        <View style={styles.flexOne}>
          <Text category="b8" marginRight={12} numberOfLines={1} capitalize>
            {eventType.title}
          </Text>
        </View>
        <Icon
          pack="assets"
          name="filter"
          style={{ tintColor: theme['icon-basic-color'] }}
        />
      </TouchableOpacity>
    );
  }, [eventType, theme]);

  return (
    <Container level="2">
      {/* @ts-ignore */}
      <TopNavigation
        style={styles.topNavigation}
        appearance="control"
        //title={t('request:activity')}
        accessoryLeft={
          <View style={styles.header}>
            <NavigationAction icon="menu" status="basic" onPress={openDrawer} />
            <UserItem />
          </View>
        }
        //@ts-ignore
        accessoryRight={
          <Tooltip
            anchor={renderToggleButton}
            visible={visible}
            placement="bottom"
            onBackdropPress={off}
            contentContainerStyle={styles.contentStyle}
            style={styles.tooltip}
          >
            <View>
              {eventTypes.length > 0 &&
                eventTypes.map((i, idx) => {
                  return (
                    <TouchableOpacity
                      activeOpacity={0.7}
                      key={idx}
                      onPress={() => {
                        off();
                        dispatch(setEventType(i));
                        if (i.type === FILTER_ENUM.CUSTOM_DATE) {
                          setTimeout(() => {
                            onShow();
                          }, 200);
                        }
                      }}
                    >
                      <Text
                        marginBottom={idx < eventTypes.length - 1 ? 16 : 0}
                        category={i.type === eventType.type ? 'b2' : 'b1'}
                        numberOfLines={1}
                        capitalize
                      >
                        {i.title}
                      </Text>
                    </TouchableOpacity>
                  );
                })}
            </View>
          </Tooltip>
        }
      />
      <FlatList
        data={events}
        renderItem={renderItem}
        refreshing={loading}
        onRefresh={handleRefetch}
        keyExtractor={keyExtractor}
        contentContainerStyle={[
          styles.contentContainerStyle,
          {
            paddingBottom: Platform.OS === 'ios' ? bottom + 16 : bottom + 58,
          },
        ]}
      />
      <ModalCalendar
        title={t('common:select_range')}
        visible={showModal}
        onBackdropPress={offShow}
        onButton={() => {
          offShow();
          dispatch(
            getAllEvents({
              from: range.startDate,
              until: range.endDate,
              refresh: true,
              force: true,
            }),
          );
        }}
        rangeCalendar
        range={range}
        onSelect={(e) => dispatch(setRange(e))}
        max={dayjs().toDate()}
      />
    </Container>
  );
});

export default EventsScreen;

const styles = StyleSheet.create({
  viewPager: {
    flex: 1,
  },
  activity: {
    borderRadius: 12,
    marginBottom: 16,
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  shadow: {
    shadowColor: 'rgba(29, 30, 44, 0.61)',
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 5,
  },
  contentContainerStyle: {
    paddingTop: 24,
    paddingHorizontal: 16,
  },
  row: {
    flexDirection: 'row',
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tooltip: {
    borderWidth: 1,
    borderColor: 'transparent',
    width: 190,
    alignItems: 'flex-start',
  },
  buttonFilter: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 140,
    height: 40,
    borderRadius: 6,
    paddingHorizontal: 12,
  },
  flexOne: {
    flex: 1,
  },
  top: {
    marginTop: 12,
  },
  contentStyle: {
    marginTop: 8,
  },
  topNavigation: {
    marginTop: 12,
  },
});
