import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { useTheme, Icon } from '@ui-kitten/components';
import { useLayout } from 'hooks';

import Text from './Text';
import { IAction } from 'constants/types';

interface ActionProps {
  item: IAction;
  appearance?: 'basic' | 'control';
}

const Action: React.FC<ActionProps> = ({ item, appearance = 'control' }) => {
  const theme = useTheme();
  const { width } = useLayout();
  const { icon, status, title, onPress } = item;

  const getColor = (
    status:
      | 'primary'
      | 'success'
      | 'green'
      | 'danger'
      | 'basic'
      | 'secondary'
      | 'orange'
      | 'yellow'
      | 'red',
  ): string => {
    switch (status) {
      case 'basic':
        return theme['background-basic-color-13'];
      case 'primary':
        return theme['color-primary-500'];
      case 'success':
        return theme['color-success-500'];
      case 'green':
        return theme['color-info-500'];
      case 'danger':
        return theme['color-danger-500'];
      case 'secondary':
        return theme['color-secondary-500'];
      case 'orange':
        return theme['color-orange-500'];
      case 'yellow':
        return theme['color-yellow-500'];
      case 'red':
        return theme['color-red-500'];
      default:
        return theme['background-basic-color-13'];
    }
  };

  return (
    <TouchableOpacity
      activeOpacity={0.7}
      onPress={onPress}
      style={[styles.container, { width: (width - 48) / 4 - 8 }]}
    >
      <View style={[styles.viewIcon, { backgroundColor: getColor(status) }]}>
        <Icon
          pack="assets"
          name={icon ? icon : 'back'}
          style={[
            styles.icon,
            {
              tintColor:
                appearance === 'basic'
                  ? theme['color-basic-100']
                  : theme['icon-control-color'],
            },
          ]}
        />
      </View>
      <Text category="c3-m" marginTop={8} center>
        {title}
      </Text>
    </TouchableOpacity>
  );
};

export default Action;

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 4,
    alignItems: 'center',
  },
  viewIcon: {
    width: 56,
    height: 56,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
  },
  icon: {
    width: 32,
    height: 32,
  },
  text: {
    height: 16,
    marginTop: 8,
  },
});
