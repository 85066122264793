/**
 * List App User Notifications
 */

import { INotification, INotificationModel } from 'constants/types';
import { ERequest, request } from 'utils/request';
import { EV_TRACK_ENDPOINT } from './api';

type ListUserNotificationsPayload = {
  start?: number;
  limit?: number;
};

export const requestListUserNotifications = async ({
  start = 0,
  limit = 500,
}: ListUserNotificationsPayload): Promise<INotificationModel | undefined> => {
  const url = `${EV_TRACK_ENDPOINT}/notifications`;

  const { data } = await request(ERequest.GET, url, { start, limit });

  return data;
};

/**
 * Read Notification Message
 */

type ReadNotificationMessagePayload = {
  uuid: string;
};

export const requestReadNotificationMessage = async ({
  uuid,
}: ReadNotificationMessagePayload): Promise<INotification | undefined> => {
  const url = `${EV_TRACK_ENDPOINT}/notifications/${uuid}`;

  const { data } = await request(ERequest.GET, url);

  return data;
};
