// @ts-nocheck

import Constants from 'expo-constants';

export default {
  API_ENDPOINT: Constants.expoConfig.extra.API_ENDPOINT,
  CACHE_TTL_SECS: Constants.expoConfig.extra.CACHE_TTL_SECS,
  CACHE_TTL_SECS_EVENT: Constants.expoConfig.extra.CACHE_TTL_SECS_EVENT,
  EXPO_STATE: Constants.expoConfig.extra.EXPO_STATE,
  JWT_MIN_TTL_PERCENTAGE: Constants.expoConfig.extra.JWT_MIN_TTL_PERCENTAGE,
  APP_THEME: Constants.expoConfig.extra.APP_THEME,
  APP_LOGO: Constants.expoConfig.extra.APP_LOGO,
  APP_LOGO_LIGHT: Constants.expoConfig.extra.APP_LOGO_LIGHT,
  ADD_BUTTON_STATUS: Constants.expoConfig.extra.ADD_BUTTON_STATUS,
  INVITATIONS_BUTTON_STATUS:
    Constants.expoConfig.extra.INVITATIONS_BUTTON_STATUS,
  EMERGENCY_BUTTON_STATUS: Constants.expoConfig.extra.EMERGENCY_BUTTON_STATUS,
  PROFILE_BUTTON_STATUS: Constants.expoConfig.extra.PROFILE_BUTTON_STATUS,
  REQUESTS_NOTIFY_STATUS: Constants.expoConfig.extra.REQUESTS_NOTIFY_STATUS,
  SEE_ALL_TEXT_STATUS: Constants.manifest?.extra.SEE_ALL_TEXT_STATUS,
  FORGOT_PASSWORD_TEXT_STATUS:
    Constants.expoConfig.extra.FORGOT_PASSWORD_TEXT_STATUS,
  LOGIN_LOGO_STYLE: Constants.expoConfig.extra.LOGIN_LOGO_STYLE,
  DRAWER_LOGO_STYLE: Constants.expoConfig.extra.DRAWER_LOGO_STYLE,
  FORGOT_LINK: Constants.expoConfig.extra.FORGOT_LINK,
  REGISTER_LINK: Constants.expoConfig.extra.REGISTER_LINK,
  PRIVACY_POLICY_LINK: Constants.expoConfig.extra.PRIVACY_POLICY_LINK,
  BUTTON_SHADOW_COLOR: Constants.expoConfig.extra.BUTTON_SHADOW_COLOR,
  ONESIGNAL_APP_ID: Constants.expoConfig.extra.ONESIGNAL_APP_ID,
};
