import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import {
  Container,
  Content,
  LoadingIndicator,
  NavigationAction,
  UserItem,
  Text,
  CheckItem,
} from 'components';
import {
  useTheme,
  TopNavigation,
  Input,
  Icon,
  Button,
} from '@ui-kitten/components';
import {
  useNavigation,
  DrawerActions,
  NavigationProp,
} from '@react-navigation/native';
import { useAuth, useBoolean } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useToast } from 'react-native-toast-notifications';
import {
  ruleConfirmPassword,
  ruleNewPassword,
  rulePassword,
} from 'utils/rules';
import { DrawerStackParamList } from 'navigation/types';

interface FormValues {
  old_password: string;
  password: string;
  confirm_password: string;
}

const pattern = /\d/;
const patternUppercase = /[A-Z]/;

const UpdateProfile: React.FC = () => {
  const theme = useTheme();
  const toast = useToast();
  const { t } = useTranslation(['common', 'account_settings']);
  const navigation = useNavigation();
  const { navigate } = useNavigation<NavigationProp<DrawerStackParamList>>();
  const { updatePassword, setToken } = useAuth();

  const [showOldPassword, { toggle: toggleOldPassword }] = useBoolean(true);
  const [showNewPassword, { toggle: toggleNewPassword }] = useBoolean(true);
  const [showConfirmPassword, { toggle: toggleConfirmPassword }] =
    useBoolean(true);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [focusNew, { on: onNew, off: offNew }] = useBoolean(false);
  const [focusConfirm, { on: onConfirm, off: offConfirm }] = useBoolean(false);

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
  } = useForm<FormValues>({
    defaultValues: {
      old_password: '',
      password: '',
      confirm_password: '',
    },
  });

  const onPressHandleDeactivateAccount = () => () => {
    navigate('DeleteAccount');
  };

  const handlePasswordUpdate = React.useCallback(async (data: FormValues) => {
    try {
      setLoading(true);
      if (data.password === data.confirm_password) {
        await updatePassword(
          data.old_password,
          data.password,
          data.confirm_password,
        ).then((i) => {
          if (i.reason === 'success') {
            toast.show(t('account_settings:message_success_password_changed'), {
              type: 'success',
              placement: 'top',
              successColor: theme['color-primary-500'],
              duration: 2000,
              animationType: 'slide-in',
              onClose: () => setToken(undefined),
            });
          } else {
            toast.show(i.message, {
              type: 'danger',
              placement: 'top',
              duration: 2000,
              dangerColor: theme['color-danger-500'],
              animationType: 'slide-in',
            });
          }
        });
      } else {
        setError('confirm_password', { message: `Password doesn't match.` });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const styles = StyleSheet.create({
    input: {
      marginTop: 24,
    },
    button: {
      marginTop: 24,
    },
    button_danger: {
      marginTop: 24,
      marginBottom: 36,
      backgroundColor: theme['color-danger-500'],
      borderColor: theme['color-danger-500'],
    },
    textStyle: {
      height: '100%',
    },
    header: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    check: {
      marginTop: 12,
    },
    topNavigation: {
      marginTop: 12,
    },
  });

  return (
    <Container level="2">
      <TopNavigation
        style={styles.topNavigation}
        appearance="control"
        title={t('account_settings:heading')}
        accessoryLeft={
          <View style={styles.header}>
            <NavigationAction
              icon="menu"
              status="basic"
              onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
            />
            <UserItem isShow={false} />
          </View>
        }
      />
      <Content isPadding>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              label={t('account_settings:old_password')}
              placeholder={t('account_settings:old_password')}
              style={styles.input}
              value={value}
              onChangeText={onChange}
              returnKeyType="next"
              status={errors.old_password ? 'danger' : 'primary'}
              onBlur={onBlur}
              secureTextEntry={showOldPassword}
              textContentType={'password'}
              autoCapitalize="none"
              textStyle={styles.textStyle}
              caption={errors.old_password?.message}
              accessoryRight={(props) => (
                <TouchableOpacity
                  activeOpacity={0.7}
                  onPress={toggleOldPassword}
                >
                  <Icon
                    pack="assets"
                    name={showOldPassword ? 'eyeOff' : 'eyeOn'}
                    {...props}
                  />
                </TouchableOpacity>
              )}
            />
          )}
          name="old_password"
          rules={rulePassword}
        />
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <Input
                label={t('account_settings:new_password')}
                placeholder={t('account_settings:new_password')}
                style={styles.input}
                value={value}
                onChangeText={onChange}
                returnKeyType="next"
                status={errors.password ? 'danger' : 'primary'}
                onBlur={offNew}
                onFocus={onNew}
                secureTextEntry={showNewPassword}
                textContentType={'newPassword'}
                textStyle={styles.textStyle}
                autoCapitalize="none"
                caption={errors.password?.message}
                accessoryRight={(props) => (
                  <TouchableOpacity
                    activeOpacity={0.7}
                    onPress={toggleNewPassword}
                  >
                    <Icon
                      pack="assets"
                      name={showNewPassword ? 'eyeOff' : 'eyeOn'}
                      {...props}
                    />
                  </TouchableOpacity>
                )}
              />
              {focusNew &&
                [
                  {
                    title: t('account_settings:password_rule_1'),
                    is_check: pattern.test(value),
                  },
                  {
                    title: t('account_settings:password_rule_2'),
                    is_check: patternUppercase.test(value),
                  },
                  {
                    title: t('account_settings:password_rule_3'),
                    is_check: value.length > 8 && value.length < 30,
                  },
                ].map((item, index) => {
                  return (
                    <CheckItem {...item} key={index} style={styles.check} />
                  );
                })}
            </>
          )}
          name="password"
          rules={ruleNewPassword}
        />
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <Input
                label={t('account_settings:confirm_password')}
                placeholder={t('account_settings:confirm_password')}
                style={styles.input}
                value={value}
                onChangeText={onChange}
                returnKeyType="done"
                status={errors.confirm_password ? 'danger' : 'primary'}
                onBlur={offConfirm}
                onFocus={onConfirm}
                secureTextEntry={showConfirmPassword}
                textContentType={'newPassword'}
                textStyle={styles.textStyle}
                autoCapitalize="none"
                caption={errors.confirm_password?.message}
                accessoryRight={(props) => (
                  <TouchableOpacity
                    activeOpacity={0.7}
                    onPress={toggleConfirmPassword}
                  >
                    <Icon
                      pack="assets"
                      name={showConfirmPassword ? 'eyeOff' : 'eyeOn'}
                      {...props}
                    />
                  </TouchableOpacity>
                )}
              />
              {focusConfirm &&
                [
                  {
                    title: t('account_settings:password_rule_1'),
                    is_check: pattern.test(value),
                  },
                  {
                    title: t('account_settings:password_rule_2'),
                    is_check: patternUppercase.test(value),
                  },
                  {
                    title: t('account_settings:password_rule_3'),
                    is_check: value.length > 8 && value.length < 30,
                  },
                ].map((item, index) => {
                  return (
                    <CheckItem {...item} key={index} style={styles.check} />
                  );
                })}
            </>
          )}
          name="confirm_password"
          rules={ruleConfirmPassword}
        />
        <Button
          children={t('common:update')}
          style={styles.button}
          onPress={handleSubmit(handlePasswordUpdate)}
          disabled={loading || isSubmitting}
          accessoryLeft={
            isSubmitting || loading ? (
              <LoadingIndicator size="small" />
            ) : undefined
          }
        />
        <Text marginTop={36} category="h7" status="danger">
          {t('account_settings:heading_delete_account')}
        </Text>
        <Text category="b4" marginTop={12}>
          {t('account_settings:delete_warning_1')}
        </Text>
        <Text category="b8" marginTop={12}>
          {t('account_settings:delete_warning_2')}
        </Text>
        <Button
          children={t('common:delete_account')}
          style={styles.button_danger}
          onPress={onPressHandleDeactivateAccount()}
        />
      </Content>
    </Container>
  );
};

export default UpdateProfile;
