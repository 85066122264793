import React from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { Skeleton } from 'moti/skeleton';
import { useAppTheme } from 'hooks';
import VStack from './VStack';
import HStack from './HStack';
import Container from './Container';
import { TopNavigation } from '@ui-kitten/components';
import NavigationAction from './NavigationAction';
import { DrawerActions, useNavigation } from '@react-navigation/native';
import { MODE } from 'constants/types';
import { useTranslation } from 'react-i18next';

interface LoadingVisitorProps {
  mode?: MODE | null;
}

const Spacer = ({ height = 16 }) => <View style={{ height }} />;

const LoadingVisitor: React.FC<LoadingVisitorProps> = ({ mode }) => {
  const { t } = useTranslation(['common', 'visitor']);
  const navigation = useNavigation();
  const { theme: themes } = useAppTheme();

  const Loading = ({
    flexOne,
    marginRight,
    marginTop,
  }: {
    flexOne?: boolean;
    marginRight?: number;
    marginTop?: number;
  }) => (
    <VStack
      style={[{ marginRight, marginTop: marginTop, flex: flexOne ? 1 : 0 }]}
    >
      <Skeleton colorMode={themes} width="40%" height={14} />
      <Spacer height={10} />
      <Skeleton colorMode={themes} width="100%" height={16} />
      <Spacer height={10} />
      <Skeleton colorMode={themes} width="100%" height={1} />
    </VStack>
  );

  return (
    <Container level="2">
      <TopNavigation
        style={styles.topNavigation}
        appearance="control"
        title={
          mode
            ? mode === MODE.EDIT
              ? t('visitor:update_visitor')
              : t('visitor:new_visitor')
            : undefined
        }
        //@ts-ignore
        accessoryLeft={
          <NavigationAction
            icon="menu"
            status="basic"
            onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
          />
        }
      />
      <ScrollView contentContainerStyle={styles.content}>
        <Spacer height={40} />
        <View style={styles.avatar}>
          <Skeleton colorMode={themes} height={120} width={120} />
        </View>
        <Spacer height={16} />
        <HStack>
          <Loading marginRight={16} flexOne />
          <Loading flexOne />
        </HStack>
        <Loading marginTop={24} />
        <Loading marginTop={24} />
        <Loading marginTop={24} />
        <Loading marginTop={24} />
        <Loading marginTop={24} />
      </ScrollView>
    </Container>
  );
};

export default LoadingVisitor;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  topNavigation: {
    marginTop: 12,
  },
  row: {
    flexDirection: 'row',
  },
  flexOne: {
    flex: 1,
  },
  content: {
    flex: 1,
    paddingHorizontal: 24,
    marginTop: 16,
  },
  avatar: {
    alignSelf: 'center',
    borderRadius: 36,
    overflow: 'hidden',
  },
});
