import React from 'react';
import { TopNavigation } from '@ui-kitten/components';
import { Container, NavigationAction } from 'components';
import { WebView } from 'react-native-webview';
import Configs from 'configs';

const PrivacyPolicy = React.memo(() => {
  const url = Configs.PRIVACY_POLICY_LINK;
  const INJECTED_JAVASCRIPT = `(function() {
	window.ReactNativeWebView.postMessage(JSON.stringify(window.location));
    })();`;

  return (
    <Container>
      <TopNavigation accessoryLeft={<NavigationAction />} />
      {url && (
        <WebView
          source={{ uri: url }}
          originWhitelist={['*']}
          showsVerticalScrollIndicator={false}
          javaScriptEnabledAndroid={true}
          injectedJavaScript={INJECTED_JAVASCRIPT}
          mediaPlaybackRequiresUserAction={false}
          allowsInlineMediaPlayback
          javaScriptEnabled
        />
      )}
    </Container>
  );
});

export default PrivacyPolicy;
