import React from 'react';
import {
  FlatList,
  Image,
  Platform,
  TouchableOpacity,
  View,
} from 'react-native';
import {
  TopNavigation,
  StyleService,
  useStyleSheet,
  Icon,
  Divider,
} from '@ui-kitten/components';
import * as Linking from 'expo-linking';
import { useTranslation } from 'react-i18next';
import { NavigationProp, useNavigation } from '@react-navigation/native';

import {
  Container,
  Text,
  VStack,
  NavigationAction,
  HStack,
  Shape,
  UserItem,
} from 'components';
import { IBooking } from 'constants/types';
import keyExtractor from 'utils/keyExtractor';
import { useDrawer, useLayout } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getAllBookings, requestSelector } from 'store/slices/requestSlide';
import { HomeMainParamList } from 'navigation/types';
import { parsePhoneNumber } from 'libphonenumber-js';
import * as ImageCache from 'react-native-expo-image-cache';

const BookingScreen = React.memo(() => {
  const { openDrawer } = useDrawer();
  const { navigate } = useNavigation<NavigationProp<HomeMainParamList>>();
  const dispatch = useAppDispatch();

  const { width } = useLayout();
  const styles = useStyleSheet(themedStyles);
  const { t } = useTranslation(['request', 'common']);
  const { bookings } = useAppSelector(requestSelector);

  const [isRefresh, setIsRefresh] = React.useState(false);

  const handleRefresh = () => {
    setIsRefresh(true);
    dispatch(getAllBookings());
    setIsRefresh(false);
  };
  const handleBooking = (item: IBooking) => () => {
    if (item.exterenalWindow || Platform.OS === 'web') {
      Linking.openURL(item.url);
    } else {
      navigate('WebViewScreen', { url: item.url });
    }
  };

  const renderItem = React.useCallback(({ item }: { item: IBooking }) => {
    const {
      pax,
      openTime,
      closeTime,
      telephoneNumber,
      banner,
      name,
      description,
    } = item;
    function renderPax() {
      if (!pax) {
        return;
      }
      return (
        <View style={[styles.row, { marginHorizontal: 16 }]}>
          <Icon pack="assets" name="baby" style={styles.icon} />
          <Text category="c2" marginLeft={8}>
            {pax}
          </Text>
        </View>
      );
    }

    function renderOpenCloseTimes() {
      if (!openTime || !closeTime) {
        return;
      }
      return (
        <View style={styles.row}>
          <Icon pack="assets" name="time" style={styles.icon} />
          <Text category="c2" marginLeft={5}>
            {openTime.slice(0, 5)} - {closeTime.slice(0, 5)}
          </Text>
        </View>
      );
    }

    function renderTelephoneNumber() {
      if (!telephoneNumber) {
        return;
      }

      const mobileNumber =
        typeof telephoneNumber === 'string'
          ? parsePhoneNumber(telephoneNumber)?.format('INTERNATIONAL')
          : '';
      return (
        <TouchableOpacity
          activeOpacity={0.7}
          onPress={() => Linking.openURL(`tel://${telephoneNumber}`)}
          style={styles.row}
        >
          <Icon pack="assets" name="call" style={styles.icon} />
          <Text category="c2" marginLeft={5}>
            {mobileNumber}
          </Text>
        </TouchableOpacity>
      );
    }

    return (
      <VStack
        padding={16}
        pb={0}
        level="1"
        mh={24}
        border={16}
        mb={24}
        style={styles.shadow}
        onPress={handleBooking(item)}
      >
        {Platform.OS === 'web' ? (
          <Image
            source={{ uri: banner }}
            style={{
              width: width - 80,
              aspectRatio: 13 / 4,
              borderRadius: 12,
            }}
          />
        ) : (
          <ImageCache.Image
            uri={banner}
            style={{
              width: width - 80,
              aspectRatio: 13 / 4,
              borderRadius: 12,
            }}
          />
        )}
        <HStack mt={16}>
          <VStack>
            <Text marginBottom={8} category="b4">
              {name}
            </Text>
            <Text marginBottom={8} category="c3" maxWidth={227 * (width / 375)}>
              {description}
            </Text>
          </VStack>
          <Shape icon="calendarFill" status="green" size="large" />
        </HStack>
        <Divider style={styles.divider} />
        <HStack itemsCenter justify="flex-start">
          {renderOpenCloseTimes()}
          {renderPax()}
          {renderTelephoneNumber()}
        </HStack>
      </VStack>
    );
  }, []);

  return (
    <Container level="2" style={styles.container}>
      <TopNavigation
        style={styles.topNavigation}
        appearance="control"
        title={t('request:bookings')}
        accessoryLeft={
          <View style={styles.header}>
            <NavigationAction icon="menu" status="basic" onPress={openDrawer} />
            <UserItem isShow={false} />
          </View>
        }
      />
      <FlatList
        data={bookings}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        indicatorStyle="black"
        refreshing={isRefresh}
        onRefresh={handleRefresh}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.contentContainerStyle}
      />
    </Container>
  );
});

export default BookingScreen;

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  contentContainerStyle: {
    paddingTop: 24,
    paddingBottom: 48,
  },
  shadow: {
    shadowColor: 'rgba(29, 30, 44, 0.61)',
    shadowOffset: {
      width: 2,
      height: 5,
    },
    shadowOpacity: 0.3,
    shadowRadius: 10,
    elevation: 5,
  },
  icon: {
    width: 16,
    height: 16,
    tintColor: 'text-placeholder-color',
  },
  divider: {
    marginTop: 8,
  },
  row: {
    flexDirection: 'row',
    paddingTop: 12,
    paddingBottom: 12,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  topNavigation: {
    marginTop: 12,
  },
});
