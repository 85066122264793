import React, { useState } from 'react';
import {
  Platform,
  Image,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import {
  useTheme,
  Modal,
  Layout,
  Avatar,
  Button,
  Icon,
} from '@ui-kitten/components';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useTranslation } from 'react-i18next';
import { useLayout } from 'hooks';

import Text from './Text';

import {
  approveVisitor,
  denyVisitor,
  getListPending,
  processSelector,
  removeApprovalResult,
} from 'store/slices/processSlide';
import { Images } from 'assets/images';
import { IApproveRequest } from 'constants/types';
import { getRemainingTimeUntilMsTimestamp } from 'hooks';

interface ModalApprovalRequestProps {
  title?: string;
  visible: boolean;
  onClose?(): void;
}
const defaultRemainingTime = {
  seconds: '00',
  minutes: '00',
  hours: '00',
  days: '00',
};

function ModalApprovalRequest({ visible, onClose }: ModalApprovalRequestProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'request']);
  const { width, height, top } = useLayout();

  const [forceHide, setForceHide] = useState(false);

  const { listPending, isLoadedListPending } = useAppSelector(processSelector);

  const [remainingTime, setRemainingTime] =
    React.useState(defaultRemainingTime);

  const [request, setRequest] = React.useState<IApproveRequest | undefined>(
    undefined,
  );

  React.useEffect(() => {
    dispatch(getListPending({ refresh: true }));
  }, []);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(removeApprovalResult());
    }, 500);
    return () => clearInterval(intervalId);
  }, []);

  React.useEffect(() => {
    if (listPending && listPending.length > 0) {
      setRequest(listPending[0]);
      setForceHide(false);
    } else {
      if (isLoadedListPending) {
        // console.log(
        //   'Modal Approval Request: loaded and no data, auto close dialog',
        // );
        setRequest(undefined);
        hide();
      }
    }
  }, [listPending, isLoadedListPending]);

  React.useEffect(() => {
    if (request) {
      const intervalId = setInterval(() => {
        updateRemainingTime(request.timeout * 1000);
      }, 200);
      return () => clearInterval(intervalId);
    }
  }, [request]);

  const data = React.useMemo(
    () => [
      {
        title: t('common:reason'),
        data: request?.reason || '',
      },
      {
        title: 'ID',
        data: request?.identity || '',
      },
      {
        title: t('common:mobile_number'),
        data: request?.mobile || '',
      },
      {
        title: t('common:company'),
        data: request?.company || '',
      },
    ],
    [request],
  );

  const hide = React.useCallback(() => {
    setForceHide(true);
    onClose && onClose();
  }, []);

  const updateRemainingTime = (countdown: number) => {
    setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));
  };

  const handleDeny = React.useCallback(async () => {
    if (request) {
      dispatch(
        denyVisitor({
          userUuid: request.userUuid,
          transactionUuid: request.uuid,
        }),
      );
    }
  }, [request]);

  const handleApprove = React.useCallback(() => {
    if (request) {
      dispatch(
        approveVisitor({
          userUuid: request.userUuid,
          transactionUuid: request.uuid,
        }),
      );
    }
  }, [request]);

  return (
    <Modal
        visible={visible && !forceHide && listPending.length > 0}
        onBackdropPress={hide}
      style={[
        styles.modalStyle,
        {
          height: height + top * 2,
        },
      ]}
      backdropStyle={styles.container}
    >
      <View
        style={[
          styles.modalContent,
          {
            height: height,
            width: width,
          },
        ]}
      >
        <TouchableOpacity
          activeOpacity={1}
          onPress={hide}
          style={StyleSheet.absoluteFillObject}
        />
        <Layout
          level="2"
          style={[styles.modalView, { minHeight: height * 0.6 }]}
        >
          <View
            style={[
              styles.box,
              {
                backgroundColor: theme['border-3-color'],
              },
            ]}
          />
          <View style={[styles.headerModal, { borderBottomColor: '#EBE3E3' }]}>
            <Text marginTop={24} category="t3" bold>
              {t('request:visitor_approval_request')}
            </Text>
          </View>
          {request && (
            <>
              <Layout level="1" style={styles.containerItem}>
                <Layout
                  style={[
                    styles.header,
                    { borderBottomColor: theme['background-basic-color-15'] },
                  ]}
                >
                  <Avatar
                    size="giant"
                    shape="rounded"
                    source={
                      request?.photo
                        ? { uri: `data:image/png;base64,${request?.photo}` }
                        : Images.avatar
                    }
                    style={styles.avatar}
                  />
                  <View>
                    <Text category="b4">
                      {request?.name} {request?.surname}
                    </Text>
                    <View style={{ flex: 1 }}>
                      <Text category="c1" status="placeholder" marginTop={8}>
                        {t('common:is_waiting_at')} {request?.accessPoint}
                      </Text>
                    </View>
                  </View>
                </Layout>
                <View style={styles.content}>
                  {data.map((i, key) => {
                    return (
                      i.data && (
                        <View key={key} style={styles.row}>
                          <Text category="c1">{i.title}</Text>
                          <View
                            style={[
                              styles.dot,
                              {
                                backgroundColor:
                                  theme['background-basic-color-4'],
                              },
                            ]}
                          />
                          <Text category="c2">{i.data}</Text>
                        </View>
                      )
                    );
                  })}
                </View>
              </Layout>
              <View style={styles.box1}>
                <Image source={Images.circle} style={styles.circle} />
                <View style={styles.child}>
                  <Text
                    category={remainingTime.minutes !== '00' ? 'h7' : 'h3'}
                    status="danger"
                  >
                    {remainingTime.minutes !== '00' &&
                      `${remainingTime.minutes}:`}
                    <Text
                      category={remainingTime.minutes !== '00' ? 'h7' : 'h3'}
                      status="danger"
                    >
                      {remainingTime.seconds}
                    </Text>
                  </Text>
                </View>
                <View
                  style={[
                    styles.dot1,
                    { backgroundColor: theme['color-green-500'] },
                  ]}
                />
              </View>
              <Text marginTop={24} center status="warning">
                {t('request:time_to_respond')}
              </Text>
              <View
                style={[
                  styles.buttonsView,
                  {
                    marginBottom: Platform.OS === 'ios' ? top * 2 : top,
                  },
                ]}
              >
                <Button
                  status="danger"
                  style={styles.deny}
                  children={t('common:deny')}
                  onPress={handleDeny}
                  accessoryLeft={<Icon pack="assets" name="deny" />}
                />
                <Button
                  status="info"
                  style={styles.approve}
                  children={t('common:approve')}
                  onPress={handleApprove}
                  accessoryLeft={<Icon pack="assets" name="approve" />}
                />
              </View>
            </>
          )}
        </Layout>
      </View>
    </Modal>
  );
}

export default React.forwardRef(ModalApprovalRequest) as (
  props: ModalApprovalRequestProps & {
    ref?: React.ForwardedRef<{ show: () => void; hide: () => void }>;
  },
) => ReturnType<typeof ModalApprovalRequest>;

const styles = StyleSheet.create({
  modalStyle: {
    backgroundColor: 'transparent',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: '100%',
    width: '100%',
  },
  modalContent: {
    flex: 1,
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(30, 31, 32, 0.86)',
  },
  box: {
    width: 48,
    height: 6,
    borderRadius: 6,
    backgroundColor: 'blue',
    alignSelf: 'center',
  },
  headerModal: {
    paddingHorizontal: 24,
    paddingBottom: 24,
    borderBottomWidth: 1,
  },
  modalView: {
    paddingTop: 12,
    paddingBottom: 8,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  container: {
    flex: 1,
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
  },
  containerItem: {
    borderRadius: 16,
    shadowColor: 'rgba(29, 30, 44, 0.61)',
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 5,
    marginHorizontal: 24,
    marginTop: 16,
  },
  header: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 8,
    borderBottomWidth: 1,
  },
  avatar: {
    marginRight: 8,
  },
  content: {
    padding: 16,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  dot: {
    width: 3,
    height: 3,
    borderRadius: 3,
    marginHorizontal: 8,
  },
  dot1: {
    width: 16,
    height: 16,
    borderRadius: 16,
    position: 'absolute',
    top: -4,
    right: -4,
  },
  child: {
    position: 'absolute',
    left: 12,
    top: 12,
    bottom: 12,
    right: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  box1: {
    height: 92,
    width: 92,
    alignSelf: 'center',
    marginTop: 32,
  },
  circle: {
    width: '100%',
    height: '100%',
  },
  buttonsView: {
    flexDirection: 'row',
    marginTop: 16,
    marginHorizontal: 40,
  },
  deny: {
    flex: 1,
    marginRight: 16,
  },
  approve: {
    flex: 1,
  },
});
