import React from 'react';
import { View, FlatList, StyleSheet } from 'react-native';
import { TopNavigation } from '@ui-kitten/components';
import { DrawerActions, useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import * as Linking from 'expo-linking';
import {
  Container,
  EmergencyItem,
  NavigationAction,
  UserItem,
} from 'components';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import { EKeyAsyncStorage, IEmergencyNumber } from 'constants/types';
import { useLayout } from 'hooks';

import keyExtractor from 'utils/keyExtractor';

const EmergencyNumbers = React.memo(() => {
  const { bottom } = useLayout();
  const { t } = useTranslation(['common', 'home', 'request']);

  const navigation = useNavigation();
  const [loading, setLoading] = React.useState<boolean>(false);

  const { getItem } = useAsyncStorage(EKeyAsyncStorage.emergencyNumbers);
  const [listEmergencyNumber, setListEmergencyNumber] = React.useState<
    Array<IEmergencyNumber>
  >([]);
  const readListFromStorage = async () => {
    const emergencyNumbers = await getItem();
    if (emergencyNumbers) {
      setListEmergencyNumber(JSON.parse(emergencyNumbers));
    }
  };

  React.useEffect(() => {
    readListFromStorage();
  }, []);

  const handleRefetch = React.useCallback(() => {
    setLoading(true);
    readListFromStorage();
    setLoading(false);
  }, []);

  const renderItem = React.useCallback(
    ({ item }: { item: IEmergencyNumber }) => {
      return (
        <EmergencyItem
          item={item}
          style={styles.item}
          onPress={() => Linking.openURL(`tel://${item.telephoneNumber}`)}
        />
      );
    },
    [],
  );

  return (
    <Container level={'2'}>
      <TopNavigation
        style={styles.topNavigation}
        appearance="control"
        title={t('request:emergency_numbers')}
        accessoryLeft={
          <View style={styles.header}>
            <NavigationAction
              icon="menu"
              status="basic"
              onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
            />
            <UserItem isShow={false} />
          </View>
        }
      />
      <FlatList
        data={listEmergencyNumber}
        renderItem={renderItem}
        scrollEventThrottle={16}
        refreshing={loading}
        onRefresh={handleRefetch}
        indicatorStyle="black"
        keyExtractor={keyExtractor}
        contentContainerStyle={[
          styles.contentContainerStyle,
          { paddingBottom: bottom + 24 },
        ]}
      />
    </Container>
  );
});

export default EmergencyNumbers;

const styles = StyleSheet.create({
  contentContainerStyle: {
    marginHorizontal: 24,
    marginTop: 16,
    marginBottom: 48,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  item: {
    marginBottom: 24,
  },
  topNavigation: {
    marginTop: 12,
  },
});
