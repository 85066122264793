import React from 'react';
import createStackNavigator from './createStackNavigator';

import { VisitorsMainParamList } from './types';

import VisitorsScreen from 'screens/visitors/VisitorsScreen';
import VisitorProfile from 'screens/visitors/VisitorProfile';
import VisitorNewUpdate from 'screens/visitors/VisitorNewUpdate';

const Stack = createStackNavigator<VisitorsMainParamList>();

const VisitorsMainNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName="VisitorsScreen"
    >
      <Stack.Screen name="VisitorsScreen" component={VisitorsScreen} />
      <Stack.Screen
        name="VisitorNewUpdate"
        component={VisitorNewUpdate}
        initialParams={{ id: undefined }}
      />
      <Stack.Screen name="VisitorProfile" component={VisitorProfile} />
    </Stack.Navigator>
  );
};

export default VisitorsMainNavigator;
