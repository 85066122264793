import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../rootReducer';

export interface IAppState {
  appLoading: boolean;
  currentRoute: string | undefined;
  showModalRequest: boolean;
}

export const initialState: IAppState = {
  appLoading: false,
  currentRoute: undefined,
  showModalRequest: true,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppLoading: (state: IAppState, { payload }: PayloadAction<boolean>) => {
      state.appLoading = payload;
    },
    setCurrentRoute: (
      state: IAppState,
      { payload }: PayloadAction<string | undefined>,
    ) => {
      state.currentRoute = payload;
    },
    setShowModalRequest: (
      state: IAppState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.showModalRequest = payload;
    },
  },
});

export const { setAppLoading, setCurrentRoute, setShowModalRequest } =
  appSlice.actions;

export const appSelector = (state: RootState) => state.app;

export default appSlice.reducer;
