import * as ImagePicker from 'expo-image-picker';

type ImagePickerCallBack = (image?: ImagePicker.ImagePickerAsset) => void;

const useImagePicker = () => {
  const takePhoto = async (callback?: ImagePickerCallBack) => {
    const permissionResult = await ImagePicker.requestCameraPermissionsAsync();

    if (permissionResult.granted === false) {
      alert("You've refused to allow this app to access your photos!");
      return;
    }

    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      base64: true,
      quality: 0.8,
    });

    if (result.canceled) {
      callback && callback();
    } else {
      if (result.assets && result.assets[0]) {
        callback && callback(result.assets[0]);
      }
    }
  };

  const choosePhoto = async (callback?: ImagePickerCallBack) => {
    const permissionResult =
      await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (permissionResult.granted === false) {
      alert("You've refused to allow this app to access your photos!");
      return;
    }

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      base64: true,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.8,
    });

    if (result.canceled) {
      callback && callback();
    } else {
      if (result.assets && result.assets[0]) {
        callback && callback(result.assets[0]);
      }
    }
  };
  return { takePhoto, choosePhoto };
};

export default useImagePicker;
