import React from 'react';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import useAuth from 'hooks/useAuth';
import { Platform } from 'react-native';
import { AndroidNotificationVisibility } from 'expo-notifications/src/NotificationChannelManager.types';

type Context = {
  expoPushToken: string;
};

export const NotificationsContext = React.createContext<Context>({
  expoPushToken: '',
});

interface NotificationsProviderProps {
  children: React.ReactElement;
}

export const NotificationsProvider: React.FC<NotificationsProviderProps> = ({
  children,
}) => {
  const { isInitializing, isAuthenticated } = useAuth();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [expoPushToken, setExpoPushToken] = React.useState<string>('');

  React.useEffect(() => {
    (async () => {
      if (Device.isDevice) {
        const { status: existingStatus } =
          await Notifications.getPermissionsAsync();

        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
          const { status } = await Notifications.requestPermissionsAsync();
          finalStatus = status;
        }
        if (finalStatus !== 'granted') {
          console.log('Failed to get push token for push notification!');
          return;
        }
      } else {
        console.log('Must use physical device for Push Notifications');
      }

      if (Platform.OS === 'android') {
        // Set Default channelID
        Notifications.setNotificationChannelAsync('FCM_PLUGIN_ACTIVITY', {
          name: 'FCM_PLUGIN_ACTIVITY',
          importance: Notifications.AndroidImportance.DEFAULT,
          vibrationPattern: [0, 250, 250, 250],
          lightColor: '#FF231F7C',
        });

        // Visitor Movement Update Changes
        await Notifications.setNotificationChannelAsync('FCM_NOTICE_CHANNEL', {
          name: 'Status Update',
          sound: 'notification3', // Provide ONLY the base filename
          lockscreenVisibility: AndroidNotificationVisibility.PRIVATE,
          importance: Notifications.AndroidImportance.DEFAULT,
        });

        // Current Channel for Visitor Approval Request
        // will be deprecated in future and replace with default sound only
        await Notifications.setNotificationChannelAsync('FCM_DEFAULT_CHANNEL', {
          name: 'Approval Request',
          sound: 'ringtone3',
          importance: Notifications.AndroidImportance.MAX,
          lockscreenVisibility: AndroidNotificationVisibility.PRIVATE,
          vibrationPattern: [0, 250, 250, 250],
          bypassDnd: true,
          enableLights: true,
          enableVibrate: true,
        });

        // Future Channel for Visitor Approval Request to replace FCM_DEFAULT_CHANNEL
        await Notifications.setNotificationChannelAsync('FCM_REQUEST_CHANNEL', {
          name: 'Approval Request',
          sound: 'ringtone3',
          importance: Notifications.AndroidImportance.MAX,
          lockscreenVisibility: AndroidNotificationVisibility.PRIVATE,
          vibrationPattern: [0, 250, 250, 250],
          bypassDnd: true,
          enableLights: true,
          enableVibrate: true,
        });
      }
    })();
  }, []);

  React.useEffect(() => {
    if (!isInitializing) {
      if (isAuthenticated) {
        clearAllNotifications();
      }
    }
  }, [isInitializing, isAuthenticated]);

  const clearAllNotifications = React.useCallback(async () => {
    if (Platform.OS === 'web') {
      return;
    }
    await Notifications.cancelAllScheduledNotificationsAsync();
  }, []);

  return (
    <NotificationsContext.Provider
      value={{
        expoPushToken,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
