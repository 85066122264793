import React from 'react';
import { TopNavigation } from '@ui-kitten/components';
import { useRoute } from '@react-navigation/native';
import { useLayout } from 'hooks';

import { Container, NavigationAction } from 'components';
import { WebViewScreenNavigationProp } from 'navigation/types';
import { WebView } from 'react-native-webview';

const WebViewScreen = React.memo(() => {
  const { bottom } = useLayout();

  const route = useRoute<WebViewScreenNavigationProp>();
  const url = route.params.url;
  const INJECTED_JAVASCRIPT = `(function() {
	window.ReactNativeWebView.postMessage(JSON.stringify(window.location));
    })();`;
  return (
    <Container style={{ paddingBottom: bottom + 58 }}>
      <TopNavigation accessoryLeft={<NavigationAction />} />
      {url && (
        <WebView
          source={{ uri: url }}
          originWhitelist={['*']}
          showsVerticalScrollIndicator={false}
          javaScriptEnabledAndroid={true}
          injectedJavaScript={INJECTED_JAVASCRIPT}
        />
      )}
    </Container>
  );
});

export default WebViewScreen;
