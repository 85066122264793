import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Text } from 'components';
import { useNavigation, NavigationProp } from '@react-navigation/native';
import { useTheme, Icon } from '@ui-kitten/components';
import { useAppSelector } from 'store/store';

import HomeMainNavigator from './HomeMainNavigator';
import RequestsMainNavigator from './RequestsNavigator';
import InvitesMainNavigator from './InvitesMainNavigator';
import VisitorsMainNavigator from './VisitorsMainNavigator';

import Configs from 'configs';
import { isEmpty } from 'lodash';
import { MainStackParamList } from './types';
import { processSelector } from 'store/slices/processSlide';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

const BottomTab = createBottomTabNavigator<MainStackParamList>();

const MainBottomTab: React.FC = () => {
  const theme = useTheme();
  const { navigate } = useNavigation<NavigationProp<MainStackParamList>>();
  const { listPending } = useAppSelector(processSelector);

  return (
    <BottomTab.Navigator
      initialRouteName="Home"
      screenOptions={{
        tabBarHideOnKeyboard: true,
        lazy: true,
        headerShown: false,
        tabBarStyle: {
          backgroundColor: theme['background-bottom-tab-color'],
          borderTopWidth: 0,
          borderTopLeftRadius: 24,
          borderTopRightRadius: 24,
          position: 'absolute',
          right: 0,
          left: 0,
          bottom: 0,
          shadowColor: 'rgba(29, 30, 44, 0.61)',
          shadowOffset: {
            width: 1,
            height: 12,
          },
          shadowOpacity: 0.2,
          shadowRadius: 12,
          elevation: 10,
        },
        tabBarActiveTintColor: theme['color-primary-500'],
        tabBarInactiveTintColor: theme['background-basic-color-4'],
        tabBarLabelPosition: 'below-icon',
      }}
    >
      <BottomTab.Screen
        name="Home"
        component={HomeMainNavigator}
        options={{
          tabBarButton: (props) => (
            <TouchableOpacity
              {...props}
              onPress={() => navigate('Home', { screen: 'HomeScreen' })}
            />
          ),
          tabBarIcon: ({ color }) => (
            <Icon
              style={[{ tintColor: color }, styles.icon]}
              pack="assets"
              name="home"
            />
          ),
        }}
      />
      <BottomTab.Screen
        name="Requests"
        component={RequestsMainNavigator}
        options={{
          tabBarButton: (props) => (
            <TouchableOpacity
              {...props}
              onPress={() =>
                navigate('Requests', { screen: 'ApproveRequests' })
              }
            />
          ),
          tabBarIcon: ({ color }) => (
            <View>
              <Icon
                style={[{ tintColor: color }, styles.icon]}
                pack="assets"
                name="request"
              />
              {!isEmpty(listPending) && (
                <View
                  style={[
                    styles.notify,
                    {
                      backgroundColor:
                        theme[`color-${Configs.REQUESTS_NOTIFY_STATUS}-500`],
                    },
                  ]}
                >
                  <Text status="white" category="c4">
                    {listPending.length}
                  </Text>
                </View>
              )}
            </View>
          ),
        }}
      />
      <BottomTab.Screen
        name="Invitations"
        component={InvitesMainNavigator}
        options={{
          tabBarButton: (props) => (
            <TouchableOpacity
              {...props}
              onPress={() =>
                navigate('Invitations', { screen: 'InvitationsScreen' })
              }
            />
          ),
          tabBarIcon: ({ color }) => (
            <Icon
              style={[{ tintColor: color }, styles.icon]}
              pack="assets"
              name="calendar"
            />
          ),
        }}
      />
      <BottomTab.Screen
        name="Visitors"
        component={VisitorsMainNavigator}
        options={{
          tabBarButton: (props) => (
            <TouchableOpacity
              {...props}
              onPress={() => navigate('Visitors', { screen: 'VisitorsScreen' })}
            />
          ),
          tabBarIcon: ({ color }) => (
            <Icon
              style={[{ tintColor: color }, styles.icon]}
              pack="assets"
              name="baby"
            />
          ),
        }}
      />
    </BottomTab.Navigator>
  );
};

export default MainBottomTab;

const styles = StyleSheet.create({
  icon: {
    width: 24,
    height: 24,
  },
  notify: {
    width: 20,
    height: 20,
    borderRadius: 20,
    position: 'absolute',
    top: -4,
    right: -8,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
