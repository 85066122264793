import { ImageSourcePropType } from 'react-native';

export interface IconName {
  back: ImageSourcePropType;
  'arrow-right': ImageSourcePropType;
  'right-arrow': ImageSourcePropType;
  settingFill: ImageSourcePropType;
  home: ImageSourcePropType;
  homeFill: ImageSourcePropType;
  accountFill: ImageSourcePropType;
  group: ImageSourcePropType;
  groupFill: ImageSourcePropType;
  calendar: ImageSourcePropType;
  calendarFill: ImageSourcePropType;
  logout: ImageSourcePropType;
  menu: ImageSourcePropType;
  add: ImageSourcePropType;
  eyeOff: ImageSourcePropType;
  eyeOn: ImageSourcePropType;
  baby: ImageSourcePropType;
  babyFill: ImageSourcePropType;
  edit: ImageSourcePropType;
  edit_1: ImageSourcePropType;
  trash: ImageSourcePropType;
  search: ImageSourcePropType;
  'caret-down': ImageSourcePropType;
  call: ImageSourcePropType;
  cancel: ImageSourcePropType;
  share: ImageSourcePropType;
  close: ImageSourcePropType;
  'cancel-invite': ImageSourcePropType;
  email: ImageSourcePropType;
  'whats-app': ImageSourcePropType;
  copy: ImageSourcePropType;
  emergencyNumber: ImageSourcePropType;
  request: ImageSourcePropType;
  booking: ImageSourcePropType;
  document: ImageSourcePropType;
  paper: ImageSourcePropType;
  download: ImageSourcePropType;
  person: ImageSourcePropType;
  'add-child': ImageSourcePropType;
  people: ImageSourcePropType;
  wishlist: ImageSourcePropType;
  'my-post': ImageSourcePropType;
  activity: ImageSourcePropType;
  phone: ImageSourcePropType;
  time: ImageSourcePropType;
  deny: ImageSourcePropType;
  approve: ImageSourcePropType;
  filter: ImageSourcePropType;
  delete: ImageSourcePropType;
  'un-correct': ImageSourcePropType;
  correct: ImageSourcePropType;
  moon: ImageSourcePropType;
  sun: ImageSourcePropType;
  notification: ImageSourcePropType;
  'close-1': ImageSourcePropType;
}

export const Icons: IconName = {
  back: require('./ic_back.png'),
  'arrow-right': require('./ic_arrow_right.png'),
  'right-arrow': require('./ic_right_arrow.png'),
  settingFill: require('./ic_setting_fill.png'),
  home: require('./ic_home.png'),
  homeFill: require('./ic_home_fill.png'),
  accountFill: require('./ic_account_fill.png'),
  group: require('./ic_group.png'),
  groupFill: require('./ic_group_fill.png'),
  calendar: require('./ic_calendar.png'),
  calendarFill: require('./ic_calendar_fill.png'),
  logout: require('./ic_logout.png'),
  menu: require('./ic_menu.png'),
  add: require('./ic_add.png'),
  eyeOff: require('./ic_eye_off.png'),
  eyeOn: require('./ic_eye_on.png'),
  baby: require('./ic_baby.png'),
  babyFill: require('./ic_baby_fill.png'),
  edit: require('./ic_edit.png'),
  trash: require('./ic_trash.png'),
  search: require('./ic_search.png'),
  edit_1: require('./ic_edit_1.png'),
  'caret-down': require('./ic_caret_down.png'),
  call: require('./ic_call.png'),
  cancel: require('./ic_cancel.png'),
  share: require('./ic_share.png'),
  close: require('./ic_close.png'),
  'close-1': require('./ic_close_1.png'),
  'cancel-invite': require('./ic_cancel_invite.png'),
  email: require('./ic_email.png'),
  'whats-app': require('./ic_whats_app.png'),
  copy: require('./ic_copy.png'),
  emergencyNumber: require('./ic_emergency_number.png'),
  request: require('./ic_request.png'),
  booking: require('./ic_booking.png'),
  document: require('./ic_document.png'),
  paper: require('./ic_paper.png'),
  download: require('./ic_download.png'),
  person: require('./ic_person.png'),
  'add-child': require('./ic_add_child.png'),
  people: require('./ic_people.png'),
  wishlist: require('./ic_wishlist.png'),
  'my-post': require('./ic_my_post.png'),
  activity: require('./ic_activity.png'),
  phone: require('./ic_phone.png'),
  time: require('./ic_time.png'),
  deny: require('./ic_deny.png'),
  approve: require('./ic_approve.png'),
  filter: require('./ic_filter.png'),
  delete: require('./ic_delete.png'),
  'un-correct': require('./ic_uncorrect.png'),
  correct: require('./ic_correct.png'),
  moon: require('./ic_moon.png'),
  sun: require('./ic_sun.png'),
  notification: require('./ic_notification.png'),
};
