import React from 'react';
import { View, StyleSheet, ViewStyle, TouchableOpacity } from 'react-native';
import { useTheme } from '@ui-kitten/components';

import Text from './Text';

import dayjs from 'dayjs';
import { NotificationMessagePriority, INotification } from 'constants/types';

interface NotificationItemProps {
  style?: ViewStyle;
  item: INotification;
  onPress?(): void;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  style,
  item,
  onPress,
}) => {
  const theme = useTheme();
  const { priority, dateSent, senderName, subject, brief, read } = item;

  return (
    <TouchableOpacity
      activeOpacity={0.7}
      style={[styles.container, style]}
      onPress={onPress}
    >
      <View
        style={[
          styles.box1,
          {
            backgroundColor:
              priority === NotificationMessagePriority.HIGH
                ? theme['color-red-1-500']
                : theme['color-violet-500'],
          },
        ]}
      >
        <Text category="h3" status="white">
          {senderName.split('')[0]}
        </Text>
      </View>
      <View
        style={[
          styles.content,
          { borderBottomColor: theme['background-basic-color-15'] },
        ]}
      >
        <View style={styles.header}>
          <Text category="c3-b" letterSpacing={0.2}>
            {senderName}
          </Text>
          <View
            style={[
              styles.box,
              {
                backgroundColor:
                  priority === NotificationMessagePriority.HIGH
                    ? theme['color-red-1-500']
                    : theme['color-blue-500'],
              },
            ]}
          >
            <Text status="white" category="c3-m" uppercase>
              {priority}
            </Text>
          </View>
        </View>
        <Text category="c5" marginTop={12}>
          {dayjs(dateSent).format('YYYY/MM/DD h:mm')}
        </Text>
        <Text marginTop={8} letterSpacing={0.2} category="c3">
          {subject}
        </Text>
        <Text
          marginTop={8}
          category="c3-l"
          letterSpacing={0.2}
          status={read ? 'placeholder' : 'basic'}
        >
          {brief}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default NotificationItem;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  avatar: {
    width: 42,
    height: 42,
    overflow: 'hidden',
    marginRight: 8,
  },
  content: {
    flex: 1,
    paddingBottom: 16,
    borderBottomWidth: 1,
  },
  box: {
    borderRadius: 16,
    paddingHorizontal: 8,
    height: 22,
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  box1: {
    width: 42,
    height: 42,
    borderRadius: 42,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8,
  },
});
