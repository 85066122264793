import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useTheme, Modal, Layout } from '@ui-kitten/components';
import { useImagePicker, useLayout } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/store';

import Text from './Text';
import SelectItem from './SelectItem';

import { setAvatar } from 'store/slices/visitorSlice';
import { ETypePhoto, IChoosePhoto } from 'constants/types';

interface ModalPhotoProps {
  title?: string;
  visible: boolean;
  onBackdropPress(): void;
}

function ModalPhoto({ visible, onBackdropPress }: ModalPhotoProps) {
  const theme = useTheme();
  const { width } = useLayout();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const { takePhoto, choosePhoto } = useImagePicker();

  const data_photo: IChoosePhoto[] = [
    {
      title: t('photo_gallery'),
      type: ETypePhoto.LIBRARY,
    },
    {
      title: t('camera'),
      type: ETypePhoto.TAKE_PHOTO,
    },
  ];

  return (
    <Modal
      visible={visible}
      onBackdropPress={onBackdropPress}
      style={styles.modalContainer}
      backdropStyle={[
        styles.container,
        {
          backgroundColor: 'rgba(30, 31, 32, 0.86)',
        },
      ]}
    >
      <Layout level="2" style={[styles.modal, { width: width - 24 }]}>
        {data_photo.map((item, index) => {
          return (
            <SelectItem
              key={index}
              title={item.title}
              last={index === data_photo.length - 1}
              onPress={() => {
                if (item.type === ETypePhoto.TAKE_PHOTO) {
                  setTimeout(() => {
                    takePhoto((i) => dispatch(setAvatar(i)));
                  }, 1000);
                } else {
                  setTimeout(() => {
                    choosePhoto((i) => dispatch(setAvatar(i)));
                  }, 1000);
                }
                onBackdropPress();
              }}
            />
          );
        })}
      </Layout>
      <TouchableOpacity
        activeOpacity={0.7}
        onPress={onBackdropPress}
        style={[
          styles.button,
          { backgroundColor: theme['background-basic-color-2'] },
        ]}
      >
        <Text category="t1-m" status="primary">
          {t('cancel')}
        </Text>
      </TouchableOpacity>
    </Modal>
  );
}

export default React.forwardRef(ModalPhoto) as (
  props: ModalPhotoProps & {
    ref?: React.ForwardedRef<{ show: () => void; hide: () => void }>;
  },
) => ReturnType<typeof ModalPhoto>;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  modal: {
    borderRadius: 12,
    marginHorizontal: 12,
  },
  modalContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    top: '70%',
    height: 195,
  },
  button: {
    marginTop: 12,
    height: 60,
    borderRadius: 12,
    marginHorizontal: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
