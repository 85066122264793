import {EV_TRACK_ENDPOINT} from './api';
import {ERequest, request} from 'utils/request';
import {
    ICreateInvite,
    ICreateInviteResult,
    IInvite,
    IResponse,
} from 'constants/types';

/**
 * List All Invites
 */

export const requestListAllInvites = async (): Promise<IInvite[] | undefined> => {
    const url = `${EV_TRACK_ENDPOINT}/visitor/invite`;
    const {data} = await request(ERequest.GET, url);
    return data;
};

/**
 * Create New Invite
 */

export const requestCreateNewInvite = async (
    payload: ICreateInvite,
): Promise<ICreateInviteResult | undefined> => {
    const url = `${EV_TRACK_ENDPOINT}/visitor/invite`;
    const {data} = await request(ERequest.POST, url, payload);
    return data;
};

/**
 * Delete Invite By UUID
 */

type DeleteInviteByUUIDPayload = {
    uuid: string;
};

export const requestDeleteInviteByUUID = async (
    payload: DeleteInviteByUUIDPayload,
): Promise<IResponse | undefined> => {
    const url = `${EV_TRACK_ENDPOINT}/visitor/invite/${payload.uuid}`;
    const {data} = await request(ERequest.DELETE, url, payload);
    return data;
};
