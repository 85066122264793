import React from 'react';
import { View, StyleSheet, Platform, FlatList } from 'react-native';
import Configs from 'configs';
import {
  Container,
  Credential,
  HideWithKeyboard,
  ModalConfirm,
  NavigationAction,
  Shape,
  Text,
  UserItem,
} from 'components';
import { Button, TopNavigation } from '@ui-kitten/components';
import {
  useNavigation,
  DrawerActions,
  NavigationProp,
} from '@react-navigation/native';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useTranslation } from 'react-i18next';
import { useLayout, useModal } from 'hooks';

import keyExtractor from 'utils/keyExtractor';
import SvgInvitations from 'assets/svgs/SvgInvitations';
import { ICredential, IInvite, IShare } from 'constants/types';
import { MainStackParamList } from 'navigation/types';
import {
  deleteInviteByUUID,
  getAllInvites,
  inviteSelector,
} from 'store/slices/inviteSlice';

const InvitationsScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const { navigate } = useNavigation<NavigationProp<MainStackParamList>>();
  const { t } = useTranslation(['common']);

  const { bottom, height } = useLayout();
  const { visible, hide, show } = useModal();

  const { invites } = useAppSelector(inviteSelector);

  const [invite, setInvite] = React.useState<IInvite>();
  const [credential, setCredential] = React.useState<ICredential>();
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    dispatch(getAllInvites());
  }, [dispatch]);

  const handleRefetch = React.useCallback(() => {
    setLoading(true);
    dispatch(getAllInvites(true));
    setLoading(false);
  }, []);

  const handleDelete = React.useCallback(() => {
    hide();
    if (credential && invite) {
      dispatch(deleteInviteByUUID({ uuid: credential.uuid }));
    }
  }, [credential, invite]);

  const handleCreateVisitor = React.useCallback(() => {
    navigate('Visitors', {
      screen: 'VisitorNewUpdate',
      params: { id: undefined },
    });
  }, []);

  const renderItem = React.useCallback(
    ({ item }: { item: IInvite; index: number }) => {
      const { firstName, lastName, credentials } = item;
      return (
        <View>
          <Text category="t3" marginLeft={24} marginBottom={16}>
            {firstName} {lastName}
          </Text>
          <View>
            {!!credentials &&
              credentials.map((i, index) => {
                return (
                  <View key={index}>
                    <Credential
                      item={i}
                      key={index}
                      onCancel={() => {
                        setInvite(item);
                        setCredential(i);
                        setTimeout(() => {
                          show();
                        }, 200);
                      }}
                      onShare={() => {
                        const share: IShare = {
                          activeDate: i.activeDate,
                          expiryDate: i.expiryDate,
                          uuid: i.uuid,
                          firstName: item.firstName,
                          lastName: item.lastName,
                          mobileNumber: item.mobileNumber || '',
                          message: i.inviteMessage || '',
                        };

                        navigate('Invitations', {
                          screen: 'InviteShare',
                          params: { share },
                        });
                      }}
                    />
                  </View>
                );
              })}
          </View>
        </View>
      );
    },
    [],
  );

  const ListEmptyComponent = React.useCallback(() => {
    return (
      <View
        style={[
          styles.none,
          { paddingBottom: bottom + 58, marginTop: height / 5 },
        ]}
      >
        <SvgInvitations />
        <Text marginTop={16} category="t1-m" center>
          No Visitor Invitations
        </Text>
        <Text marginTop={8} category="b3" center>
          {'Press Add button or link below to\nadd your first invitation'}
        </Text>
        <Button
          size="medium"
          children="Invite Visitor"
          style={styles.buttonInvite}
          onPress={() => navigate('Visitors', { screen: 'VisitorsScreen' })}
        />
      </View>
    );
  }, []);

  return (
    <Container level="2">
      <TopNavigation
        style={styles.topNavigation}
        //title={t('common:invitations')}
        accessoryLeft={
          <View style={styles.header}>
            <NavigationAction
              icon="menu"
              onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
            />
            <UserItem />
          </View>
        }
      />
      <FlatList
        data={invites}
        renderItem={renderItem}
        scrollEventThrottle={16}
        refreshing={loading}
        ListEmptyComponent={<ListEmptyComponent />}
        onRefresh={handleRefetch}
        indicatorStyle="black"
        keyExtractor={keyExtractor}
        contentContainerStyle={{
          paddingBottom: Platform.OS === 'ios' ? bottom + 16 : bottom + 58,
          paddingTop: 24,
        }}
      />
      <HideWithKeyboard
        style={{
          right: 8,
          bottom: Platform.OS === 'ios' ? bottom + 58 : bottom + 58,
          position: 'absolute',
        }}
      >
        <Shape
          icon="add"
          status={Configs.ADD_BUTTON_STATUS}
          size="large"
          onPress={handleCreateVisitor}
        />
      </HideWithKeyboard>
      {credential && invite && (
        <ModalConfirm
          visible={visible}
          onBackdropPress={hide}
          title={`${t('common:confirm')}`}
          description={t('common:delete_invitation_for', {
            firstName: invite?.firstName || '',
            lastName: invite?.lastName || '',
          })}
          content={{
            activeDate: credential?.activeDate,
            expiryDate: credential?.expiryDate,
          }}
          buttonLeft={{ onPress: hide, title: t('common:cancel') }}
          buttonRight={{ onPress: handleDelete, title: t('common:ok') }}
        />
      )}
    </Container>
  );
};

export default InvitationsScreen;

const styles = StyleSheet.create({
  none: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonAdd: {
    position: 'absolute',
    right: 8,
    bottom: 24,
    width: 48,
    height: 48,
    borderRadius: 24,
  },
  buttonInvite: {
    alignSelf: 'center',
    marginTop: 16,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  topNavigation: {
    marginTop: 12,
  },
});
