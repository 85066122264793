import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';
import { AppThunk } from 'store/store';
import {
  requestFlushOrDeleteUnusedOrOldQRCode,
  requestRefreshOrGetQRCodeMobileCredentials,
} from 'api/credential';
import { EKeyAsyncStorage, IQrCodeMobile } from 'constants/types';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { isQrCodeTokenValid } from 'utils/isQrCodeTokenValid';
import dayjs from 'dayjs';
import { isQrCodeTTLExpired } from 'utils/isQrCodeTTLExpired';

export interface ICredentialState {
  qrCode: IQrCodeMobile | undefined;
}

export const initialState: ICredentialState = {
  qrCode: undefined,
};

export const credentialSlice = createSlice({
  name: 'credential',
  initialState,
  reducers: {
    setQRCode: (
      state: ICredentialState,
      { payload }: PayloadAction<IQrCodeMobile | undefined>,
    ) => {
      state.qrCode = payload;
    },
  },
});

export const { setQRCode } = credentialSlice.actions;

export const credentialSelector = (state: RootState) => state.credential;

type FlushOldQRCodePayload = {
  inUseToken: string;
};

export const flushQrCodes =
  (payload: FlushOldQRCodePayload): AppThunk =>
  async () => {
    await requestFlushOrDeleteUnusedOrOldQRCode(payload);
  };

export const getQrCodeObservable =
  ({ force = false }: { force?: boolean }): AppThunk =>
  async (dispatch) => {
    try {
      const cacheQRCode = await AsyncStorage.getItem(EKeyAsyncStorage.qrCode);

      if (cacheQRCode && !force) {
        const candidate: IQrCodeMobile = JSON.parse(cacheQRCode);

        const exp = dayjs(candidate.exp).valueOf();
        const nbf = dayjs(candidate.nbf).valueOf();
        if (isQrCodeTokenValid(nbf, exp)) {
          // QR Code cached is valid, update UI
          dispatch(setQRCode(candidate));
        }

        if (!isQrCodeTTLExpired(candidate.ttl)) {
          // continue using cached QR Code if TTL still valid,
          // other proceed and fetch new QR from Server
          console.log(
            'IN getQrCodeObservable == QR Code cached is valid, update UI',
          );
          return;
        }
      }

      // Fetch New QR Code from Server
      console.log('IN getQrCodeObservable >> Fetch New QR Code from Server');
      const res: any = await requestRefreshOrGetQRCodeMobileCredentials();

      // Find Candidate from List
      if (res && res.length > 0) {
        let candidate: any;
        for (const probe of res) {
          if (!candidate) {
            candidate = probe;
          }

          if (candidate.exp === null || probe.exp > candidate.exp) {
            candidate = probe;
          }
        }

        if (candidate) {
          dispatch(flushQrCodes({ inUseToken: candidate.uuid }));

          // persists to storage
          await AsyncStorage.setItem(
            EKeyAsyncStorage.qrCode,
            JSON.stringify(candidate),
          );

          // set isNew flag and update UI
          candidate.isNew = true;
          dispatch(setQRCode(candidate));
        }
      } else {
        // clear storage
        await AsyncStorage.removeItem(EKeyAsyncStorage.qrCode);
        dispatch(setQRCode(undefined));
      }
    } catch (error) {
      console.error('qr code error', error);
    }
  };

export default credentialSlice.reducer;
