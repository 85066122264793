import { IQrCode } from 'constants/types';
import { ERequest, request } from 'utils/request';
import { EV_TRACK_ENDPOINT } from './api';

/**
 * Flush Or Delete Unused Or Old QR Codes
 */

type FlushOrDeleteUnusedOrOldQRCodePayload = {
  inUseToken: string;
};

export const requestFlushOrDeleteUnusedOrOldQRCode = async (
  payload: FlushOrDeleteUnusedOrOldQRCodePayload,
): Promise<any | undefined> => {
  const url = `${EV_TRACK_ENDPOINT}/credential/qr-flush`;

  const { data } = await request(ERequest.POST, url, payload);

  return data;
};

/**
 * Refresh Or Get QR Code Mobile Credentials
 */

export const requestRefreshOrGetQRCodeMobileCredentials = async (): Promise<
  IQrCode[]
> => {
  const url = `${EV_TRACK_ENDPOINT}/credential/qr-refresh`;

  const { data } = await request(ERequest.GET, url);

  return data;
};
