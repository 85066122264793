import React from 'react';
import * as Contacts from 'expo-contacts';
import {
  FlatList,
  Modal,
  Platform,
  StyleSheet,
  View,
  TouchableOpacity,
} from 'react-native';
import {
  ContactItem,
  Container,
  InputSelect,
  LoadingIndicator,
  LoadingVisitor,
  ModalConfirm,
  ModalPhoto,
  NavigationAction,
  Text,
  UserItem,
} from 'components';
import {
  Avatar,
  Button,
  CheckBox,
  Icon,
  Input,
  Layout,
  TopNavigation,
  useTheme,
} from '@ui-kitten/components';
import {
  DrawerActions,
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import {
  useBoolean,
  useImagePicker,
  useKeyboard,
  useLayout,
  useModal,
} from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CountryPicker, {
  Country,
  FlagType,
} from 'react-native-country-picker-modal';

import _ from 'lodash';
import Configs from 'configs';
import keyExtractor from 'utils/keyExtractor';
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js';
import {
  requestCreateVisitor,
  requestFilesUpload,
  requestGetVisitorById,
  requestUpdateVisitor,
} from 'api/visitor';
import {
  EKeyAsyncStorage,
  ICredential,
  IVisitReason,
  MobileAppOptions,
  MODE,
} from 'constants/types';
import {
  deleteVisitorById,
  setAvatar,
  visitorSelector,
} from 'store/slices/visitorSlice';
import { VisitorsMainParamList } from 'navigation/types';
import { getCountriesAsync } from 'react-native-country-picker-modal/lib/CountryService';
import { sortAlphabetical } from 'utils/sortAlphabetical';
import { listSearch } from 'utils/listSearch';
import { setAppLoading } from 'store/slices/appSlice';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import PhoneInput from 'react-native-phone-input';
import { extractContactNumber } from 'utils/extractContactNumber';
import DataURIToBlob from 'utils/DataURIToBlob';

type VisitorNewUpdateRouteProp = RouteProp<
  VisitorsMainParamList,
  'VisitorNewUpdate'
>;

type VisitorType = {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  email: string;
  identityNr: string;
  company: string;
  visitReason: IVisitReason;
  avatar: string;
  photoUUID: string;
};

const initValue: VisitorType = {
  firstName: '',
  lastName: '',
  mobileNumber: '',
  email: '',
  identityNr: '',
  company: '',
  visitReason: {
    id: 0,
    reason: '',
    optionDefaultReason: false,
  },
  avatar: '',
  photoUUID: '',
};

type IVisitorType = {
  comments?: string | null;
  company?: string | null;
  credentials?: ICredential[];
  dateOfBirth?: null;
  email?: string | null;
  firstName?: string | null;
  gender?: string | null;
  id: number;
  identityNr?: string | null;
  initials?: string | null;
  lastName?: string | null;
  mobileNumber?: string | null;
  visitReason?: IVisitReason;
  uuid?: string;
  thumbnail?: string;
};

const VisitorNewUpdate: React.FC = () => {
  const isWeb = Platform.OS === 'web';
  const theme = useTheme();
  const navigation = useNavigation();
  const { goBack } = useNavigation();
  const { top, bottom } = useLayout();
  const { t } = useTranslation(['common', 'visitor']);
  const refPhone = React.useRef<PhoneInput>(null);
  const [visible, { on, off }] = useBoolean(false);
  const [visibleContacts, { on: onContacts, off: offContacts }] =
    useBoolean(false);
  const [visibleCountry, { on: onCountry, off: offCountry }] =
    useBoolean(false);
  const { visible: modalRef, hide, show } = useModal();
  const {
    visible: modalRefPhoto,
    show: showPhoto,
    hide: hidePhoto,
  } = useModal();
  const { choosePhoto } = useImagePicker();
  const { keyboardShown, keyboardHeight } = useKeyboard();

  const {
    params: { id },
  } = useRoute<VisitorNewUpdateRouteProp>();
  const dispatch = useAppDispatch();
  const { visitors, avatar } = useAppSelector(visitorSelector);
  const { navigate, reset: resetNavigation } =
    useNavigation<NavigationProp<VisitorsMainParamList>>();

  const [user, setUser] = React.useState<MobileAppOptions>();
  const [mode, setMode] = React.useState<MODE.ADD | MODE.EDIT | null>(null);
  const [country, setCountry] = React.useState<Country>();
  const [contacts, setContacts] = React.useState<Contacts.Contact[]>([]);
  const [search, setSearch] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [removePhoto, { on: onRemovePhoto, off: hasPhoto }] = useBoolean(false);

  const [visitor, setVisitor] = React.useState<IVisitorType>();

  const { getItem: getVisitorsCache, setItem: setVisitorsCache } =
    useAsyncStorage(EKeyAsyncStorage.visitors);
  const { getItem: getUser } = useAsyncStorage(EKeyAsyncStorage.user);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    setError,
    clearErrors,
    reset,
    watch,
  } = useForm<VisitorType>({
    defaultValues: initValue,
  });

  React.useEffect(() => {
    (async () => {
      setAppLoading(true);
      if (id !== undefined) {
        setMode(MODE.EDIT);

        const res = await requestGetVisitorById({ id: `${id}` });

        if (res) {
          setVisitor(res);
          setValue('firstName', res.firstName || '');
          setValue('lastName', res.lastName || '');
          setValue('mobileNumber', res.mobileNumber || '');
          setValue('email', res.email || '');
          setValue('company', res.company || '');
          setValue('identityNr', res.identityNr || '');
          setValue('avatar', res.thumbnail || '');

          if (res.visitReason) {
            user?.visitReasons.find((i) => {
              if (i.id === res.visitReason?.id) {
                setValue('visitReason', i);
              }
            });
          }
          setTimeout(async () => {
            if (res.mobileNumber) {
              refPhone.current?.setValue(res.mobileNumber);
              const _country =
                typeof res.mobileNumber === 'string'
                  ? parsePhoneNumber(res.mobileNumber)?.country
                  : user?.defaultCountryIsoCode.toUpperCase();

              const countries = await getCountriesAsync(FlagType.FLAT);
              const defaultCountry = _.find(
                countries,
                (i) => i.cca2 === _country,
              );

              if (defaultCountry) {
                setCountry(defaultCountry);
              }

              if (_country) {
                refPhone.current?.selectCountry(_country.toLowerCase());
              }
            }
          }, 1500);
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        } else {
          setValue('visitReason', initValue.visitReason);
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        }
      } else {
        dispatch(setAvatar(undefined));
        reset(initValue);
        setMode(MODE.ADD);

        // if (user.visitorRegRequiredReason) {
        // always try and set default option

        user?.visitReasons.find((i) => {
          if (i.optionDefaultReason) {
            setValue('visitReason', i);
          }
        });

        // Set default country code by localization
        //const { region } = await Localization.getLocalizationAsync();

        if (user?.defaultCountryIsoCode) {
          refPhone.current?.selectCountry(
            user?.defaultCountryIsoCode.toLowerCase(),
          );
        }
        setIsLoading(false);
      }
    })();
  }, [id, user]);

  React.useEffect(() => {
    (async () => {
      const _user = await getUser();

      if (_user) {
        setUser(JSON.parse(_user));
      }
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        if (avatar && avatar.uri) {
          setLoading(true);
          setValue('avatar', avatar.uri);
          const fileName = avatar.uri.split('/').pop();
          //@ts-ignore
          const fileType = fileName.split('.').pop();
          const formData = new FormData();
          if (Platform.OS === 'web') {
            const file = DataURIToBlob(avatar.uri);
            formData.append('file', file, 'image.jpg');
          } else {
            formData.append(
              'file',
              {
                //@ts-ignore
                uri: avatar.uri,
                name: fileName,
                type: `image/${fileType}`,
              },
              fileName || 'image.jpg',
            );
          }
          if (!formData) {
            return;
          }
          const res = await requestFilesUpload(formData);
          if (!res) {
            return;
          }
          setValue('photoUUID', res);
          hasPhoto();
        }
      } catch (e) {
        console.log('error upload file', e);
      } finally {
        setLoading(false);
      }
    })();
  }, [avatar]);

  const getPermission = React.useCallback(async () => {
    const { status } = await Contacts.requestPermissionsAsync();
    if (status === 'granted') {
      const { data }: Contacts.ContactResponse =
        await Contacts.getContactsAsync({
          fields: [
            Contacts.Fields.Name,
            Contacts.Fields.MiddleName,
            Contacts.Fields.FirstName,
            Contacts.Fields.LastName,
            Contacts.Fields.MaidenName,
            Contacts.Fields.PhoneNumbers,
            //Contacts.Fields.ImageAvailable,
            //Contacts.Fields.Image,
            Contacts.Fields.Emails,
          ],
        });

      const sortContacts = (contracts: Contacts.Contact[]) => {
        if (contracts != null) {
          contracts.sort((a, b) => {
            const string1 = [a.firstName, a.lastName]
              .filter(Boolean)
              .join(' ')
              .toLowerCase()
              .trim();
            const string2 = [b.firstName, b.lastName]
              .filter(Boolean)
              .join(' ')
              .toLowerCase()
              .trim();
            if (string1 < string2) {
              return -1;
            }
            return string1 > string2 ? 1 : 0;
          });
        }
        return contracts;
      };

      const _contracts = sortContacts([...data]);

      setContacts(_contracts);
      onContacts();
    }
  }, []);

  const handlerSearch = React.useCallback(async (e: string) => {
    setSearch(e);
    const { data }: Contacts.ContactResponse = await Contacts.getContactsAsync({
      fields: [
        Contacts.Fields.Name,
        Contacts.Fields.MiddleName,
        Contacts.Fields.FirstName,
        Contacts.Fields.LastName,
        Contacts.Fields.MaidenName,
        Contacts.Fields.PhoneNumbers,
        //Contacts.Fields.ImageAvailable,
        //Contacts.Fields.Image,
        Contacts.Fields.Emails,
      ],
    });

    const _contracts = sortAlphabetical([...data]);

    if (!e) {
      setContacts(_contracts);
      return;
    }

    const results = listSearch(_contracts, e);

    setContacts(results);
  }, []);

  const onSubmit = React.useCallback(
    async (data: VisitorType) => {
      try {
        dispatch(setAppLoading(true));

        const phone = `${data.mobileNumber.replace(/-/g, '')}`;

        let mobileNumber = '';

        let _defaultCountryCode;
        if (country?.cca2) {
          //@ts-ignore
          mobileNumber = parsePhoneNumber(phone, country.cca2)?.format('E.164');
          _defaultCountryCode = country.cca2;
        } else {
          if (
            user &&
            user?.visitorRegRequiredMobileNumber &&
            !user.defaultCountryIsoCode
          ) {
            setError('mobileNumber', {
              type: 'required',
              message: t('visitor:country_code_required'),
            });
            return;
          }
          //@ts-ignore
          mobileNumber = parsePhoneNumber(
            phone,
            //@ts-ignore
            user.defaultCountryIsoCode.toUpperCase(),
          )?.format('E.164');
          _defaultCountryCode = user?.defaultCountryIsoCode.toUpperCase();
        }

        if (
          user &&
          user?.visitorRegRequiredMobileNumber &&
          !isValidPhoneNumber(
            mobileNumber,
            //@ts-ignore
            _defaultCountryCode,
          )
        ) {
          setError('mobileNumber', {
            type: 'required',
            message: t('visitor:phone_number_required'),
          });
          return;
        }

        if (mode === MODE.ADD) {
          const _visitor = user?.visitorRegRequiredReason
            ? {
              lastName: data.lastName,
              firstName: data.firstName,
              email: data.email,
              mobileNumber: mobileNumber || '',
              identityNr: data.identityNr,
              company: data.company,
              visitReason: {
                ...data.visitReason,
                optionDefaultReason: true,
              },
              tempPhotoUUID: data.photoUUID,
            }
            : data.visitReason.reason !== ''
              ? {
                lastName: data.lastName,
                firstName: data.firstName,
                email: data.email,
                mobileNumber: mobileNumber || '',
                identityNr: data.identityNr,
                company: data.company,
                visitReason: {
                  ...data.visitReason,
                  optionDefaultReason: false,
                },
                tempPhotoUUID: data.photoUUID,

              }
              : {
                lastName: data.lastName,
                firstName: data.firstName,
                email: data.email,
                mobileNumber: mobileNumber || '',
                identityNr: data.identityNr,
                company: data.company,
                tempPhotoUUID: data.photoUUID,
              };


          const result = await requestCreateVisitor(_visitor);

          if (result) {
            if (!!result && result.code === '200') {

              // flush photo from frontend
              onRemovePhoto();
              dispatch(setAvatar(undefined));
              setValue('avatar', '');
              setValue('photoUUID', '');

              // get new visitor list form remote server
              const visitorsCache = await getVisitorsCache();

              if (visitorsCache) {
                const _list = [...JSON.parse(visitorsCache), result.visitor];

                const _visitors = sortAlphabetical(_list);
                setVisitorsCache(JSON.stringify(_visitors));
                setTimeout(() => {
                  resetNavigation({
                    index: 0,
                    routes: [
                      {
                        name: 'VisitorProfile',
                        params: {
                          id: result.id,
                        },
                      },
                    ],
                  });
                }, 2000);
              }
            }
          }
        } else {
          if (visitor) {
            const _visitor: any = user?.visitorRegRequiredReason
              ? {
                  lastName: data.lastName,
                  firstName: data.firstName,
                  email: data.email,
                  mobileNumber: mobileNumber || '',
                  identityNr: data.identityNr,
                  company: data.company,
                  visitReason: {
                    ...data.visitReason,
                    optionDefaultReason: true,
                  },
                  id: id,
                }
              : data.visitReason.reason !== ''
              ? {
                  lastName: data.lastName,
                  firstName: data.firstName,
                  email: data.email,
                  mobileNumber: mobileNumber || '',
                  identityNr: data.identityNr,
                  company: data.company,
                  visitReason: {
                    ...data.visitReason,
                    optionDefaultReason: false,
                  },
                  id: id,
                }
              : {
                  lastName: data.lastName,
                  firstName: data.firstName,
                  email: data.email,
                  mobileNumber: mobileNumber || '',
                  identityNr: data.identityNr,
                  company: data.company,
                  id: id,
                };

            if (data.photoUUID) {
              _visitor['tempPhotoUUID'] = data.photoUUID;
            }

            if (removePhoto) {
              _visitor['deletePhoto'] = true;
              _visitor['tempPhotoUUID'] = null;
            } else {
              _visitor['tempPhotoUUID'] = data.photoUUID;
              _visitor['deletePhoto'] = false;
            }

            const result = await requestUpdateVisitor(_visitor);

            if (result) {
              if (!!result && result.code === '200') {

                // flush photo from frontend
                onRemovePhoto();
                dispatch(setAvatar(undefined));
                setValue('avatar', '');
                setValue('photoUUID', '');

                // get new visitor list form remote server
                const visitorsCache = await getVisitorsCache();
                let _list = [];

                if (visitorsCache) {
                  _list = _.filter(
                    JSON.parse(visitorsCache),
                    (i) => i.id !== id,
                  );

                  _list = [..._list, result.visitor];

                  const _visitors = sortAlphabetical(_list);
                  setVisitorsCache(JSON.stringify(_visitors));
                  setTimeout(() => {
                    resetNavigation({
                      index: 0,
                      routes: [
                        {
                          name: 'VisitorProfile',
                          params: {
                            id: id,
                          },
                        },
                      ],
                    });
                  }, 3000);
                }
              }
            }
          }
        }
      } catch (error: any) {
        if (error.message === 'A text for parsing must be a string.') {
          setError('mobileNumber', {
            type: 'required',
            message: t('visitor:phone_number_required'),
          });
        } else {
          console.log('error', error);
        }
      } finally {
        setTimeout(() => {
          dispatch(setAppLoading(false));
        }, 3000);
      }
    },
    [mode, visitor, user, country, visitors, avatar, removePhoto],
  );

  const renderReason = React.useCallback(({ item }: { item: IVisitReason }) => {
    const { reason } = item;
    return (
      <Controller
        control={control}
        name="visitReason"
        render={({ field: { value } }) => (
          <CheckBox
            style={styles.checkBox}
            checked={value.id === item.id}
            status="round"
            children={reason}
            activeOpacity={0.7}
            onChange={() => {
              setValue('visitReason', item);
              off();
            }}
          />
        )}
      />
    );
  }, []);

  const renderContact = React.useCallback(
    ({ item }: { item: Contacts.Contact }) => {
      return (
        <ContactItem
          style={styles.contact}
          item={item}
          defaultCountryCode={country?.cca2}
          onPress={async () => {
            if (user?.visitorRegRequiredName && !item.firstName) {
              setValue('firstName', '');
              setError('firstName', {
                type: 'required',
                message: t('visitor:first_name_required'),
              });
            } else {
              setValue('firstName', item.firstName || '');
              clearErrors('firstName');
            }

            if (user?.visitorRegRequiredSurname && !item.lastName) {
              setValue('lastName', '');
              setError('lastName', {
                type: 'required',
                message: t('visitor:last_name_required'),
              });
            } else {
              setValue('lastName', item.lastName || '');
              clearErrors('lastName');
            }

            if (user?.visitorRegRequiredCompany && !item.company) {
              setValue('company', '');
              setError('company', {
                type: 'required',
                message: t('visitor:company_required'),
              });
            } else {
              setValue('company', item.company || '');
              clearErrors('company');
            }

            if (!!item.emails && !!item.emails[0].email) {
              setValue('email', item.emails[0].email || '');
            } else {
              if (user?.visitorRegRequiredEmail) {
                setValue('email', '');
                setError('email', {
                  type: 'required',
                  message: t('visitor:email_required'),
                });
              }
            }

            if (user?.visitorRegRequiredIdNumber) {
              setError('identityNr', {
                type: 'required',
                message: t('visitor:id_passport_required'),
              });
            }

            const mobileNumber = extractContactNumber(
              item.phoneNumbers,
              country?.cca2,
            );

            if (mobileNumber) {
              refPhone.current?.setValue(mobileNumber);

              // parse number into parts to update UI and country code selector
              const parsedMobileNumber = parsePhoneNumber(mobileNumber);

              // set mobile number
              const formattedNumber = parsedMobileNumber?.format('NATIONAL');
              setValue('mobileNumber', formattedNumber || '');

              // if we have a valid number, set country code selector
              if (parsedMobileNumber) {
                if (parsedMobileNumber.country) {
                  const countries = await getCountriesAsync(FlagType.FLAT);
                  const _country = _.find(
                    countries,
                    (i) =>
                      //@ts-ignore
                      i.cca2 === parsedMobileNumber?.country.toUpperCase(),
                  );
                  refPhone.current?.selectCountry(
                    parsedMobileNumber?.country.toLowerCase(),
                  );
                  if (_country) {
                    setCountry(_country);
                  }
                }
              }
            }

            // hide import contact modal
            setSearch('');
            offContacts();
          }}
        />
      );
    },
    [country],
  );

  const handleDelete = React.useCallback(() => {
    hide();
    dispatch(setAppLoading(true));
    if (visitor && visitor.id) {
      dispatch(deleteVisitorById({ id: visitor.id }));
      setTimeout(() => {
        navigate('VisitorsScreen');
      }, 3000);
    }
  }, [visitor]);

  if (isLoading) {
    return <LoadingVisitor mode={mode} />;
  }

  return (
    <Container level="2">
      <TopNavigation
        style={styles.topNavigation}
        appearance="control"
        title={
          mode === MODE.EDIT
            ? t('visitor:update_visitor')
            : t('visitor:new_visitor')
        }
        //@ts-ignore
        accessoryLeft={
          <View style={styles.header}>
            <NavigationAction
              icon="menu"
              status="basic"
              onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
            />
            <UserItem isShow={false} />
          </View>
        }
        accessoryRight={
          mode === MODE.EDIT && !isLoading
            ? () => (
                <NavigationAction icon="trash" status="basic" onPress={show} />
              )
            : undefined
        }
      />
      <KeyboardAwareScrollView
        enableOnAndroid
        showsVerticalScrollIndicator={false}
        extraScrollHeight={30}
        contentContainerStyle={{
          paddingTop: 16,
          paddingBottom: bottom + 64,
          paddingHorizontal: 24,
        }}
      >
        {!isWeb && (
          <Button
            size="small"
            status="info"
            accessoryLeft={<Icon pack="assets" name="call" />}
            children={t('visitor:import_from_phone_book')}
            style={styles.phoneBook}
            onPress={getPermission}
          />
        )}
        <Controller
          control={control}
          render={({ field: { value } }) => (
            <>
              {value ? (
                <View style={{ alignSelf: 'center' }}>
                  <Avatar
                    source={{ uri: value }}
                    style={[
                      styles.avatar,
                      { borderColor: theme['background-basic-color-1'] },
                    ]}
                  />
                  {visitor && watch('avatar') && (
                    <TouchableOpacity
                      activeOpacity={1}
                      onPress={() => {
                        onRemovePhoto();
                        dispatch(setAvatar(undefined));
                        setValue('avatar', '');
                      }}
                      style={[
                        styles.delete,
                        {
                          backgroundColor: theme['background-basic-color-16'],
                        },
                      ]}
                    >
                      <Icon
                        pack="assets"
                        name="close-1"
                        style={{
                          tintColor: theme['color-basic-100'],
                          width: 16,
                          height: 16,
                        }}
                      />
                    </TouchableOpacity>
                  )}
                </View>
              ) : (
                <TouchableOpacity
                  activeOpacity={0.7}
                  onPress={() => {
                    if (Platform.OS === 'web') {
                      choosePhoto((i) => dispatch(setAvatar(i)));
                    } else {
                      showPhoto();
                    }
                  }}
                >
                  <Layout
                    level="16"
                    style={[
                      styles.avatar,
                      {
                        borderColor: theme['background-basic-color-1'],
                      },
                    ]}
                  >
                    <Icon
                      pack="assets"
                      name="add"
                      style={{
                        tintColor: theme['color-basic-100'],
                        width: 32,
                        height: 32,
                      }}
                    />
                  </Layout>
                </TouchableOpacity>
              )}
            </>
          )}
          name="avatar"
        />
        <View style={styles.name}>
          <Controller
            control={control}
            rules={{
              required: {
                value: user?.visitorRegRequiredName || false,
                message: t('visitor:first_name_required'),
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label={
                  user?.visitorRegRequiredName
                    ? t('common:first_name')
                    : t('common:first_name_optional')
                }
                placeholder={t('common:first_name')}
                style={[styles.input, { marginRight: 16, flex: 1 }]}
                onBlur={onBlur}
                onChangeText={onChange}
                textStyle={styles.textStyle}
                value={value}
                status={errors.firstName && 'danger'}
                caption={errors.firstName?.message}
              />
            )}
            name="firstName"
          />
          <Controller
            control={control}
            rules={{
              required: {
                value: user?.visitorRegRequiredSurname || false,
                message: t('visitor:last_name_required'),
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label={
                  user?.visitorRegRequiredSurname
                    ? t('common:last_name')
                    : t('common:last_name_optional')
                }
                placeholder={t('common:last_name')}
                style={[styles.input, { flex: 1 }]}
                onBlur={onBlur}
                textStyle={styles.textStyle}
                onChangeText={onChange}
                value={value}
                status={errors.lastName && 'danger'}
                caption={errors.lastName?.message}
              />
            )}
            name="lastName"
          />
        </View>
        <Controller
          control={control}
          rules={{
            required: {
              value: user?.visitorRegRequiredEmail || false,
              message: t('visitor:email_required'),
            },
            pattern: {
              value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
              message: t('visitor:please_enter_email'),
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              label={
                user?.visitorRegRequiredEmail
                  ? t('common:email')
                  : t('common:email_optional')
              }
              placeholder={t('common:email')}
              style={styles.input}
              onBlur={onBlur}
              onChangeText={onChange}
              textStyle={styles.textStyle}
              value={value ? value : ''}
              keyboardType="email-address"
              status={errors.email && 'danger'}
              caption={errors.email?.message}
            />
          )}
          name="email"
        />
        <Controller
          control={control}
          rules={{
            required: {
              value: user?.visitorRegRequiredMobileNumber || false,
              message: t('visitor:phone_number_required'),
            },
          }}
          render={({ field: { onChange } }) => (
            <>
              <Text category="c1" marginTop={24}>
                {user?.visitorRegRequiredMobileNumber
                  ? t('common:phone_number')
                  : t('common:phone_number_optional')}
              </Text>
              <View style={{ flex: 1 }}>
                <PhoneInput
                  ref={refPhone}
                  onPressFlag={onCountry}
                  onChangePhoneNumber={onChange}
                  autoFormat
                  style={{
                    borderColor: errors.mobileNumber?.message
                      ? theme['color-danger-500']
                      : theme['border-input-color'],
                    borderBottomWidth: 1,
                    shadowColor: 'transparent',
                    height: 48,
                  }}
                  textStyle={{
                    //@ts-ignore
                    outline: 'none',
                    color: theme['text-basic-color'],
                    fontFamily: 'Roboto-Medium',
                    fontSize: 15,
                    fontWeight: '400',
                    height: '100%',
                  }}
                  textProps={{
                    placeholder: t('common:phone_number'),
                    placeholderTextColor: theme['text-placeholder-color'],
                  }}
                />
              </View>
              {errors.mobileNumber?.message && (
                <Text category="c3" status="danger" marginTop={2}>
                  {errors.mobileNumber?.message}
                </Text>
              )}
            </>
          )}
          name="mobileNumber"
        />
        <Controller
          control={control}
          rules={{
            required: {
              value: user?.visitorRegRequiredIdNumber || false,
              message: t('visitor:id_passport_required'),
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              label={
                user?.visitorRegRequiredIdNumber
                  ? t('common:id_passport')
                  : t('common:id_passport_optional')
              }
              placeholder={t('common:id_passport')}
              style={styles.input}
              onBlur={onBlur}
              textStyle={styles.textStyle}
              onChangeText={onChange}
              value={value ? value : ''}
              status={errors.identityNr && 'danger'}
              caption={errors.identityNr?.message}
            />
          )}
          name="identityNr"
        />
        <Controller
          control={control}
          rules={{
            required: {
              value: user?.visitorRegRequiredCompany || false,
              message: t('visitor:company_required'),
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              label={
                user?.visitorRegRequiredCompany
                  ? t('common:company')
                  : t('common:company_optional')
              }
              placeholder={t('common:company')}
              style={styles.input}
              textStyle={styles.textStyle}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value || ''}
              status={errors.company && 'danger'}
              caption={errors.company?.message}
            />
          )}
          name="company"
        />
        <Controller
          control={control}
          rules={{
            required: {
              value: user?.visitorRegRequiredReason || false,
              message: t('visitor:visit_reason_required'),
            },
          }}
          render={() => (
            <InputSelect
              label={
                user?.visitorRegRequiredReason
                  ? t('common:reason')
                  : t('common:reason_optional')
              }
              placeholder={t('common:reason')}
              style={styles.input}
              value={watch('visitReason.reason')}
              onPress={on}
              status={errors.visitReason?.reason && 'danger'}
              caption={errors.visitReason?.reason?.message}
            />
          )}
          name="visitReason.reason"
        />
        <View style={{ marginTop: 24, flexDirection: 'row' }}>
          <Button
            style={styles.button_clear}
            status="basic"
            children={mode === MODE.EDIT ? t('common:back') : t('common:clear')}
            onPress={() => {
              if (mode === MODE.EDIT) {
                goBack();
              } else {
                reset();
                refPhone.current?.setValue(' ');
                refPhone.current?.selectCountry(
                  //@ts-ignore
                  user?.defaultCountryIsoCode.toLocaleLowerCase(),
                );
              }
            }}
          />
          <Button
            style={[
              styles.buttonSave,
              { shadowColor: Configs.BUTTON_SHADOW_COLOR },
            ]}
            disabled={loading || isSubmitting}
            accessoryLeft={
              isSubmitting || loading
                ? () => (
                    <LoadingIndicator style={{ marginRight: 8 }} size="small" />
                  )
                : undefined
            }
            children={t('common:save')}
            onPress={handleSubmit(onSubmit)}
          />
        </View>
      </KeyboardAwareScrollView>
      <Modal visible={visible} transparent onRequestClose={off}>
        <Layout level="2" style={[styles.modal, { paddingTop: top }]}>
          <TopNavigation
            appearance="control"
            title={t('common:reason')}
            //@ts-ignore
            accessoryLeft={
              <NavigationAction icon="close" status="basic" onPress={off} />
            }
          />
          <FlatList
            data={user?.visitReasons || []}
            keyExtractor={keyExtractor}
            renderItem={renderReason}
            scrollEventThrottle={16}
            showsVerticalScrollIndicator={false}
            refreshing={false}
            style={[
              styles.modalReason,
              { backgroundColor: theme['background-basic-color-1'] },
            ]}
          />
        </Layout>
      </Modal>
      <Modal
        visible={visibleContacts}
        transparent
        onRequestClose={() => {
          setSearch('');
          offContacts();
        }}
      >
        <Layout level="2" style={[styles.modal, { paddingTop: top }]}>
          <TopNavigation
            appearance="control"
            title={t('visitor:find_contact')}
            //@ts-ignore
            accessoryLeft={
              <NavigationAction
                icon="close"
                status="basic"
                onPress={() => {
                  setSearch('');
                  offContacts();
                }}
              />
            }
          />
          <Input
            style={[
              styles.search,
              { backgroundColor: theme['background-basic-color-1'] },
            ]}
            textStyle={styles.textStyle}
            value={search}
            size="large"
            clearButtonMode="while-editing"
            placeholder={t('visitor:search_name')}
            onChangeText={handlerSearch}
            accessoryLeft={
              <Icon style={styles.iconSearch} pack="assets" name="search" />
            }
          />
          <FlatList
            data={contacts || []}
            keyExtractor={keyExtractor}
            renderItem={renderContact}
            scrollEventThrottle={16}
            showsVerticalScrollIndicator={false}
            refreshing={false}
            contentContainerStyle={{
              paddingTop: 16,
              paddingHorizontal: 24,
              paddingBottom:
                Platform.OS === 'ios'
                  ? keyboardShown
                    ? keyboardHeight
                    : bottom
                  : keyboardShown
                  ? 0
                  : bottom,
            }}
          />
        </Layout>
      </Modal>
      <Modal visible={visibleCountry}>
        <CountryPicker
          filterProps={{ placeholder: t('visitor:country_code_select') }}
          //@ts-ignore
          flatListProps={{
            refreshing: false,
            initialNumToRender: 15,
            keyExtractor: keyExtractor,
            removeClippedSubviews: true,
            maxToRenderPerBatch: 20,
            getItemLayout: (data, index) => ({
              length: 30,
              offset: 30 * index,
              index,
            }),
            windowSize: 20,
          }}
          withCallingCode
          withAlphaFilter
          withFilter
          withModal={false}
          withEmoji
          onSelect={(e) => {
            setCountry(e);
            refPhone.current?.selectCountry(e.cca2.toLowerCase());
          }}
          disableNativeModal={false}
          onClose={offCountry}
          modalProps={{
            //@ts-ignore
            ariaHideApp: false,
          }}
        />
      </Modal>
      <ModalConfirm
        visible={modalRef}
        onBackdropPress={hide}
        title={`${t('common:confirm')}`}
        description={
          `${t('common:delete')} ` +
          `${visitor?.firstName !== null ? `${visitor?.firstName}` : ''}` +
          ` ${visitor?.lastName}?`
        }
        buttonLeft={{ onPress: hide, title: t('common:cancel') }}
        buttonRight={{ onPress: handleDelete, title: t('common:ok') }}
      />
      <ModalPhoto visible={modalRefPhoto} onBackdropPress={hidePhoto}/>
    </Container>
  );
};

export default VisitorNewUpdate;

const styles = StyleSheet.create({
  input: {
    marginTop: 24,
  },
  name: {
    flexDirection: 'row',
  },
  icon: {
    width: 16,
    height: 16,
  },
  viewFlag: {
    width: 19,
    marginRight: 4,
  },
  flag: {
    resizeMode: 'contain',
    width: 19,
    height: 13,
  },
  country: {
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    paddingRight: 12,
  },
  phoneBook: {
    maxWidth: 174,
  },
  button_clear: {
    flex: 1,
    marginRight: 16,
  },
  buttonSave: {
    flex: 1,
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 1,
    shadowRadius: 20,
    elevation: 24,
  },
  checkBox: {
    paddingVertical: 12,
  },
  modal: {
    flex: 1,
  },
  modalReason: {
    marginHorizontal: 16,
    borderTopLeftRadius: 16,
    marginTop: 16,
    paddingTop: 12,
    borderTopRightRadius: 16,
    paddingHorizontal: 24,
  },
  contact: {
    marginBottom: 24,
  },
  contentContainerStyle: {
    paddingTop: 16,
    paddingHorizontal: 24,
  },
  search: {
    borderBottomWidth: 0,
    borderRadius: 8,
    shadowColor: 'rgba(29, 30, 44, 0.1)',
    shadowOffset: {
      width: 1,
      height: 12,
    },
    shadowOpacity: 0.2,
    shadowRadius: 12,
    elevation: 10,
    marginHorizontal: 24,
    marginVertical: 16,
  },
  iconSearch: {
    marginHorizontal: 16,
    width: 24,
    height: 24,
  },
  textStyle: {
    height: '100%',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  topNavigation: {
    marginTop: 12,
  },
  avatar: {
    width: 120,
    height: 120,
    borderRadius: 36,
    alignSelf: 'center',
    marginTop: 24,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
  },
  edit: {
    alignSelf: 'center',
    padding: 16,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  line: {
    width: 2,
    height: 16,
  },
  delete: {
    width: 32,
    height: 32,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
});
