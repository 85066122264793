import { combineReducers } from '@reduxjs/toolkit';
import invite from './slices/inviteSlice';
import visitor from './slices/visitorSlice';
import request from './slices/requestSlide';
import app from './slices/appSlice';
import credential from './slices/credentialSlice';
import document from './slices/documentSlice';
import process from './slices/processSlide';
import notification from './slices/notificationSlice';

export type RootState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
  invite,
  visitor,
  request,
  app,
  credential,
  document,
  process,
  notification,
});

export default rootReducer;
