import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { EKeyAsyncStorage } from 'constants/types';
import { isJwtTokenValid } from './isJwtTokenValid';
import { reset } from 'navigation/RootNavigation';
import * as Network from 'expo-network';
import { ServerUnreachableError } from '../errors/ServerUnreachableError';
import { JwtAuthConnectionError } from '../errors/JwtAuthConnectionError';
import { Toast } from 'react-native-toast-notifications';

export enum ERequest {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

const handleAxiosError = async (error: any, url: string) => {
  const network = await Network.getNetworkStateAsync();

  console.log('IN request CATCH error while accessing: ' + url);
  console.log(error);
  console.log('IN request CATCH error network: ');
  console.log(network);

  const toastConfig = {
    duration: 5 * 1000,
    type: 'danger',
  };

  if (error.response) {
    // The client was given an error response (5xx, 4xx)
    if (error.response.status === 400) {
      return handleErrorUnknown(error, toastConfig); // could be server validation issues
    } else if (error.response.status === 401) {
      return handleError401(error, toastConfig);
    } else if (error.response.status === 403) {
      handleError403(error, toastConfig);
    } else if (error.response.status === 409 && error.response.data) {
      Toast.show(error.response.data.message, toastConfig);
      throw {};
    } else if (error.response.status === 429) {
      handleError429(error, toastConfig);
    } else if (error.response.status === 500) {
      handleError500(error, toastConfig);
    } else {
      handleErrorConnectionReset(error, toastConfig);
    }
  } else if (!network.isConnected && !network.isInternetReachable) {
    handleErrorOffline(error, toastConfig);
  } else if (error.request) {
    handleErrorConnectionReset(error, toastConfig);
  } else if (!network.isConnected || !network.isInternetReachable) {
    handleErrorConnectionReset(error, toastConfig);
  } else {
    // Anything else
    handleErrorUnknown(error, toastConfig);
  }
};

const handleError401 = (
  error: any,
  toastConfig: { duration: number; type: string },
) => {
  let url = '';
  if (error && error.config && error.config.url) {
    url = error.config.url;
  }

  console.log(`IN handleError401 url: ${url} error:`);
  console.log(error);

  if (url.endsWith('/process/pending-by-token')) {
    // do nothing
    throw {};
  }

  // display login problem error message
  if (url.endsWith('/auth')) {
    Toast.show(error.response.data, toastConfig);
    throw {};
  }

  // show error toast
  if (
    error.response &&
    typeof error.response.data === 'object' &&
    error.response.data !== null
  ) {
    // 401 with empty body - probably expired session
    Toast.show('Your Session Expired', toastConfig);
  } else if (error.response && error.response.data) {
    Toast.show(error.response.data, toastConfig);
  } else {
    Toast.show('Your Session Expired', toastConfig);
  }

  // flush token
  AsyncStorage.removeItem(EKeyAsyncStorage.jwt).then();
  console.log('IN handleError401: flushed JWT token');

  // reset login screen
  reset('Auth');

  throw {};
};

const handleError403 = (
  error: any,
  toastConfig: { duration: number; type: string },
) => {
  console.log('IN handleError403 error:');
  console.log(error);
  Toast.show(
    "It seems like you don't have the necessary permissions for this action. Please reach out to your friendly system administrator for some assistance!",
    toastConfig,
  );
  throw {};
};

const handleError500 = (
  error: any,
  toastConfig: { duration: number; type: string },
) => {
  console.log('IN handleError500 error:');
  console.error(error);
  Toast.show(
    'Looks like something went wrong! We track these errors automatically, but if the problem persists feel free to contact us. In the meantime, try refreshing.',
    toastConfig,
  );
  throw {};
};

const handleError429 = (
  error: any,
  toastConfig: { duration: number; type: string },
) => {
  console.log('IN handleError429 error:');

  console.log(error);
  Toast.show(
    'Too Many Requests - Please wait 1 minute before trying again.',
    toastConfig,
  );
  throw {};
};

const handleErrorUnknown = (
  error: any,
  toastConfig: { duration: number; type: string },
) => {
  console.log('IN handleErrorUnknown error:');
  console.error(error);

  let url = '';
  if (error && error.config && error.config.url) {
    url = error.config.url;
  }

  if (url.endsWith('/auth/refresh')) {
    Toast.show('Unable to Validate your session', toastConfig);
    reset('Auth');
    Toast.show(error.response.data, toastConfig);
    return;
  }

  if (error.response && error.response.data && error.response.data.message) {
    Toast.show(
      'Please check server configuration, (' +
        error.response.data.message +
        ')',
      toastConfig,
    );
  } else {
    Toast.show(
      'Unknown Error Occurred, (' + error.message + ') Please try again.',
      toastConfig,
    );
  }
  throw {};
};

const handleErrorOffline = (
  error: any,
  toastConfig: { duration: number; type: string },
) => {
  console.log('IN handleErrorOffline error:');

  let url = '';
  if (error && error.config && error.config.url) {
    url = error.config.url;
  }

  if (url.endsWith('/auth/refresh')) {
    throw new JwtAuthConnectionError(
      'Your device is not connected to the internet and we are unable to refresh JWT with remote server',
    );
  }

  Toast.show('Offline. Please check your network connection.', toastConfig);
  throw new ServerUnreachableError(
    'Offline. Please check your network connection.',
  );
};

const handleErrorConnectionReset = (
  error: any,
  toastConfig: { duration: number; type: string },
) => {
  console.log('IN handleErrorConnectionReset error:');

  let url = '';
  if (error && error.config && error.config.url) {
    url = error.config.url;
  }

  // The client never received a response, and the request was never left
  if (url.endsWith('/auth/refresh')) {
    throw new JwtAuthConnectionError(
      'Your device is not connected to the internet and we are unable to refresh JWT with remote server',
    );
  } else {
    Toast.show(
      'Unable to connect to the server, please check your network connection or try again later.',
      toastConfig,
    );
  }

  throw {};
};

export const request = async (
  method: ERequest,
  url: string,
  payload: any = {},
  canSkipJwtCheck = false,
  typeForm = false,
): Promise<any> => {
  const config: { [key: string]: any } = {
    method: method,
    url: url,
    headers: {
      'Content-Type': typeForm ? 'multipart/form-data' : 'application/json',
    },
  };
  console.log(
    'IN request method: ' + ERequest[method] + ' url: ' + url + ' payload: ',
  );
  console.log(payload);

  const jwt = await AsyncStorage.getItem(EKeyAsyncStorage.jwt);

  if (jwt && !canSkipJwtCheck) {
    if (!isJwtTokenValid(jwt) && !canSkipJwtCheck) {
      reset('Auth');
      return;
    }
    config['headers']['Authorization'] = `Bearer ${jwt}`;
  }

  switch (method) {
    case ERequest.GET:
      // `params` are the URL parameters to be sent with the request
      // Must be a plain object or a URLSearchParams object
      // NOTE: params that are null or undefined are not rendered in the URL.
      // config["params"] = payload;
      config['params'] = payload;
      break;
    case ERequest.PUT:
    case ERequest.POST:
    case ERequest.DELETE:
    case ERequest.PATCH:
      config['data'] = payload;
      break;
  }

  try {
    return await axios(config);
  } catch (error: any) {
    return await handleAxiosError(error, url);
  }
};
