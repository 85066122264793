import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useTheme, Avatar, Button, Layout, Icon } from '@ui-kitten/components';

import Text from './Text';
import { Images } from 'assets/images';
import { IApproveRequest } from 'constants/types';
import { getRemainingTimeUntilMsTimestamp } from 'hooks';

interface ApproveRequestProps {
  item: IApproveRequest;
  onDeny?(): void;
  onApprove?(): void;
}

const ApproveRequest: React.FC<ApproveRequestProps> = ({
  item,
  onDeny,
  onApprove,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    name,
    surname,
    mobile,
    identity,
    company,
    accessPoint,
    reason,
    photo,
    timeout,
  } = item;

  const data = React.useMemo(
    () => [
      {
        title: t('reason'),
        data: reason,
      },
      {
        title: 'ID',
        data: identity,
      },
      {
        title: t('mobile_number'),
        data: mobile,
      },
      {
        title: t('company'),
        data: company,
      },
    ],
    [reason, identity, mobile, company],
  );
  const defaultRemainingTime = {
    seconds: '00',
    minutes: '00',
    hours: '00',
    days: '00',
  };

  const [remainingTime, setRemainingTime] =
    React.useState(defaultRemainingTime);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainingTime(timeout * 1000);
    }, 200);
    return () => clearInterval(intervalId);
  }, [timeout]);

  const updateRemainingTime = (countdown: number) => {
    setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));
  };

  return (
    <Layout level="1" style={styles.container}>
      <Layout
        style={[
          styles.header,
          { borderBottomColor: theme['background-basic-color-15'] },
        ]}
      >
        <Avatar
          size="giant"
          shape="rounded"
          source={
            photo ? { uri: `data:image/png;base64,${photo}` } : Images.avatar
          }
          style={styles.avatar}
        />
        <View>
          <Text category="b4">
            {name} {surname}
          </Text>
          <View style={{ flex: 1 }}>
            <Text category="c1" status="placeholder" marginTop={8}>
              {t('is_waiting_at')} {accessPoint}
            </Text>
          </View>
        </View>
      </Layout>
      <View style={styles.content}>
        {data.map((i, key) => {
          return (
            i.data && (
              <View key={key} style={styles.row}>
                <Text category="c1">{i.title}</Text>
                <View
                  style={[
                    styles.dot,
                    { backgroundColor: theme['background-basic-color-4'] },
                  ]}
                />
                <Text category="c2">{i.data}</Text>
              </View>
            )
          );
        })}
        <View style={styles.buttonsView}>
          <Button
            status="danger"
            style={styles.deny}
            children={t('deny')}
            onPress={onDeny}
            accessoryLeft={<Icon pack="assets" name="deny" />}
          />
          <Button
            status="info"
            style={styles.approve}
            children={t('approve')}
            onPress={onApprove}
            accessoryLeft={<Icon pack="assets" name="approve" />}
          />
        </View>
        <Text status="warning" marginTop={8} category="c3">
          {t('time_to_respond')}
          <Text status="warning" category="c3-m">
            {remainingTime.minutes !== '00' && `${remainingTime.minutes}:`}
            {t('seconds_left', { time: remainingTime.seconds })}
          </Text>
        </Text>
      </View>
    </Layout>
  );
};

export default ApproveRequest;

const styles = StyleSheet.create({
  container: {
    borderRadius: 16,
    shadowColor: 'rgba(29, 30, 44, 0.61)',
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 5,
  },
  header: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 8,
    borderBottomWidth: 1,
  },
  avatar: {
    marginRight: 8,
  },
  content: {
    padding: 16,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  buttonsView: {
    flexDirection: 'row',
    marginTop: 8,
  },
  deny: {
    flex: 1,
    marginRight: 16,
  },
  approve: {
    flex: 1,
  },
  dot: {
    width: 3,
    height: 3,
    borderRadius: 3,
    marginHorizontal: 8,
  },
});
