import React from 'react';
import {
  Image,
  Linking,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { useLayout } from 'hooks';
import { useNavigation, NavigationProp } from '@react-navigation/native';

import Text from './Text';

import * as ImageCache from 'react-native-expo-image-cache';

import { IService } from 'constants/types';
import { HomeMainParamList } from 'navigation/types';

interface ServiceItemProps {
  item: IService;
}

const ServiceItem: React.FC<ServiceItemProps> = ({ item }) => {
  const { navigate } = useNavigation<NavigationProp<HomeMainParamList>>();
  const { width } = useLayout();
  const { name, description, banner, url, externalWindow } = item;

  return (
    <TouchableOpacity
      onPress={() => {
        if (externalWindow || Platform.OS === 'web') {
          Linking.openURL(url);
        } else {
          navigate('WebViewScreen', { url: url });
        }
      }}
      activeOpacity={0.7}
      style={[styles.container, { width: (width - 48) / 3 - 16 }]}
    >
      <View style={styles.viewBanner}>
        {Platform.OS === 'web' ? (
          <Image source={{ uri: banner }} style={styles.banner} />
        ) : (
          <ImageCache.Image uri={banner} style={styles.banner} />
        )}
      </View>
      <Text category="b7" marginTop={8} numberOfLines={2}>
        {name}
      </Text>
      <Text category="c3" status="placeholder" marginTop={2} numberOfLines={2}>
        {description}
      </Text>
    </TouchableOpacity>
  );
};

export default ServiceItem;

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 8,
    marginBottom: 16,
  },
  viewBanner: {
    aspectRatio: 1 / 1,
    borderRadius: 6,
    overflow: 'hidden',
  },
  banner: {
    width: '100%',
    height: '100%',
  },
});
