import React from 'react';
import { Linking, Platform, Share, StyleSheet, View } from 'react-native';
import {
  Container,
  Content,
  ModalConfirm,
  NavigationAction,
  Text,
  UserItem,
} from 'components';
import {
  Button,
  Icon,
  Layout,
  TopNavigation,
  useTheme,
} from '@ui-kitten/components';
import {
  DrawerActions,
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import * as MailComposer from 'expo-mail-composer';
import { useAppDispatch } from 'store/store';
import { useTranslation } from 'react-i18next';
import { useCopy, useLayout, useModal } from 'hooks';
import { useToast } from 'react-native-toast-notifications';

//@ts-ignore
import UserAvatar from 'react-native-user-avatar';

import dayjs from 'dayjs';
import Configs from 'configs';
import { deleteInviteByUUID } from 'store/slices/inviteSlice';
import { InvitesMainParamList, MainStackParamList } from 'navigation/types';
import { IconName } from 'assets/icons';
import { EvaStatus } from '@ui-kitten/components/devsupport';
import { setAppLoading } from 'store/slices/appSlice';

type InviteShareRouteProp = RouteProp<InvitesMainParamList, 'InviteShare'>;

type ButtonType = {
  icon: keyof IconName;
  status?: EvaStatus;
  children: string;
  onPress?(): void;
};

const InviteShare: React.FC = () => {
  const theme = useTheme();
  const toast = useToast();
  const navigation = useNavigation();
  const { navigate } = useNavigation<NavigationProp<MainStackParamList>>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'share_invite']);
  const [_, copy] = useCopy();
  const { width } = useLayout();
  const { visible, hide, show } = useModal();

  const {
    params: { share },
  } = useRoute<InviteShareRouteProp>();

  const handleDelete = React.useCallback(() => {
    hide();
    if (share && share.uuid) {
      dispatch(deleteInviteByUUID({ uuid: share.uuid }));
    }
    setTimeout(() => {
      dispatch(setAppLoading(false));
      navigate('Invitations', { screen: 'InvitationsScreen' });
    }, 3000);
  }, [share]);

  const onEmail = React.useCallback(async () => {
    try {
      await MailComposer.composeAsync({
        body: share.message,
      });
    } catch (error) {
      console.log('error', error);
    }
  }, [share]);

  const onWhatsApp = React.useCallback(async () => {
    if (!share.message && !share.mobileNumber) {
      return;
    }
    const URL = 'https://wa.me';
    const number = share.mobileNumber
      ?.replace(/[^\w\s]/gi, '')
      .replace(/ /g, '');
    let url = `${URL}/${number}`;
    url += `?text=${encodeURIComponent(share.message)}`;
    Linking.openURL(url);
  }, [share]);

  const onShare = React.useCallback(async () => {
    try {
      if (Platform.OS === 'web') {
        alert(`Uh oh, sharing isn't available on your platform`);
        return;
      }
      const result = await Share.share({
        message: share.message,
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      console.log('error', error);
    }
  }, [share]);

  const onCopy = React.useCallback(() => {
    try {
      copy(share.message);
      toast.show('Copied to clipboard.', {
        type: 'success',
        placement: 'top',
        duration: 2000,
        successColor: theme['color-primary-500'],
        animationType: 'slide-in',
      });
    } catch (error) {
      toast.show('Copy failed.', {
        type: 'danger',
        dangerColor: theme['color-danger-500'],
        placement: 'top',
        duration: 2000,
        animationType: 'slide-in',
      });
    }
  }, [share]);

  const buttons: ButtonType[] = [
    {
      icon: 'share',
      children: t('share_invite:share'),
      onPress: onShare,
      status: 'primary',
    },
    {
      icon: 'email',
      children: t('common:email'),
      onPress: onEmail,
      status: 'warning',
    },
    {
      icon: 'copy',
      children: t('share_invite:copy_clipboard'),
      onPress: onCopy,
      status: 'control',
    },
    {
      icon: 'whats-app',
      children: t('share_invite:whatsapp'),
      onPress: onWhatsApp,
      status: 'info',
    },
  ];

  return (
    <Container level="2">
      <TopNavigation
        style={styles.topNavigation}
        appearance="control"
        //title={t('share_invite:share_invite')}
        accessoryLeft={
          <View style={styles.box1}>
            <NavigationAction
              icon="menu"
              status="basic"
              onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
            />
            <UserItem />
          </View>
        }
        accessoryRight={
          <NavigationAction
            icon="cancel-invite"
            status="placeholder"
            onPress={show}
          />
        }
      />
      <Content isPadding>
        <Text category="b2" marginTop={16}>
          {t('share_invite:share_invite')}
        </Text>
        <Text category="c3" marginTop={4} status="placeholder">
          {t('share_invite:share_invite_description')}
        </Text>
        <Layout style={styles.box}>
          <Layout level="2" style={styles.header}>
            <UserAvatar
              size={48}
              style={{
                width: 48,
                height: 48,
                borderRadius: 16,
                overflow: 'hidden',
              }}
              src="" // visitor avatar uri
              name={`${share.firstName} ${share.lastName}`}
              colors={[
                theme['color-primary-500'],
                theme['color-info-500'],
                theme['color-warning-500'],
                theme['color-danger-500'],
                theme['background-basic-color-6'],
                theme['background-basic-color-7'],
                theme['background-basic-color-8'],
                theme['background-basic-color-13'],
                theme['background-basic-color-14'],
              ]}
            />
            <View style={styles.name}>
              <Text category="b4">
                {share.firstName && `${share.firstName} `}
                {share.lastName}
              </Text>
              <Text status="warning" category="c1" marginTop={8}>
                {t('share_invite:invite_generated')}
              </Text>
            </View>
          </Layout>
          <View style={styles.content}>
            <View
              style={[
                styles.time,
                {
                  backgroundColor: theme['color-primary-500'],
                  shadowColor: Configs.BUTTON_SHADOW_COLOR,
                },
              ]}
            >
              <Text status="white" category="c3">
                {dayjs(share.activeDate).format('MM/DD/YYYY')}
              </Text>
            </View>
            <Text category="b4" marginTop={16}>
              {t('share_invite:message')}
            </Text>
            <Text marginTop={4} category="b7" status="placeholder">
              {share.message}
            </Text>
          </View>
        </Layout>
        <View style={styles.viewButton}>
          {buttons.map((item, index) => {
            return (
              <Button
                key={index}
                style={[
                  styles.button,
                  {
                    width: width / 2 - 32,
                    marginRight: index % 2 === 0 ? 16 : 0,
                  },
                ]}
                {...item}
                accessoryLeft={<Icon pack="assets" name={item.icon} />}
              />
            );
          })}
        </View>
      </Content>
      {!!share && (
        <ModalConfirm
          visible={visible}
          onBackdropPress={hide}
          title={`${t('common:confirm')}`}
          description={t('common:delete_invitation_for', {
            firstName: share.firstName || '',
            lastName: share.lastName || '',
          })}
          content={{
            activeDate: share.activeDate,
            expiryDate: share?.expiryDate,
          }}
          buttonLeft={{ onPress: hide, title: t('common:cancel') }}
          buttonRight={{ onPress: handleDelete, title: t('common:ok') }}
        />
      )}
    </Container>
  );
};

export default InviteShare;

const styles = StyleSheet.create({
  box: {
    borderRadius: 16,
    marginTop: 6,
    shadowColor: 'rgba(0, 64, 128, 1)',
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 5,
  },
  header: {
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    paddingTop: 16,
    paddingBottom: 24,
    paddingHorizontal: 16,
  },
  time: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    backgroundColor: 'blue',
    alignSelf: 'flex-start',
    borderRadius: 8,
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 1,
    shadowRadius: 20,
    elevation: 24,
  },
  name: {
    marginLeft: 16,
  },
  viewButton: {
    marginTop: 24,
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'stretch',
  },
  button: {
    marginBottom: 16,
    borderRadius: 12,
  },
  box1: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  topNavigation: {
    marginTop: 12,
  },
});
