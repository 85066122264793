import React from 'react';
import {
  View,
  StyleSheet,
  Platform,
  ScrollView,
  RefreshControl,
} from 'react-native';
import { Layout, TopNavigation } from '@ui-kitten/components';
import {
  Container,
  NavigationAction,
  NotificationItem,
  Text,
  UserItem,
} from 'components';
import { useNavigation, NavigationProp } from '@react-navigation/native';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useBoolean, useDrawer, useLayout } from 'hooks';
import { useTranslation } from 'react-i18next';

import {
  getAllNotifications,
  notificationSelector,
  readNotificationMessage,
} from 'store/slices/notificationSlice';
import { HomeMainParamList } from 'navigation/types';
import { INotification } from 'constants/types';

const NotificationScreen = React.memo(() => {
  const { bottom } = useLayout();
  const dispatch = useAppDispatch();
  const { openDrawer } = useDrawer();
  const { navigate } = useNavigation<NavigationProp<HomeMainParamList>>();
  const { t } = useTranslation(['common', 'request']);
  const { notifications } = useAppSelector(notificationSelector);

  const [loading, { on: onLoading, off: offLoading }] = useBoolean(false);

  const handleRefetch = React.useCallback(() => {
    onLoading();
    dispatch(
      getAllNotifications({
        payload: {
          start: 0,
          limit: 500,
        },
        refresh: true,
        force: true,
      }),
    );
    offLoading();
  }, []);

  const handleOnMessage = React.useCallback((notification: INotification) => {
    dispatch(readNotificationMessage({ uuid: notification.uuid }));
    navigate('NotificationDetails', { notification: notification });
  }, []);

  return (
    <Container level="2">
      <TopNavigation
        style={styles.topNavigation}
        appearance="control"
        accessoryLeft={
          <View style={styles.header}>
            <NavigationAction icon="menu" status="basic" onPress={openDrawer} />
            <UserItem />
          </View>
        }
      />
      <Text
        category="b4-b"
        marginBottom={12}
        marginTop={12}
        marginHorizontal={16}
      >
        {t('common:notifications')}
      </Text>
      <Layout
        style={[
          {
            flex: 1,
            marginHorizontal: 16,
            borderRadius: 16,
            marginBottom: Platform.OS === 'ios' ? bottom + 28 : bottom + 58,
          },
        ]}
      >
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingHorizontal: 16, paddingTop: 16 }}
          refreshControl={
            <RefreshControl refreshing={loading} onRefresh={handleRefetch} />
          }
        >
          {notifications.map((item) => {
            return (
              <NotificationItem
                item={item}
                key={`${item.uuid} ${item.dateSent}`}
                style={styles.item}
                onPress={() => handleOnMessage(item)}
              />
            );
          })}
        </ScrollView>
      </Layout>
    </Container>
  );
});

export default NotificationScreen;

const styles = StyleSheet.create({
  topNavigation: {
    marginTop: 12,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tooltip: {
    borderWidth: 1,
    borderColor: 'transparent',
    width: 190,
    alignItems: 'flex-start',
  },
  buttonFilter: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 140,
    height: 40,
    borderRadius: 6,
    paddingHorizontal: 12,
  },
  contentStyle: {
    marginTop: 8,
  },
  flexOne: {
    flex: 1,
  },
  contentContainerStyle: {
    paddingTop: 12,
    paddingHorizontal: 16,
  },
  item: {
    marginBottom: 16,
  },
});
