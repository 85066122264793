import { EV_TRACK_ENDPOINT } from './api';
import {
  ICredential,
  IResponse,
  IVisitor,
  IVisitReason,
} from 'constants/types';
import { ERequest, request } from 'utils/request';

/**
 * List All Visitors
 */

export const requestListAllVisitors = async (): Promise<
  IVisitor[] | undefined
> => {
  try {
    const url = `${EV_TRACK_ENDPOINT}/visitor/list`;

    const { data } = await request(ERequest.GET, url);

    return data;
  } catch (error) {
    return undefined;
  }
};

/**
 * Get Visitor By UUID
 */

type GetVisitorByUUIDPayload = {
  uuid: string;
};

export const requestGetVisitorByUUID = async (
  payload: GetVisitorByUUIDPayload,
): Promise<any | undefined> => {
  const url = `${EV_TRACK_ENDPOINT}/visitor/uuid/${payload.uuid}`;

  const { data } = await request(ERequest.POST, url);

  return data;
};

/**
 * Get Visitor By Id
 */

type GetVisitorByIdPayload = {
  id: string;
};

type GetVisitorByIdResult = {
  comments?: string | null;
  company?: string | null;
  credentials?: ICredential[];
  dateOfBirth?: null;
  email?: string | null;
  firstName?: string | null;
  gender?: string | null;
  id: number;
  identityNr?: string | null;
  initials?: string | null;
  lastName?: string | null;
  mobileNumber?: string | null;
  visitReason?: IVisitReason;
  thumbnail?: string;
  uuid?: string;
};

export const requestGetVisitorById = async (
  payload: GetVisitorByIdPayload,
): Promise<GetVisitorByIdResult | undefined> => {
  try {
    const url = `${EV_TRACK_ENDPOINT}/visitor/${payload.id}`;

    const { data } = await request(ERequest.GET, url);

    return data;
  } catch (error) {
    return undefined;
  }
};

/**
 * Create Visitor
 */

type CreateVisitorPayload = {
  firstName?: string;
  lastName: string;
  email?: string;
  mobileNumber: string;
  identityNr?: string;
  company?: string;
  visitReason?: IVisitReason;
  tempPhotoUUID?: string;
};

type CreateVisitorResult = {
  code: string;
  reason: string;
  message: string;
  id: string | null;
  visitor: IVisitor;
};

export const requestCreateVisitor = async (
  payload: CreateVisitorPayload,
): Promise<CreateVisitorResult | undefined> => {
  const url = `${EV_TRACK_ENDPOINT}/visitor`;

  const { data } = await request(ERequest.POST, url, payload);

  return data;
};

/**
 * Update Visitor
 */

type UpdateVisitorPayload = {
  firstName: string;
  lastName: string;
  email?: string;
  mobileNumber: string;
  identityNr?: string;
  company?: string;
  visitReason?: IVisitReason;
  id: number;
};

type UpdateVisitorResult = {
  code: string;
  reason: string;
  message: string;
  id: string | null;
  visitor: IVisitor;
};

export const requestUpdateVisitor = async (
  payload: UpdateVisitorPayload,
): Promise<UpdateVisitorResult | undefined> => {
  const url = `${EV_TRACK_ENDPOINT}/visitor/basic/${payload.id}`;

  const { data } = await request(ERequest.PUT, url, payload);

  return data;
};

/**
 * Delete Visitor By Id
 */

type DeleteVisitorByIdPayload = {
  id: number;
};

export const requestDeleteVisitorById = async (
  payload: DeleteVisitorByIdPayload,
): Promise<IResponse | undefined> => {
  const url = `${EV_TRACK_ENDPOINT}/visitor/${payload.id}`;

  const { data } = await request(ERequest.DELETE, url, payload);

  return data;
};

/**
 * Files Upload
 */

export const requestFilesUpload = async (
  payload: FormData,
): Promise<string | undefined> => {
  console.log('IN requestFilesUpload payload:');
  console.log(payload);

  const url = `${EV_TRACK_ENDPOINT}/files/upload`;

  const { data } = await request(ERequest.POST, url, payload, false, true);

  return data;
};


/**
 * Testing Endpoints
 * */
export const doSimulateApprovalRequest = async (): Promise<
    IVisitor[] | undefined
> => {
  try {
    const url = `${EV_TRACK_ENDPOINT}/tests/sim-approval-request`;

    const { data } = await request(ERequest.GET, url);
    return data;
  } catch (error) {
    return undefined;
  }
};
