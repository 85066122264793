import React from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { useTheme, Icon, TopNavigationAction } from '@ui-kitten/components';
import { useNavigation } from '@react-navigation/native';

import { IconName } from 'assets/icons';

interface NavigationActionProps {
  icon?: keyof IconName;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  marginHorizontal?: number;
  marginVertical?: number;
  onPress?: () => void;
  status?: 'basic' | 'white' | 'primary' | 'placeholder';
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
}

const NavigationAction: React.FC<NavigationActionProps> = ({
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  marginHorizontal,
  marginVertical,
  onPress,
  icon,
  status = 'basic',
  disabled,
  style,
}) => {
  const theme = useTheme();
  const { goBack } = useNavigation();

  const _onPress = React.useCallback(() => {
    if (onPress) {
      onPress && onPress();
    } else {
      goBack();
    }
  }, [onPress, goBack]);

  const getIconColor = (
    status: 'basic' | 'white' | 'primary' | 'placeholder',
  ): string => {
    switch (status) {
      case 'basic':
        return theme['icon-basic-color'];
      case 'white':
        return theme['color-basic-100'];
      case 'primary':
        return theme['color-primary-500'];
      case 'placeholder':
        return theme['text-placeholder-color'];
      default:
        return theme['color-primary-100'];
    }
  };

  return (
    <TopNavigationAction
      onPress={_onPress}
      disabled={disabled}
      activeOpacity={0.7}
      style={[
        styles.container,
        style,
        {
          marginBottom: marginBottom,
          marginTop: marginTop,
          marginLeft: marginLeft,
          marginRight: marginRight,
          marginHorizontal: marginHorizontal,
          marginVertical: marginVertical,
          height: 40,
          width: 40,
        },
      ]}
      icon={
        <Icon
          pack="assets"
          name={icon || 'back'}
          style={[styles.icon, { tintColor: getIconColor(status) }]}
        />
      }
    />
  );
};
export default NavigationAction;

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 24,
    height: 24,
  },
});
