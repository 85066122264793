import AsyncStorage from '@react-native-async-storage/async-storage';
import {
    IApproveRequest,
    IApprovalAuthorisationRequest,
    EKeyAsyncStorage, EPushTokenType,
} from 'constants/types';
import {isJwtTokenValid} from 'utils/isJwtTokenValid';
import {ERequest, request} from 'utils/request';
import {EV_TRACK_ENDPOINT} from './api';

/**
 * List Pending Visitor Registrations
 */

export const requestListPendingVisitorRegistrations = async (): Promise<IApproveRequest[] | undefined> => {
    let url = `${EV_TRACK_ENDPOINT}/process/pending`;

    // try and get JWT
    const jwt = await AsyncStorage.getItem(EKeyAsyncStorage.jwt);

    // try and get Push Token
    const notificationToken = await AsyncStorage.getItem(
        EKeyAsyncStorage.notificationToken,
    );
    const notificationTokenType = Number(await AsyncStorage.getItem(
        EKeyAsyncStorage.notificationTokenType,
    ));

    if (notificationToken && notificationTokenType > 0) {
        // Strategy 1: use push token to avoid possible race condition on startup with /auth/refresh timing delays while cycling JWT
        url = `${EV_TRACK_ENDPOINT}/process/pending-by-token`;
        console.log("IN requestListPendingVisitorRegistrations TRYING: " + url);
        const {data} = await request(ERequest.POST, url, {
            type: EPushTokenType[notificationTokenType],
            token: notificationToken,
        });
        if (data) {
            console.log("DONE requestListPendingVisitorRegistrations data: " + data.length);
        }
        return data;
    } else if (jwt != null && jwt && isJwtTokenValid(jwt)) {
        // Strategy 2: fall back on JWT
        console.log("IN requestListPendingVisitorRegistrations TRYING: " + url);
        const {data} = await request(ERequest.GET, url);
        if (data) {
            console.log("DONE requestListPendingVisitorRegistrations data: " + data.length);
        }
        return data;
    } else {
        return;
    }
};

/**
 * Deny Visitor Registration Transaction
 */
export const requestDenyVisitor = async (
    payload: IApprovalAuthorisationRequest,
): Promise<any | undefined> => {
    const url = `${EV_TRACK_ENDPOINT}/process/request/visitor/deny`;

    const {data} = await request(ERequest.POST, url, payload, true);

    return data;
};

/**
 * Approve Visitor Registration Transaction
 */
export const requestApproveVisitor = async (
    payload: IApprovalAuthorisationRequest,
): Promise<any | undefined> => {
    const url = `${EV_TRACK_ENDPOINT}/process/request/visitor/approve`;
    const {data} = await request(ERequest.POST, url, payload, true);
    return data;
};
