import React from 'react';
import ApproveRequests from 'screens/requests/ApproveRequests';
import createStackNavigator from './createStackNavigator';
import { RequestMainParamList } from './types';

const Stack = createStackNavigator<RequestMainParamList>();

const RequestsMainNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName="ApproveRequests"
    >
      <Stack.Screen name="ApproveRequests" component={ApproveRequests} />
    </Stack.Navigator>
  );
};

export default RequestsMainNavigator;
