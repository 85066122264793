import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import {
  Calendar,
  CalendarRange,
  I18nConfig,
  NativeDateService,
  RangeCalendar,
  useTheme,
  Modal,
  Layout,
  Button,
} from '@ui-kitten/components';
import { useLayout } from 'hooks';
import { useTranslation } from 'react-i18next';

import Text from './Text';

interface ModalCalendarProps {
  title: string;
  visible?: boolean;
  onBackdropPress?(): void;
  onButton?(): void;
  date?: Date | undefined;
  min?: Date;
  max?: Date;
  rangeCalendar?: boolean;
  range?: CalendarRange<Date> | undefined;
  onChangeDate?: ((date: Date) => void) | undefined;
  onSelect?: ((range: CalendarRange<Date>) => void) | undefined;
}

function ModalCalendar({
  title,
  date,
  visible,
  min,
  max,
  rangeCalendar,
  range,
  onBackdropPress,
  onButton,
  onChangeDate,
  onSelect,
}: ModalCalendarProps) {
  const { t } = useTranslation('calendar');
  const { bottom, width, height } = useLayout();
  const themes = useTheme();

  const i18n: I18nConfig = {
    dayNames: {
      short: [
        t('sun'),
        t('mon'),
        t('tue'),
        t('wed'),
        t('thu'),
        t('fri'),
        t('sat'),
      ],
      long: [
        t('sunday'),
        t('monday'),
        t('tuesday'),
        t('wednesday'),
        t('thursday'),
        t('friday'),
        t('saturday'),
      ],
    },
    monthNames: {
      short: [
        t('jan'),
        t('feb'),
        t('mar'),
        t('apr'),
        t('may'),
        t('jun'),
        t('jul'),
        t('aug'),
        t('sep'),
        t('oct'),
        t('nov'),
        t('dec'),
      ],
      long: [
        t('january'),
        t('february'),
        t('march'),
        t('april'),
        t('may'),
        t('june'),
        t('july'),
        t('august'),
        t('september'),
        t('october'),
        t('november'),
        t('december'),
      ],
    },
  };

  const localeDateService = new NativeDateService('en', {
    i18n,
    startDayOfWeek: 1,
  });

  return (
    <Modal
      visible={visible}
      onBackdropPress={() => {
        onBackdropPress && onBackdropPress();
      }}
      style={{
        backgroundColor: 'transparent',
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        height: '100%',
        width: '100%',
      }}
      backdropStyle={styles.container}
    >
      <View
        style={{
          height: height,
          flex: 1,
          width: width,
          justifyContent: 'flex-end',
          backgroundColor: 'rgba(30, 31, 32, 0.86)',
        }}
      >
        <TouchableOpacity
          activeOpacity={1}
          onPress={() => {
            onBackdropPress && onBackdropPress();
          }}
          style={StyleSheet.absoluteFillObject}
        />
        {rangeCalendar ? (
          <Layout level="2" style={styles.calendarView}>
            <View style={styles.headerModal}>
              <Text category="h6">{title}</Text>
              <View
                style={[
                  styles.line,
                  { backgroundColor: themes['background-basic-color-15'] },
                ]}
              />
            </View>
            <RangeCalendar
              style={[
                styles.calendar,
                { width: width, paddingBottom: bottom + 8 },
              ]}
              range={range}
              onSelect={onSelect}
              dateService={localeDateService}
              min={min}
              max={max}
            />
            <Button
              style={[styles.button, { marginBottom: bottom + 8 }]}
              status="basic"
              onPress={onButton}
              children={t('button').toString()}
            />
          </Layout>
        ) : (
          <Layout level="2" style={styles.calendarView}>
            <View style={styles.headerModal}>
              <Text category="h6">{title}</Text>
              <View
                style={[
                  styles.line,
                  { backgroundColor: themes['background-basic-color-15'] },
                ]}
              />
            </View>
            <Calendar
              min={min}
              max={max}
              style={[
                styles.calendar,
                { width: width, paddingBottom: bottom + 8 },
              ]}
              date={date}
              onSelect={onChangeDate}
              dateService={localeDateService}
            />
          </Layout>
        )}
      </View>
    </Modal>
  );
}

export default React.forwardRef(ModalCalendar) as (
  props: ModalCalendarProps & {
    ref?: React.ForwardedRef<{ show: () => void; hide: () => void }>;
  },
) => ReturnType<typeof ModalCalendar>;

const styles = StyleSheet.create({
  modalStyle: {
    borderTopRightRadius: 24,
    borderTopLeftRadius: 24,
  },
  handleStyle: {
    height: 0,
  },
  headerModal: {
    paddingHorizontal: 16,
    paddingBottom: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  styleFlatList: {
    paddingHorizontal: 24,
  },
  line: {
    height: 1,
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 0,
  },
  calendarView: {
    paddingTop: 24,
    paddingBottom: 8,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  calendar: {
    alignSelf: 'center',
    paddingTop: 0,
  },
  button: {
    marginTop: 24,
    marginHorizontal: 12,
  },
  container: {
    flex: 1,
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
  },
});
