import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { requestDeleteInviteByUUID, requestListAllInvites } from 'api/invite';
import { AppThunk } from '../store';

import { RootState } from '../rootReducer';
import { EKeyAsyncStorage, IInvite } from 'constants/types';
import dayjs from 'dayjs';
import { sortAlphabetical } from 'utils/sortAlphabetical';
import { setAppLoading } from './appSlice';
import Configs from 'configs';
import AsyncStorage from '@react-native-async-storage/async-storage';
export interface IInviteState {
  invites: IInvite[];
  updatedAt: number;
}

export const initialState: IInviteState = {
  invites: [],
  updatedAt: 0,
};

export const inviteSlice = createSlice({
  name: 'invite',
  initialState,
  reducers: {
    setInvites: (
      state: IInviteState,
      { payload }: PayloadAction<IInvite[]>,
    ) => {
      state.invites = payload;
      state.updatedAt = dayjs().unix();
    },
    clearInvites: (state: IInviteState) => {
      state.invites = [];
      state.updatedAt = 0;
    },
  },
});

export const { setInvites, clearInvites } = inviteSlice.actions;

export const getAllInvites =
  (force?: boolean): AppThunk =>
  async (dispatch, getState) => {
    try {
      if (!force) {
        const { updatedAt } = inviteSelector(getState());
        if (dayjs().unix() < updatedAt + Configs.CACHE_TTL_SECS) {
          return;
        }
      }

      const result = await requestListAllInvites();

      if (result) {
        for (const item of result) {
          const _visitors = await AsyncStorage.getItem(
            EKeyAsyncStorage.visitors,
          );

          if (_visitors) {
            const visitors = JSON.parse(_visitors);

            const newVisitors = visitors.map((i: any) => {
              if (i.uuid === item.uuid) {
                return {
                  ...i,
                  credentials: item.credentials,
                };
              } else {
                return i;
              }
            });
            await AsyncStorage.setItem(
              EKeyAsyncStorage.visitors,
              JSON.stringify(newVisitors),
            );
          }
        }

        const _invites = sortAlphabetical([...result]);

        dispatch(setInvites(_invites));
      }
    } catch (error) {
      console.log('invites error', error);
    }
  };

export const deleteInviteByUUID =
  ({ uuid }: { uuid: string }): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoading(true));
      let _visitors: IInvite[] = [];

      const result = await requestDeleteInviteByUUID({ uuid });

      if (!!result && result.code === '200') {
        const visitorsCache = await AsyncStorage.getItem(
          EKeyAsyncStorage.visitors,
        );

        if (visitorsCache) {
          _visitors = JSON.parse(visitorsCache);

          const listVisitor = [];

          for (const item of _visitors) {
            const newCredentials = item.credentials?.filter(
              (i) => i.uuid !== uuid,
            );

            listVisitor.push({ ...item, credentials: newCredentials || [] });
          }

          await AsyncStorage.setItem(
            EKeyAsyncStorage.visitors,
            JSON.stringify(listVisitor),
          );
        }

        setTimeout(() => {
          dispatch(getAllInvites(true));
          dispatch(setAppLoading(false));
        }, 3000);
      }
    } catch (error: any) {
      dispatch(setAppLoading(false));
    }
  };

export const inviteSelector = (state: RootState) => state.invite;

export default inviteSlice.reducer;
