import { IconName } from 'assets/icons';
import { StyleProp, ViewStyle } from 'react-native';

export interface ShapeProps {
  icon: keyof IconName;
  size?: 'large' | 'medium' | 'small' | 'tiny';
  style?: StyleProp<ViewStyle>;
  status?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'green'
    | 'basic'
    | 'danger'
    | 'dark';
  onPress?(): void;
}

export interface DrawerItemProps {
  title?: string;
  status:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'green'
    | 'basic'
    | 'danger'
    | 'dark';
  icon: keyof IconName;
  onPress(): void;
  isEmpty?: boolean;
}
export interface ButtonType {
  status: string;
  title: string;
  onPress: () => void;
  id: number;
}

export interface IAction {
  icon: keyof IconName;
  title: string;
  enable?: boolean;
  status:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'green'
    | 'basic'
    | 'orange'
    | 'yellow'
    | 'red';

  onPress?(): void;
}

export interface MobileAppOptions {
  uuid: string;
  oneSignalToken: string;
  username: string;
  name: string;
  surname: string;
  email: string;
  siteName: string;
  siteBanner: string;
  thumbnail: string;
  enableActivityHistory: boolean;
  enableQuickCode: boolean;
  enableVisitorShortcuts: boolean;
  defaultCountryIsoCode: string;
  visitorRegRequiredName: boolean;
  visitorRegRequiredSurname: boolean;
  visitorRegRequiredMobileNumber: boolean;
  visitorRegRequiredReason: boolean;
  visitorRegRequiredEmail: boolean;
  visitorRegRequiredIdNumber: boolean;
  visitorRegRequiredCompany: boolean;
  defaultDurationOfStaySecs: number;
  maxDurationOfStaySecs: number;
  visitReasons: IVisitReason[];
  emergencyNumbers: IEmergencyNumber[];
  services: IService[];
  bookings: IBooking[];
}

export interface IVisitor {
  company?: string;
  email?: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  id?: number;
  identityNr?: string;
  tenantId?: number;
  userId?: number;
  uuid?: string;
  visitReasonId?: number | null;
  visitorId?: number;
  visitorID?: number;
  visitReason?: IVisitReason;
  thumbnail?: string;
}

export interface ICredential {
  uuid: string;
  readerType: string;
  uniqueIdentifier?: string;
  activeDate: Date;
  expiryDate: Date;
  pin: number;
  inviteMessage?: string;
}

export interface IShare {
  uuid: string;
  activeDate: Date | string;
  expiryDate: Date | string;
  id?: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  message: string;
}

export interface IInvite {
  comments?: string | null;
  company?: string | null;
  credentials?: ICredential[];
  dateOfBirth?: null;
  email?: string | null;
  firstName: string;
  gender?: string | null;
  id: number;
  identityNr?: number | null;
  initials?: string | null;
  lastName: string;
  mobileNumber: string | null;
  visitReason?: string | null;
  uuid?: string;
  thumbnail?: string;
}

export interface ICreateInvite {
  visitorId: number | null;
  activeDate: string;
  expiryDate: string;
  visitReasonId?: number;
}

export interface ICreateInviteResult {
  uuid: string;
  code: string;
  reason: string;
  message: string;
  id: string;
  activeDate: string;
  expiryDate: string;
  firstName: string;
  lastName: string;
}

export interface IVisitReason {
  id?: number;
  reason: string;
  optionDefaultReason?: boolean;
}

export interface IResponse {
  code: string;
  reason: string;
  message: string;
  id: string | null;
}

export interface IEmergencyNumber {
  id: number;
  name: string;
  telephoneNumber: string;
}

export interface IEntry {
  name: string;
  transactionUuid: string | null;
  datetime: string;
  type: EEvent;
  direction: EDirection;
  description: string;
}

export interface IEvent {
  entries: IEntry[];
  startIndex: number;
  countReturned: number;
  totalCount: number;
}

export interface INotificationModel {
  entries: INotification[];
  startIndex: number;
  countReturned: number;
  totalCount: number;
}

export interface INotification {
  uuid: string;
  priority: NotificationMessagePriority;
  dateSent: Date;
  senderName: string;
  subject: string;
  brief: string;
  read: boolean;
  body: string;
  status: string;
  photo: string;
}

export interface IService {
  uuid: string;
  name: string;
  description: string;
  banner: string;
  url: string;
  externalWindow: boolean;
  priority: number;
}

export interface IBooking {
  uuid: string;
  name: string;
  description: string;
  openTime: string;
  closeTime: string;
  pax: number;
  telephoneNumber: string;
  banner: string;
  url: string;
  exterenalWindow: boolean;
  priority: number;
}
export interface IApproveRequest {
  uuid: string;
  userUuid: string;
  action: string;
  timeout: number;
  initials: string;
  name: string;
  surname: string;
  mobile: string;
  identity: string;
  company: string;
  accessPoint: string;
  reason: string;
  photo: string;
}

export interface IDocument {
  uuid: string;
  filename: string;
  effectiveDate: string;
  expiresDate: string;
  comments: string;
}

export interface IQrCode {
  uuid: string;
  exp: number;
  nbf: number;
  ttl: number;
}

export interface IQrCodeMobile {
  uuid: string;
  exp: number;
  nbf: number;
  ttl: number;
  cacheTtl: number;
  isNew: boolean;
}

export interface IApprovalAuthorisationRequest {
  userUuid: string;
  transactionUuid: string;
}

export enum EDirection {
  IN = 'IN',
  OUT = 'OUT',
  GRANTED = 'GRANTED',
}

export enum MODE {
  ADD,
  EDIT,
}

export interface IPushNotificationPayload {
  uuid: string;
  userUUID: string;
  timestamp: string;
  action: string;
  timeout: string;
  subject: string;
  body: string;
  initials: string;
  name: string;
  surname: string;
  mobile: string;
  identity: string;
  company: string;
  accessPoint: string;
}

export type EventType = {
  type: FILTER_ENUM;
  title: string;
};

export enum EEvent {
  VISITOR_REGISTRATION = 'VISITOR_REGISTRATION',
  EVENT_ACCESS_GRANTED = 'EVENT_ACCESS_GRANTED',
  EVENT_ACCESS_DENIED = 'EVENT_ACCESS_DENIED',
  EVENT_ACCESS_OFFLINE = 'EVENT_ACCESS_OFFLINE',
}

export enum NotificationMessagePriority {
  LOW = 'LOW',
  NORMAL = 'NORMAL',
  HIGH = 'HIGH',
}

export enum NotificationMessageStatus {
  UNKNOWN = 'UNKNOWN',
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  DELETE = 'DELETE',
}

export enum EKeyAsyncStorage {
  theme = 'theme',
  intro = 'intro',
  jwt = 'jwt',
  notificationToken = 'notification_token',
  notificationTokenType = 'notification_token_type',
  emergencyNumbers = 'emergency_numbers',
  visitors = 'visitors',
  invites = 'invites',
  approvals = 'approvals',
  qrCode = 'qr_code',
  sessionHasExpired = 'session_has_expired',
  events = 'events',
  eventsCustom = 'events_custom',
  notifications = 'notifications',
  user = 'user',
  email = 'email',
}

export enum EPushTokenType {
  UNKNOWN,
  APNS,
  FCM,
  ONE_SIGNAL,
  HMS,
}

export interface IPushTokenPayload {
  type: EPushTokenType;
  token: string;
}

export enum FILTER_ENUM {
  TODAY = 'TODAY',
  LAST_7_DAYS = 'LAST 7 DAYS',
  LAST_14_DAYS = 'LAST 14 DAYS',
  LAST_30_DAYS = 'LAST 30 DAYS',
  CUSTOM_DATE = 'CUSTOM DATE',
}

export interface IChoosePhoto {
  title: string;
  type: ETypePhoto;
}

export enum ETypePhoto {
  TAKE_PHOTO = 'TAKE_PHOTO',
  LIBRARY = 'LIBRARY',
}

export const INJECTED_JAVASCRIPT = `(function() {
	window.ReactNativeWebView.postMessage(JSON.stringify(window.location));
    })();`;
