import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { Spinner, SpinnerProps } from '@ui-kitten/components';

interface LoadingIndicatorProps extends SpinnerProps {
  style?: ViewStyle;
  flexOne?: boolean;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ style, flexOne, ...rest }) => {
  return (
    <View style={[style, { flex: flexOne ? 1 : 0 }, styles.container]}>
      <Spinner {...rest} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default LoadingIndicator;
