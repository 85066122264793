import * as Linking from 'expo-linking';
import { LinkingOptions } from '@react-navigation/native';

let isSubscribed = true;

const configs: LinkingOptions<any> = {
  prefixes: [Linking.createURL('/')],
  subscribe: (listener: any) => {
    const onReceiveURL = ({ url }: { url: string }) => {
      if (isSubscribed) {
        listener(url);
      }
    };
    Linking.addEventListener('url', onReceiveURL);

    return () => {
      isSubscribed = false;
    };
  },
  config: {
    screens: {
      Auth: {
        screens: {
          SignIn: 'sign-in',
          RegisterScreen: 'register',
          PrivacyPolicy: 'privacy-policy',
        },
      },
      Main: {
        screens: {
          MainScreen: 'main',
          TabOne: {
            screens: {
              TabOneScreen: 'one',
            },
          },
          TabTwo: {
            screens: {
              TabTwoScreen: 'two',
            },
          },
        },
      },
      Drawer: {
        screens: {
          MainBottomTab: {
            screens: {
              Home: {
                screens: {
                  HomeScreen: 'home',
                  EmergencyNumbersScreen: 'emergency-number',
                  EventsScreen: 'events',
                  ServicesScreen: 'services',
                  DocumentsScreen: 'documents',
                  BookingsScreen: 'bookings',
                },
              },
              Requests: {
                screens: {
                  ApproveRequests: 'approve-requests',
                },
              },
              Invitations: {
                screens: {
                  InvitationsScreen: 'invites',
                  InviteNew: 'invite-new',
                  InviteShare: 'invite-share',
                },
              },
              Visitors: {
                screens: {
                  VisitorsScreen: 'visitors',
                  VisitorNewUpdate: 'visitor-new-update',
                  VisitorProfile: 'visitor-profile',
                },
              },
            },
          },
          UpdateProfile: 'update-profile',
          DeleteAccount: 'delete-account',
        },
      },
      NotFound: '*',
    },
  },
};

export default configs;
