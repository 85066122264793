import React from 'react';
import { View, StyleSheet, Platform, ScrollView } from 'react-native';
import { Layout } from '@ui-kitten/components';
import { MotiView } from 'moti';
import { useAppTheme, useLayout } from 'hooks';
import { Skeleton } from 'moti/skeleton';

interface NotificationDetailsLoadingProps {}

const NotificationDetailsLoading: React.FC<
  NotificationDetailsLoadingProps
> = ({}) => {
  const { bottom } = useLayout();
  const { theme } = useAppTheme();

  const Spacer = ({ height = 16 }) => <View style={{ height }} />;

  return (
    <Layout
      style={[
        {
          flex: 1,
          marginHorizontal: 16,
          borderRadius: 16,
          marginBottom: Platform.OS === 'ios' ? bottom + 28 : bottom + 58,
        },
      ]}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingHorizontal: 16, paddingTop: 16 }}
      >
        <MotiView transition={{ type: 'timing' }} style={[styles.container]}>
          <View style={styles.row}>
            <Skeleton colorMode={theme} radius="round" height={42} width={42} />
            <View style={{ marginLeft: 8 }}>
              <Skeleton colorMode={theme} height={20} radius={8} width="60%" />
              <Spacer height={4} />
              <Skeleton colorMode={theme} height={14} radius={8} width="80%" />
            </View>
          </View>
          <Spacer height={16} />
          <Skeleton colorMode={theme} height={28} radius={8} width="100%" />
          <Spacer height={12} />
          <Skeleton colorMode={theme} width="100%" height={175} radius={8} />
          <Spacer height={12} />
          <Skeleton colorMode={theme} width="100%" height={300} radius={8} />
          <Spacer height={12} />
        </MotiView>
      </ScrollView>
    </Layout>
  );
};

export default NotificationDetailsLoading;

const styles = StyleSheet.create({
  shape: {
    justifyContent: 'center',
    height: 250,
    width: 250,
    borderRadius: 25,
    marginRight: 10,
    backgroundColor: 'white',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  padded: {
    padding: 16,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
