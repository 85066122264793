export function sortAlphabetical(list: any[]) {
  if (list != null) {
    list.sort((a, b) => {
      const string1 = [a.firstName, a.lastName].filter(Boolean).join(' ').toLowerCase().trim();
      const string2 = [b.firstName, b.lastName].filter(Boolean).join(' ').toLowerCase().trim();
      if (string1 < string2) {
        return -1;
      }
      return string1 > string2 ? 1 : 0;
    });
  }
  return list;
}
