import React from 'react';
import createStackNavigator from './createStackNavigator';

import HomeScreen from 'screens/home/HomeScreen';

import { HomeMainParamList } from './types';
import EmergencyNumbers from 'screens/home/EmergencyNumbers';
import BookingScreen from 'screens/home/BookingScreen';
import DocumentsScreen from 'screens/home/DocumentScreen';
import EventsScreen from 'screens/home/EventsScreen';
import ServicesScreen from 'screens/home/ServicesScreen';
import WebViewScreen from 'screens/web/WebViewScreen';
import NotificationScreen from 'screens/home/NotificationScreen';
import NotificationDetails from 'screens/home/NotificationDetails';

const Stack = createStackNavigator<HomeMainParamList>();

const HomeMainNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName="HomeScreen"
    >
      <Stack.Screen name="HomeScreen" component={HomeScreen} />
      <Stack.Screen
        name="EmergencyNumbersScreen"
        component={EmergencyNumbers}
      />
      <Stack.Screen name="EventsScreen" component={EventsScreen} />
      <Stack.Screen name="ServicesScreen" component={ServicesScreen} />
      <Stack.Screen name="DocumentsScreen" component={DocumentsScreen} />
      <Stack.Screen name="BookingsScreen" component={BookingScreen} />
      <Stack.Screen name="WebViewScreen" component={WebViewScreen} />
      <Stack.Screen name="Notification" component={NotificationScreen} />
      <Stack.Screen
        name="NotificationDetails"
        component={NotificationDetails}
      />
    </Stack.Navigator>
  );
};

export default HomeMainNavigator;
