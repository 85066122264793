import React from 'react';
import {
  Image,
  ImageProps,
  ImageSourcePropType,
  StyleSheet,
} from 'react-native';
import { IconPack, IconProvider } from '@ui-kitten/components';
import { SvgProps } from 'react-native-svg';
import { Icons } from './icons';

const createIcon = (source: ImageSourcePropType): IconProvider<ImageProps> => {
  return {
    toReactElement: (props) => (
      <Image
        style={styles.icon}
        {...props}
        source={source}
        resizeMode="contain"
      />
    ),
  };
};

const styles = StyleSheet.create({
  icon: {
    width: 24,
    height: 24,
  },
});

const AssetIconsPack: IconPack<ImageProps | SvgProps> = {
  name: 'assets',
  icons: {
    back: createIcon(Icons.back),
    'arrow-right': createIcon(Icons['arrow-right']),
    'right-arrow': createIcon(Icons['right-arrow']),
    settingFill: createIcon(Icons.settingFill),
    home: createIcon(Icons.home),
    homeFill: createIcon(Icons.homeFill),
    accountFill: createIcon(Icons.accountFill),
    group: createIcon(Icons.group),
    groupFill: createIcon(Icons.groupFill),
    calendar: createIcon(Icons.calendar),
    calendarFill: createIcon(Icons.calendarFill),
    logout: createIcon(Icons.logout),
    menu: createIcon(Icons.menu),
    add: createIcon(Icons.add),
    eyeOff: createIcon(Icons.eyeOff),
    eyeOn: createIcon(Icons.eyeOn),
    baby: createIcon(Icons.baby),
    babyFill: createIcon(Icons.babyFill),
    edit: createIcon(Icons.edit),
    trash: createIcon(Icons.trash),
    search: createIcon(Icons.search),
    edit_1: createIcon(Icons.edit_1),
    'caret-down': createIcon(Icons['caret-down']),
    call: createIcon(Icons.call),
    share: createIcon(Icons.share),
    cancel: createIcon(Icons.cancel),
    close: createIcon(Icons.close),
    'close-1': createIcon(Icons['close-1']),
    'cancel-invite': createIcon(Icons['cancel-invite']),
    'whats-app': createIcon(Icons['whats-app']),
    email: createIcon(Icons.email),
    copy: createIcon(Icons.copy),
    emergencyNumber: createIcon(Icons.emergencyNumber),
    request: createIcon(Icons.request),
    booking: createIcon(Icons.booking),
    document: createIcon(Icons.document),
    paper: createIcon(Icons.paper),
    download: createIcon(Icons.download),
    time: createIcon(Icons.time),
    person: createIcon(Icons.person),
    'add-child': createIcon(Icons['add-child']),
    people: createIcon(Icons.people),
    wishlist: createIcon(Icons.wishlist),
    'my-post': createIcon(Icons['my-post']),
    activity: createIcon(Icons.activity),
    phone: createIcon(Icons.phone),
    deny: createIcon(Icons.deny),
    approve: createIcon(Icons.approve),
    filter: createIcon(Icons.filter),
    delete: createIcon(Icons.delete),
    'un-correct': createIcon(Icons['un-correct']),
    correct: createIcon(Icons.correct),
    moon: createIcon(Icons.moon),
    sun: createIcon(Icons.sun),
    notification: createIcon(Icons.notification),
  },
};

export default AssetIconsPack;
