import React from 'react';
import {
  postPushTokenUpdate,
  requestAccountDeactivation,
  requestAuthLogin,
  requestAuthRefresh,
  requestUserProfile,
} from 'api/user';
import { reset } from 'navigation/RootNavigation';
import { useAppDispatch } from 'store/store';
import { useTheme } from '@ui-kitten/components';
import AsyncStorage, {
  useAsyncStorage,
} from '@react-native-async-storage/async-storage';
import { getAllBookings, getAllServices } from 'store/slices/requestSlide';
import { isJwtTokenValid } from 'utils/isJwtTokenValid';
import { getAllVisitors } from 'store/slices/visitorSlice';
import { getListPending } from 'store/slices/processSlide';
import { getListDocuments } from 'store/slices/documentSlice';
import { getQrCodeObservable, setQRCode } from 'store/slices/credentialSlice';
import {
  EKeyAsyncStorage,
  EPushTokenType,
  MobileAppOptions,
} from 'constants/types';
import { Toast } from 'react-native-toast-notifications';
// import OneSignal from 'react-native-onesignal';
import { getAllInvites } from './store/slices/inviteSlice';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';
import { JwtAuthConnectionError } from './errors/JwtAuthConnectionError';
import { CacheManager } from 'react-native-expo-image-cache';
import { getAllNotifications } from 'store/slices/notificationSlice';

type AuthResult = {
  token: string;
  options: MobileAppOptions;
};

type Context = {
  isAuthenticated: boolean;
  isInitializing: boolean;
  user: MobileAppOptions;
  login: (name: string, password: string) => Promise<AuthResult | null>;
  updatePassword: (
    passwordOld: string,
    password: string,
    passwordConfirm: string,
  ) => Promise<any | null>;
  deactivateAccount: (countersign: string) => Promise<any | null>;
  logout: () => Promise<void | null>;
  setToken: (token?: string) => void;
  setIsInitializing: (isInitializing: boolean) => void;
};

const initUser: MobileAppOptions = {
  oneSignalToken: '',
  uuid: '',
  username: '',
  name: '',
  surname: '',
  email: '',
  thumbnail: '',
  siteName: '',
  siteBanner: '',
  enableActivityHistory: false,
  enableQuickCode: false,
  enableVisitorShortcuts: false,
  defaultCountryIsoCode: '',
  visitorRegRequiredName: false,
  visitorRegRequiredSurname: false,
  visitorRegRequiredMobileNumber: false,
  visitorRegRequiredEmail: false,
  visitorRegRequiredIdNumber: false,
  visitorRegRequiredCompany: false,
  visitorRegRequiredReason: false,
  defaultDurationOfStaySecs: 0,
  maxDurationOfStaySecs: 0,
  visitReasons: [],
  emergencyNumbers: [],
  services: [],
  bookings: [],
};

export const AuthContext = React.createContext<Context>({
  isAuthenticated: false,
  isInitializing: false,
  user: initUser,
  login: async () => null,
  updatePassword: async () => null,
  deactivateAccount: async () => null,
  logout: async () => null,
  setToken: () => null,
  setIsInitializing: async () => null,
});

interface AuthProviderProps {
  children: React.ReactElement;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [user, setUser] = React.useState<MobileAppOptions>(initUser);
  const [token, setToken] = React.useState<string | undefined>(undefined);
  const [isInitializing, setIsInitializing] = React.useState<boolean>(true);

  const {
    getItem: getToken,
    setItem: saveToken,
    removeItem: removeToken,
  } = useAsyncStorage(EKeyAsyncStorage.jwt);
  const { setItem: saveEmergencyNumbers } = useAsyncStorage(
    EKeyAsyncStorage.emergencyNumbers,
  );
  const { getItem: getQrCode, removeItem: removeQrCode } = useAsyncStorage(
    EKeyAsyncStorage.qrCode,
  );

  const { setItem: saveUser, removeItem: removeUser } = useAsyncStorage(
    EKeyAsyncStorage.user,
  );

  const { setItem: saveEmail } = useAsyncStorage(EKeyAsyncStorage.email);

  const setCustomToken = React.useCallback(async (token?: string) => {
    if (token) {
      await saveToken(token);
      await setToken(token);
    } else {
      await removeToken();
      await setToken(undefined);
    }
  }, []);

  const setCustomUser = React.useCallback(async (_user?: MobileAppOptions) => {
    if (_user) {
      await setUser(_user);
      await saveUser(JSON.stringify(_user));
    } else {
      await removeUser();
      await setUser(initUser);
    }
  }, []);

  const storeAuthResult = async (authResult: AuthResult) => {
    setCustomToken(authResult.token);
    setCustomUser(authResult.options);
    saveEmail(authResult.options.email);
    dispatch(getAllServices());
    dispatch(getAllBookings());
    dispatch(getAllVisitors({ force: true, refresh: true }));
    dispatch(getAllInvites(true));
    dispatch(getListDocuments());
    dispatch(getListPending({ refresh: true, initialization: true }));
    dispatch(getQrCodeObservable({ force: true }));
    dispatch(
      getAllNotifications({
        force: true,
        refresh: true,
        payload: { start: 0, limit: 500 },
      }),
    );

    // Check if we can get a push token
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      Notifications.getDevicePushTokenAsync()
        .then((token) => {
          // Preconditions
          if (token.data == null) {
            return;
          }

          // Determine Device Push Notification Type
          let pushTokenType: EPushTokenType = EPushTokenType.UNKNOWN;
          if (token.type == 'ios') {
            pushTokenType = EPushTokenType.APNS;
          }
          if (token.type == 'android') {
            pushTokenType = EPushTokenType.FCM;
          }

          // Set PushToken to Server
          console.log(
            'HAVE PushToken type: %s token: %s',
            pushTokenType,
            token.data,
          );

          // Store notification
          AsyncStorage.setItem(EKeyAsyncStorage.notificationToken, token.data);
          AsyncStorage.setItem(
            EKeyAsyncStorage.notificationTokenType,
            pushTokenType.toString(),
          );

          postPushTokenUpdate({
            type: pushTokenType,
            token: token.data,
          }).then((result) => {
            console.log('SUCCESS: postPushTokenUpdate');
            console.log(result);
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }

    // Check for server signed HMAC oneSignalToken, if present register device with OneSignal for Push Notifications
    // if (authResult.options.oneSignalToken) {
    //   OneSignal.setExternalUserId(
    //     authResult.options.uuid,
    //     authResult.options.oneSignalToken,
    //     (results: any) => {
    //       console.log(
    //         'Results of setting external user id: ' +
    //           authResult.options.uuid +
    //           ' oneSignalToken:' +
    //           authResult.options.oneSignalToken,
    //       );
    //       console.log(results);
    //
    //       // Push can be expected in almost every situation with a success status, but as a pre-caution its good to verify it exists
    //       if (results.push && results.push.success) {
    //         console.log('Results of setting external user id push status:');
    //         console.log(results.push.success);
    //       }
    //     },
    //   );
    // } else {
    //   console.error(
    //     'NOTICE: authResult.options.oneSignalToken was EMPTY. Incompatible Server Version or not configured OneSignal API!',
    //   );
    // }
    // // Clear System Tray of old notifications
    // OneSignal.clearOneSignalNotifications();

    // Persist Emergency Numbers
    saveEmergencyNumbers(
      authResult.options
        ? JSON.stringify(authResult.options.emergencyNumbers)
        : '',
    ).then();
  };

  const login = React.useCallback(
    async (username: string, password: string) => {
      const authResult = await requestAuthLogin({ username, password });

      if (authResult) {
        console.log('Authenticated: User token -> ' + authResult.token);

        storeAuthResult(authResult);
        return authResult;
      }
    },
    [],
  );

  React.useEffect(() => {
    (async () => {
      // Step 1.1: fetch jwt cache
      const jwt = await getToken();

      // Step 1.2 check if we have a token, if not show login
      if (!jwt) {
        setIsInitializing(false);
        return;
      }

      // Step 1.3 check if jwt time is still valid, otherwise show login
      if (isJwtTokenValid(jwt)) {
        setCustomToken(jwt);
      } else {
        setIsInitializing(false);
        return;
      }

      // Step 2.0: try and refresh JWT
      requestAuthRefresh()
        .then((refreshAuthResult) => {
          console.log('AuthContext DONE requestAuthRefresh');

          // Step 3: Handle error response
          if (!refreshAuthResult || !refreshAuthResult.token) {
            console.log(
              'AuthContext Unable to Refresh JWT access token, flushing storage',
            );
            // Step 3.2.1: check if we have any pending approval requests
            console.log('AuthContext TRYING getListPending - Step 3');
            getListPending({ refresh: true });
            //removeToken();
            return;
          }

          // Step 4: store JWT
          console.log(
            'AuthContext Authenticated: User token -> ' +
              refreshAuthResult.token,
          );
          storeAuthResult(refreshAuthResult);

          // Step 5: refresh QR Code
          getQrCode().then((cacheQRCode) => {
            if (cacheQRCode) {
              const candidate = JSON.parse(cacheQRCode);
              dispatch(setQRCode(candidate));
            }
          });
        })
        .catch((err) => {
          if (err instanceof JwtAuthConnectionError) {
            Toast.show('Offline. Please check your network connection.', {
              type: 'danger',
            });
          } else {
            setCustomToken(undefined);
            setCustomUser(undefined);
          }
        })
        .finally(() => {
          // Step N: update UI
          setTimeout(() => {
            setIsInitializing(false);
          }, 1000);
        });
    })();
  }, []);

  const updatePassword = React.useCallback(
    async (passwordOld: string, password: string, passwordConfirm: string) => {
      try {
        return await requestUserProfile({
          passwordOld,
          password,
          passwordConfirm,
        });
      } catch (error) {
        console.log(error);
      }
    },
    [],
  );

  const deactivateAccount = React.useCallback(async (countersign: string) => {
    try {
      return await requestAccountDeactivation({
        countersign,
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const logout = React.useCallback(async () => {
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      await CacheManager.clearCache();
    }
    setCustomToken(undefined);
    setCustomUser(undefined);
    removeQrCode().then();
    reset('Auth');
  }, []);

  const isAuthenticated = React.useMemo(() => {
    if (!token) {
      return false;
    }
    return isJwtTokenValid(token);
  }, [token]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isInitializing,
        user,
        //@ts-ignore
        login,
        updatePassword,
        deactivateAccount,
        logout,
        setToken: setCustomToken,
        setIsInitializing,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
