import React from 'react';
import { TextStyle } from 'react-native';
import { Text, TextProps } from '@ui-kitten/components';

export interface MyTextProps extends TextProps {
  style?: TextStyle;
  category?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h5-l'
    | 'h6'
    | 'h7'
    | 'h7-b'
    | 't1'
    | 't1-m'
    | 't2'
    | 't3'
    | 'b1'
    | 'b2'
    | 'b3'
    | 'b4'
    | 'b4-b'
    | 'b5'
    | 'b5-b'
    | 'b6'
    | 'b7'
    | 'b8'
    | 'c1'
    | 'c2'
    | 'c2-t'
    | 'c3-l'
    | 'c3'
    | 'c3-m'
    | 'c3-b'
    | 'c4'
    | 'c5'
    | 'c5-m';
  status?:
    | 'basic'
    | 'primary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'control'
    | 'description' // Neutral/08
    | 'white' // Neutral/01
    | 'sub' // Neutral/05
    | 'body' // Neutral/07
    | 'title' // Primary
    | 'placeholder' // Neutral/06
    | 'content' // Neutral/09
    | 'mode'
    | 'green'
    | 'blue'
    | 'black';
  children?: any;
  marginLeft?: number;
  marginRight?: number;
  marginTop?: number;
  marginBottom?: number;
  marginVertical?: number;
  marginHorizontal?: number;
  opacity?: number;
  maxWidth?: number;
  fontSize?: number;
  lineHeight?: number;
  uppercase?: boolean;
  lowercase?: boolean;
  capitalize?: boolean;
  none?: boolean;
  left?: boolean;
  right?: boolean;
  center?: boolean;
  underline?: boolean;
  line_through?: boolean;
  bold?: boolean;
  italic?: boolean;
  letterSpacing?: number;
}
const getLineHeight = (
  category:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h5-l'
    | 'h6'
    | 'h7'
    | 'h7-b'
    | 't1'
    | 't1-m'
    | 't2'
    | 't3'
    | 'b1'
    | 'b2'
    | 'b3'
    | 'b4'
    | 'b4-b'
    | 'b5'
    | 'b5-b'
    | 'b6'
    | 'b7'
    | 'b8'
    | 'c1'
    | 'c2'
    | 'c2-t'
    | 'c3-l'
    | 'c3'
    | 'c3-m'
    | 'c3-b'
    | 'c4'
    | 'c5'
    | 'c5-m',
): number => {
  switch (category) {
    case 'h1':
    case 'h2':
      return 48;
    case 'h3':
    case 'h4':
      return 40;
    case 'h6':
    case 'h5':
    case 'h5-l':
      return 32;
    case 'h7':
    case 'h7-b':
    case 't1':
    case 't1-m':
      return 28;
    case 't2':
    case 'b1':
    case 'b2':
    case 'b4':
    case 'b4-b':
      return 24;
    case 'b3':
    case 'b5':
    case 'b5-b':
    case 'b6':
    case 'b7':
    case 'b8':
    case 't3':
      return 20;
    case 'c1':
    case 'c2':
    case 'c2-t':
      return 16;
    case 'c3-l':
    case 'c3-m':
    case 'c3-b':
    case 'c4':
      return 14;
    case 'c3':
    case 'c5':
    case 'c5-m':
      return 12;
    default:
      return 24;
  }
};
export default React.forwardRef(
  (
    {
      marginLeft,
      marginRight,
      marginTop,
      marginBottom,
      marginVertical,
      marginHorizontal,
      opacity,
      uppercase,
      lowercase,
      capitalize,
      none,
      left,
      right,
      center,
      underline,
      line_through,
      bold,
      italic,
      category = 'b1',
      status = 'basic',
      children,
      maxWidth,
      letterSpacing,
      style,
      ...rest
    }: MyTextProps,
    ref,
  ) => {
    let textAlign: 'left' | 'center' | 'right' | 'auto' | 'justify' | 'left';

    left
      ? (textAlign = 'left')
      : right
      ? (textAlign = 'right')
      : center
      ? (textAlign = 'center')
      : (textAlign = 'left');

    let textTransform: 'uppercase' | 'lowercase' | 'capitalize' | 'none';

    uppercase
      ? (textTransform = 'uppercase')
      : lowercase
      ? (textTransform = 'lowercase')
      : capitalize
      ? (textTransform = 'capitalize')
      : none
      ? (textTransform = 'none')
      : (textTransform = 'none');

    let fontWeight:
      | 'bold'
      | 'normal'
      | '100'
      | '200'
      | '300'
      | '400'
      | '500'
      | '600'
      | '700'
      | '800'
      | '900';
    bold ? (fontWeight = 'bold') : (fontWeight = 'normal');

    let textDecorationLine:
      | 'none'
      | 'underline'
      | 'line-through'
      | 'underline line-through';
    underline
      ? (textDecorationLine = 'underline')
      : line_through
      ? (textDecorationLine = 'line-through')
      : (textDecorationLine = 'none');

    let fontStyle: 'normal' | 'italic';
    italic ? (fontStyle = 'italic') : (fontStyle = 'normal');

    return (
      <Text
        ref={ref as any}
        category={category}
        status={status}
        style={[
          {
            marginLeft: marginLeft,
            marginRight: marginRight,
            marginTop: marginTop,
            marginBottom: marginBottom,
            marginVertical: marginVertical,
            marginHorizontal: marginHorizontal,
            opacity: opacity,
            textAlign: textAlign,
            maxWidth: maxWidth,
            lineHeight: getLineHeight(category),
            textTransform: textTransform,
            textDecorationLine: textDecorationLine,
            fontWeight: fontWeight,
            fontStyle: fontStyle,
            letterSpacing: letterSpacing,
          },
          style,
        ]}
        {...rest}
      >
        {children}
      </Text>
    );
  },
);
