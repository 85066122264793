import React from 'react';
import { View, Image, StyleSheet, ScrollView, Platform } from 'react-native';
import {
  Container,
  NavigationAction,
  NotificationDetailsLoading,
  Text,
  UserItem,
} from 'components';
import { useTheme, Layout, TopNavigation } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import { useDrawer, useLayout } from 'hooks';

import dayjs from 'dayjs';
import { NotificationMessagePriority } from 'constants/types';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
  notificationSelector,
  setNotificationLoading,
} from 'store/slices/notificationSlice';
import WebView, { WebViewMessageEvent } from 'react-native-webview';

type WebViewType = {
  height: number;
  isLoaded: boolean;
};

const NotificationDetails = React.memo(() => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { isLoading, notification } = useAppSelector(notificationSelector);
  const { openDrawer } = useDrawer();
  const { t } = useTranslation(['common']);
  const { bottom } = useLayout();

  const [webview, setWebView] = React.useState<WebViewType>({
    height: 0,
    isLoaded: false,
  });

  React.useEffect(() => {
    setTimeout(() => {
      dispatch(setNotificationLoading(false));
    }, 2000);
  }, []);

  return (
    <Container level="2">
      <TopNavigation
        style={styles.topNavigation}
        appearance="control"
        accessoryLeft={
          <View style={styles.header}>
            <NavigationAction icon="menu" status="basic" onPress={openDrawer} />
            <UserItem />
          </View>
        }
      />
      <View style={styles.row}>
        <NavigationAction />
        <Text category="b4-b">{t('common:notifications')}</Text>
      </View>
      {isLoading || !notification ? (
        <NotificationDetailsLoading />
      ) : (
        <Layout
          style={[
            {
              flex: 1,
              marginHorizontal: 16,
              borderRadius: 16,
              marginBottom: Platform.OS === 'ios' ? bottom + 28 : bottom + 58,
            },
          ]}
        >
          <ScrollView
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{ paddingHorizontal: 16, paddingTop: 16 }}
          >
            <View style={styles.box1}>
              <View
                style={[
                  styles.box,
                  {
                    backgroundColor:
                      notification.priority === NotificationMessagePriority.HIGH
                        ? theme['color-red-1-500']
                        : theme['color-violet-500'],
                  },
                ]}
              >
                <Text category="h3" status="white">
                  {notification.senderName.split('')[0]}
                </Text>
              </View>
              <View>
                <Text category="b5-b">{notification.senderName}</Text>
                <Text category="c3" marginTop={4}>
                  {dayjs(notification.dateSent).format('YYYY/MM/DD h:mm')}
                </Text>
              </View>
            </View>
            <Text marginTop={16} category="h7-b" letterSpacing={0.2}>
              {notification.subject}
            </Text>
            {notification.photo && (
              <Image
                source={{ uri: notification.photo }}
                style={styles.image}
              />
            )}
            <WebView
              style={{
                flex: 1,
                marginBottom: Platform.OS === 'android' ? 24 : 16,
                height: webview.isLoaded ? webview.height : 0,
                backgroundColor: 'transparent',
              }}
              customStyle={`body { font-family: 'Roboto', sans-serif; font-weight: 300; color: ${theme['text-basic-color']};} p { font-size: 14px; }`}
              files={[
                {
                  href: 'cssfileaddress',
                  type: 'text/css',
                  rel: 'stylesheet',
                },
              ]}
              originWhitelist={['*']}
              javaScriptEnabled={true}
              domStorageEnabled={true}
              scrollEnabled={false}
              source={{
                html: `<!DOCTYPE html><html><head><meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0"><link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap"></head><body style="color: ${theme['text-basic-color']};" >${notification.body}
                <script>
                window.addEventListener('load', function () {
                  const contentHeight = document.body.scrollHeight;
                  console.log('Sending content height:', contentHeight);
                  window.ReactNativeWebView.postMessage(contentHeight.toString());
                });
              </script>
                </body></html>`,
              }}
              scalesPageToFit={true}
              onMessage={(event: WebViewMessageEvent) => {
                if (event.nativeEvent.data) {
                  const webViewHeight = parseInt(event.nativeEvent.data, 10);
                  setWebView({ height: webViewHeight, isLoaded: true });
                }
              }}
              viewportContent={'width=device-width, user-scalable=no'}
            />
          </ScrollView>
        </Layout>
      )}
    </Container>
  );
});

export default NotificationDetails;

const styles = StyleSheet.create({
  topNavigation: {
    marginTop: 12,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 16,
  },
  box: {
    width: 42,
    height: 42,
    borderRadius: 42,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8,
  },
  box1: {
    flexDirection: 'row',
  },
  image: {
    marginTop: 12,
    height: 175,
    width: '100%',
    borderRadius: 8,
  },
});
