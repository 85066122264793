import React from 'react';
import { View, StyleSheet, Image, FlatList } from 'react-native';
import {
  ApproveRequest,
  Container,
  NavigationAction,
  Text,
  UserItem,
} from 'components';
import { TopNavigation } from '@ui-kitten/components';
import {
  useNavigation,
  useFocusEffect,
  DrawerActions,
} from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useLayout } from 'hooks';

import { Images } from 'assets/images';
import { IApproveRequest } from 'constants/types';
import {
  approveVisitor,
  denyVisitor,
  getListPending,
  processSelector,
  removeApprovalResult,
} from 'store/slices/processSlide';
import { setShowModalRequest } from 'store/slices/appSlice';

const ApproveRequestsScreen = React.memo(() => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const { t } = useTranslation(['common', 'request']);
  const { bottom, height } = useLayout();

  const [loading, setLoading] = React.useState<boolean>(false);

  const { listPending } = useAppSelector(processSelector);

  useFocusEffect(() => {
    dispatch(setShowModalRequest(false));
  });

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(removeApprovalResult());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      dispatch(getListPending({ refresh: true }));
    });

    return unsubscribe;
  }, [navigation]);

  const handleDeny = React.useCallback(
    async (userUuid: string, transactionUuid: string) => {
      dispatch(denyVisitor({ userUuid, transactionUuid }));
    },
    [],
  );

  const handleApprove = React.useCallback(
    (userUuid: string, transactionUuid: string) => {
      dispatch(approveVisitor({ userUuid, transactionUuid }));
    },
    [],
  );

  const renderItem = React.useCallback(
    ({ item }: { item: IApproveRequest }) => {
      return (
        <View style={styles.item}>
          <ApproveRequest
            item={item}
            onDeny={() => handleDeny(item.userUuid, item.uuid)}
            onApprove={() => handleApprove(item.userUuid, item.uuid)}
          />
        </View>
      );
    },
    [],
  );

  const handleRefetch = React.useCallback(async () => {
    setLoading(true);
    dispatch(getListPending({ refresh: true }));
    setLoading(false);
  }, []);

  const listEmptyComponent = React.useCallback(() => {
    return (
      <View style={[styles.empty, { marginTop: height / 5 }]}>
        <Image
          source={Images.image_no_visitors}
          style={{ width: 187, height: 145 }}
        />
        <Text category="b2" marginTop={24}>
          {t('request:no_pending_requests')}
        </Text>
        <Text
          marginTop={4}
          marginBottom={16}
          category="c2"
          marginHorizontal={24}
          center
        >
          {t('request:description_empty_requests')}
        </Text>
      </View>
    );
  }, []);

  return (
    <Container level="2">
      <TopNavigation
        style={styles.topNavigation}
        appearance="control"
        //title={t('request:approve_requests')}
        accessoryLeft={
          <View style={styles.header}>
            <NavigationAction
              icon="menu"
              status="basic"
              onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
            />
            <UserItem />
          </View>
        }
      />
      <FlatList
        data={listPending || []}
        renderItem={renderItem}
        contentContainerStyle={{ paddingBottom: bottom + 58, paddingTop: 16 }}
        refreshing={loading}
        onRefresh={handleRefetch}
        showsVerticalScrollIndicator={false}
        indicatorStyle="black"
        ListEmptyComponent={listEmptyComponent}
      />
    </Container>
  );
});

export default ApproveRequestsScreen;

const styles = StyleSheet.create({
  item: {
    marginBottom: 16,
    paddingHorizontal: 24,
  },
  empty: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  topNavigation: {
    marginTop: 12,
  },
});
