import 'react-native-reanimated';
import React from 'react';
import 'i18n/config';
import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import {
  ApplicationProvider,
  IconRegistry,
  useTheme,
} from '@ui-kitten/components';
import { default as darkTheme } from 'constants/theme/dark.json';
import { default as lightTheme } from 'constants/theme/light.json';
import { default as customMapping } from 'constants/theme/mapping.json';
import AssetIconsPack from 'assets/AssetIconsPack';
import * as eva from '@eva-design/eva';
import ThemeContext from './ThemeContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AppContainer from 'navigation/AppContainer';
import { useBoolean, useCachedResources } from 'hooks';
import { patchFlatListProps } from 'react-native-web-refresh-control';
import { AuthProvider } from './AuthContext';
import { NotificationsProvider } from './NotificationsContext';
import * as Notifications from 'expo-notifications';
import { Provider, useDispatch } from 'react-redux';
import store from 'store/store';
import Configs from 'configs';
import { ToastProvider, Toast } from 'react-native-toast-notifications';
import { ModalApprovalRequest, ModalLoading } from 'components';
import _ from 'lodash';

// import OneSignal from 'react-native-onesignal';
// import OSNotification from 'react-native-onesignal/src/OSNotification';
// import { IPushNotificationPayload } from './constants/types';
import { RootSiblingParent } from 'react-native-root-siblings';
//import * as Sentry from 'sentry-expo';
import { enableScreens } from 'react-native-screens';
import { Platform, Appearance } from 'react-native';
import { getListPending } from 'store/slices/processSlide';

enableScreens();
patchFlatListProps();

// As per Expo docs, set Notifications in global scope
Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: false,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

function AppMain() {
  const isLoadingComplete = useCachedResources();
  const notificationListener = React.useRef<any>();
  const responseListenerRef = React.useRef<any>();
  const [visible, { on: showModalRequest, off: hideModalRequest }] =
    useBoolean();
  const dispatch = useDispatch();

  // Get last notifications
  const lastNotificationResponse = Notifications.useLastNotificationResponse();

  // FOR DEBUG
  // When last notifications have been changed
  React.useEffect(() => {
    if (
      lastNotificationResponse
      // &&
      // lastNotificationResponse.notification.request.content.data.url &&
      // lastNotificationResponse.actionIdentifier === Notifications.DEFAULT_ACTION_IDENTIFIER
    ) {
      console.log('IN hook Notifications.useLastNotificationResponse()');
      console.log(lastNotificationResponse);
    }
  }, [lastNotificationResponse]);

  // Clear all notifications
  const clearAllNotifications = React.useCallback(async () => {
    if (Platform.OS === 'web') {
      return;
    }
    await Notifications.cancelAllScheduledNotificationsAsync();
    await Notifications.dismissAllNotificationsAsync();
  }, []);

  // As per Expo docs, set Notifications in global scope
  React.useEffect(() => {
    responseListenerRef.current =
      Notifications.addNotificationResponseReceivedListener((response) => {
        // This listener is fired whenever a user taps on or interacts with a notification
        // (works when app is foregrounded, backgrounded, or killed)
        console.log(
          'Notifications.addNotificationResponseReceivedListener \
          (works when app is foregrounded, backgrounded, or killed)',
          { response },
        );

        // Handle Apple APNS payload
        if (
          response.notification.request.trigger != null &&
          response.notification.request.trigger.payload != null
        ) {
          handlePushNotification(
            response.notification.request.trigger.payload,
            false,
          );
        }

        // Handle FCM Notifications
        if (
          response.notification != null &&
          response.notification.request.trigger != null &&
          response.notification.request.trigger?.remoteMessage != null &&
          response.notification.request.trigger?.remoteMessage.data != null
        ) {
          handlePushNotification(
            response.notification.request.trigger?.remoteMessage.data,
            false,
          );
        }
      });

    notificationListener.current =
      Notifications.addNotificationReceivedListener((notification) => {
        // This listener is fired whenever a notification is received while the app is foregrounded
        console.log(
          'Notifications.addNotificationReceivedListener \
          (app is foregrounded)',
          { notification },
        );

        // Handle Debug schedulePushNotification payload
        if (
              notification.request.content != null &&
              notification.request.content.data != null
          ) {
              handlePushNotification(notification.request.content.data, true);
          }

        // Handle Apple APNS payload
        if (
          notification.request.trigger != null &&
          notification.request.trigger.payload != null
        ) {
          handlePushNotification(notification.request.trigger.payload, true);
        }

        // Handle FCM/Android payload
        if (
          notification.request != null &&
          notification.request.trigger != null &&
          notification.request.trigger?.remoteMessage != null &&
          notification.request.trigger?.remoteMessage.data != null
        ) {
          handlePushNotification(
            notification.request.trigger?.remoteMessage.data,
            true,
          );
        }
      });
    return () => {
      notificationListener.current &&
        Notifications.removeNotificationSubscription(
          notificationListener.current,
        );
      responseListenerRef.current &&
        Notifications.removeNotificationSubscription(
          responseListenerRef.current,
        );
    };
  }, []);

  const handlePushNotification = (payload: any, foreground: boolean) => {
    // handle visitor-update message
    if (payload != null && payload.action == 'visitor-update') {
      console.log('HAVE Push Notification action: visitor-update', {
        foreground,
        payload,
      });

      // TODO
      Toast.show('Activity Update:\n\r' + payload.body, {
        type: 'danger',
      });

      clearAllNotifications();
    }

    // handle approve request messages
    if (payload != null && payload.action == 'approve') {
      console.log('HAVE Push Notification action: approve', {
        foreground,
        payload,
      });

            // Step 1: get an updated list of pending approval requests from server
            dispatch(getListPending({refresh: true}));
            // Step 2: show visitor approval modal
            showModalRequest();
            // Step 3: flush notification from the system tray
            clearAllNotifications();

        }
    };

  return (
    <SafeAreaProvider>
      <AuthProvider>
        <NotificationsProvider>
          <AppContainer cachedResources={isLoadingComplete} />
        </NotificationsProvider>
      </AuthProvider>
      <ModalLoading />
      <ModalApprovalRequest visible={visible} onClose={hideModalRequest} />
    </SafeAreaProvider>
  );
}

export default function App() {
  const themes = useTheme();
  const [theme, setTheme] = React.useState<'light' | 'dark'>('light');

  const setAppTheme = React.useCallback(
    _.debounce((colorScheme: ColorSchemeName) => {
      console.log('IN setAppTheme debounce: ' + colorScheme);
      AsyncStorage.setItem('theme', colorScheme).then(() => {
        setTheme(colorScheme);
      });
    }, 1000),
  );

  React.useEffect(() => {
    const subscription = Appearance.addChangeListener(
      async ({ colorScheme }) => {
        console.log('IN Appearance.addChangeListener: ' + colorScheme);
        if (colorScheme) {
          await setAppTheme(colorScheme);
        }
      },
    );
    return () => subscription.remove();
  }, []);

  React.useEffect(() => {
    (async () => {
      const _theme = await Appearance.getColorScheme();
      if (_theme) {
        setTheme(_theme);
      }
    })();
  }, []);

  // Sentry.init({
  //   dsn: 'https://edf8c760d1ae49a1820d5b1344b6e39d@o519095.ingest.sentry.io/6726015',
  //   enableInExpoDevelopment: false,
  //   debug: true, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
  //   tracesSampleRate: 1.0,
  // });

  // React.useEffect(() => {
  //   // OneSignal Initialization
  //   OneSignal.setAppId(Configs.ONESIGNAL_APP_ID);
  //
  //   // promptForPushNotificationsWithUserResponse will show the native iOS or Android notification permission prompt.
  //   // We recommend removing the following code and instead using an In-App Message to prompt for notification permission (See step 8)
  //   OneSignal.promptForPushNotificationsWithUserResponse();
  //
  //   //Method for handling notifications received while app in foreground
  //   OneSignal.setNotificationWillShowInForegroundHandler(
  //     (notificationReceivedEvent) => {
  //       console.log(
  //         'OneSignal: notification will show in foreground:',
  //         notificationReceivedEvent,
  //       );
  //       const notification: OSNotification =
  //         notificationReceivedEvent.getNotification();
  //       console.log('notification: ', notification);
  //       const data = notification.additionalData;
  //       console.log('additionalData: ', data);
  //
  //       // handle payload
  //       handleNotification(notification);
  //
  //       // Complete with null means don't show a notification.
  //       notificationReceivedEvent.complete(notification);
  //     },
  //   );
  //
  //   //Method for handling notifications opened
  //   OneSignal.setNotificationOpenedHandler((openEvent) => {
  //     console.log('OneSignal: openEvent opened:', openEvent);
  //     handleNotification(openEvent.notification);
  //   });
  //
  //   function handleNotification(notification: OSNotification) {
  //     console.log('IN handleNotification');
  //     const payload = notification.additionalData as IPushNotificationPayload;
  //     if (payload.action == 'approve') {
  //       console.log('IN handleNotification HAVE approve');
  //       // TODO add payloads
  //       navigate('Requests');
  //     }
  //   }
  // });

  const toggleTheme = async () => {
    const nextTheme = theme === 'light' ? 'dark' : 'light';
    await AsyncStorage.setItem('theme', nextTheme).then(() => {
      setTheme(nextTheme);
    });
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <IconRegistry defaultIcons="lock" icons={[AssetIconsPack]} />
      <StatusBar
        style={theme === 'light' ? 'dark' : 'light'}
        translucent={true}
        backgroundColor={'#00000000'}
      />
      <ApplicationProvider
        {...eva}
        theme={
          theme === 'light'
            ? { ...eva.light, ...Configs.APP_THEME, ...lightTheme }
            : { ...eva.dark, ...Configs.APP_THEME, ...darkTheme }
        }
        customMapping={{ ...eva.mapping, ...customMapping }}
      >
        <RootSiblingParent>
          <ToastProvider
            offset={40}
            placement="top"
            animationType="slide-in"
            duration={2000}
            successColor={themes['color-primary-500']}
            dangerColor={themes['color-danger-500']}
            type="error"
          >
            <Provider store={store}>
              <AppMain />
            </Provider>
          </ToastProvider>
        </RootSiblingParent>
      </ApplicationProvider>
    </ThemeContext.Provider>
  );
}
