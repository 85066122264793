import React from 'react';
import { StyleSheet, FlatList, Platform, Image, View } from 'react-native';
import { Button, TopNavigation } from '@ui-kitten/components';
import {
  Container,
  HStack,
  NavigationAction,
  Text,
  UserItem,
  VStack,
} from 'components';
import { useNavigation, NavigationProp } from '@react-navigation/native';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useTranslation } from 'react-i18next';
import { useDrawer, useLayout } from 'hooks';

import * as Linking from 'expo-linking';
import * as ImageCache from 'react-native-expo-image-cache';

import { IService } from 'constants/types';
import { HomeMainParamList } from 'navigation/types';
import { getAllServices, requestSelector } from 'store/slices/requestSlide';

const ServicesScreen = React.memo(() => {
  const { width } = useLayout();
  const dispatch = useAppDispatch();
  const { openDrawer } = useDrawer();
  const { t } = useTranslation(['common', 'request']);
  const { services } = useAppSelector(requestSelector);
  const { navigate } = useNavigation<NavigationProp<HomeMainParamList>>();

  const [isRefresh, setIsRefresh] = React.useState<boolean>(false);

  const handleRefresh = () => {
    setIsRefresh(true);
    dispatch(getAllServices());
    setIsRefresh(false);
  };

  const handleVisit = (item: IService) => () => {
    if (item.externalWindow || Platform.OS === 'web') {
      Linking.openURL(item.url);
    } else {
      navigate('WebViewScreen', { url: item.url });
    }
  };

  const renderItem = React.useCallback(({ item }: { item: IService }) => {
    const { banner, name, description } = item;

    return (
      <VStack
        onPress={handleVisit(item)}
        mh={24}
        level="1"
        mb={24}
        style={styles.shadow}
        border={16}
        padding={12}
      >
        <HStack justify="flex-start" alignItems="flex-start">
          <VStack>
            {Platform.OS === 'web' ? (
              <Image source={{ uri: banner }} style={styles.banner} />
            ) : (
              <ImageCache.Image uri={banner} style={styles.banner} />
            )}
            <Button
              children={t('common:visit')}
              size="small"
              style={styles.button}
              onPress={handleVisit(item)}
            />
          </VStack>
          <VStack>
            <Text category="t3" marginBottom={8}>
              {name}
            </Text>
            <Text category="c3" maxWidth={212 * (width / 375)}>
              {description}
            </Text>
          </VStack>
        </HStack>
      </VStack>
    );
  }, []);

  return (
    <Container level="2">
      <TopNavigation
        style={styles.topNavigation}
        appearance="control"
        title={t('request:discover_services')}
        accessoryLeft={
          <View style={styles.header}>
            <NavigationAction icon="menu" status="basic" onPress={openDrawer} />
            <UserItem isShow={false} />
          </View>
        }
      />
      <FlatList
        data={services}
        refreshing={isRefresh}
        renderItem={renderItem}
        onRefresh={handleRefresh}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.contentContainerStyle}
      />
    </Container>
  );
});

export default ServicesScreen;

const styles = StyleSheet.create({
  contentContainerStyle: {
    paddingBottom: 40,
    paddingTop: 24,
  },
  banner: {
    width: 80,
    height: 80,
    borderRadius: 5,
    marginRight: 12,
  },
  button: {
    width: 80,
    marginTop: 12,
  },
  shadow: {
    shadowColor: 'rgba(44, 44, 44, 24)',
    shadowOffset: {
      width: 2,
      height: 4,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 5,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  topNavigation: {
    marginTop: 12,
  },
});
