import React from 'react';
import { StyleSheet, TouchableOpacity, ViewStyle } from 'react-native';
import { useTheme, Layout, Icon } from '@ui-kitten/components';
import Animated, {
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
  interpolate,
} from 'react-native-reanimated';
import { useTranslation } from 'react-i18next';
import { useAppTheme, useLayout } from 'hooks';

import Text from './Text';

interface SwitchModeProps {
  style?: ViewStyle;
}

const SwitchMode: React.FC<SwitchModeProps> = ({ style }) => {
  const themes = useTheme();
  const { width } = useLayout();
  const { t } = useTranslation('common');
  const { theme, toggleTheme } = useAppTheme();

  const progress = useDerivedValue(() => {
    return theme === 'dark' ? withTiming(1) : withTiming(0);
  }, [theme]);

  const animatedStyles = useAnimatedStyle(() => {
    const transX = interpolate(
      progress.value,
      [0, 1],
      [4, (width - 28 * 2) / 2 + 4],
    );

    return {
      width: '50%',
      transform: [{ translateX: transX }],
      backgroundColor: themes['background-basic-color-1'],
    };
  });

  return (
    <Layout
      level="2"
      style={[styles.container, { width: width - 24 * 2 }, style]}
    >
      <Animated.View style={[styles.boxAni, animatedStyles]} />
      <TouchableOpacity
        activeOpacity={0.7}
        style={styles.box}
        onPress={() => {
          if (theme === 'dark') {
            toggleTheme();
          }
        }}
      >
        <Icon
          pack="assets"
          name="sun"
          style={{
            tintColor:
              theme === 'dark'
                ? themes['background-basic-color-6']
                : themes['background-basic-color-10'],
          }}
        />
        <Text
          capitalize
          marginLeft={8}
          category="b4"
          status={theme === 'dark' ? 'mode' : 'basic'}
        >
          {t('light')}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        activeOpacity={0.7}
        style={styles.box}
        onPress={() => {
          if (theme === 'light') {
            toggleTheme();
          }
        }}
      >
        <Icon
          pack="assets"
          name="moon"
          style={{
            tintColor:
              theme === 'dark'
                ? themes['background-basic-color-10']
                : themes['background-basic-color-6'],
          }}
        />
        <Text
          capitalize
          marginLeft={8}
          category="b4"
          status={theme === 'dark' ? 'basic' : 'mode'}
        >
          {t('dark')}
        </Text>
      </TouchableOpacity>
    </Layout>
  );
};

export default SwitchMode;

const styles = StyleSheet.create({
  container: {
    height: 40,
    padding: 4,
    borderRadius: 20,
    flexDirection: 'row',
    overflow: 'hidden',
  },
  boxAni: {
    height: 32,
    alignSelf: 'center',
    position: 'absolute',
    borderRadius: 20,
  },
  box: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
