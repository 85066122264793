import { getAllInvites } from './inviteSlice';
import { sortAlphabetical } from 'utils/sortAlphabetical';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { requestDeleteVisitorById, requestListAllVisitors } from 'api/visitor';
import { EKeyAsyncStorage, IVisitor } from 'constants/types';
import { AppThunk } from '../store';

import { RootState } from '../rootReducer';
import dayjs from 'dayjs';
import Configs from 'configs';
import { setAppLoading } from './appSlice';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as ImagePicker from 'expo-image-picker';

export interface IVisitorState {
  isDelete: boolean;
  isLoadingVisitor: boolean;
  visitors: IVisitor[];
  updatedAt: number;
  avatar: ImagePicker.ImagePickerAsset | undefined;
}

export const initialState: IVisitorState = {
  isDelete: false,
  isLoadingVisitor: true,
  visitors: [],
  updatedAt: 0,
  avatar: undefined,
};

export const visitorSlice = createSlice({
  name: 'visitor',
  initialState,
  reducers: {
    setVisitors: (
      state: IVisitorState,
      { payload }: PayloadAction<IVisitor[]>,
    ) => {
      state.visitors = payload;
      state.updatedAt = dayjs().unix();
    },
    clearVisitors: (state: IVisitorState) => {
      state.updatedAt = 0;
      state.visitors = [];
    },
    setAvatar: (
      state: IVisitorState,
      { payload }: PayloadAction<ImagePicker.ImagePickerAsset | undefined>,
    ) => {
      state.avatar = payload;
    },
    setIsLoadingVisitor: (
      state: IVisitorState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isLoadingVisitor = payload;
    },
    setIsDelete: (
      state: IVisitorState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isDelete = payload;
    },
  },
});

export const {
  setVisitors,
  clearVisitors,
  setAvatar,
  setIsLoadingVisitor,
  setIsDelete,
} = visitorSlice.actions;

export const getAllVisitors =
  ({
    force = false,
    refresh,
  }: {
    force?: boolean;
    refresh?: boolean;
  }): AppThunk =>
  async (dispatch, getState) => {
    if (!force) {
      const { updatedAt } = visitorSelector(getState());
      if (dayjs().unix() < updatedAt + Configs.CACHE_TTL_SECS) {
        return;
      }
    }
    if (!refresh) {
      dispatch(setAppLoading(true));
    }

    const result = await requestListAllVisitors().catch(() => {
      dispatch(setAppLoading(false));
      setTimeout(() => {
        dispatch(setIsLoadingVisitor(false));
      }, 3000);
    });

    if (result) {
      const _visitors = sortAlphabetical([...result]);

      await AsyncStorage.setItem(
        EKeyAsyncStorage.visitors,
        JSON.stringify(_visitors),
      );

      dispatch(setVisitors(_visitors));
      setTimeout(() => {
        dispatch(setIsLoadingVisitor(false));
      }, 3000);
      if (!refresh) {
        dispatch(setAppLoading(false));
      }
    }
  };

export const deleteVisitorById =
  ({ id }: { id: number }): AppThunk =>
  async (dispatch) => {
    const result = await requestDeleteVisitorById({ id });
    if (!!result && result.code === '200') {
      dispatch(setIsDelete(true));
      dispatch(getAllVisitors({ force: true }));
      dispatch(getAllInvites(true));
      setTimeout(() => {
        dispatch(setAppLoading(false));
      }, 1000);
    }
  };

export const visitorSelector = (state: RootState) => state.visitor;

export default visitorSlice.reducer;
