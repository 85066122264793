import React from 'react';
import { FlatList, StyleSheet, TouchableOpacity, View } from 'react-native';
import {
  Modal,
  Layout,
  TopNavigation,
  Icon,
  useTheme,
} from '@ui-kitten/components';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import { useTranslation } from 'react-i18next';
import { useLayout } from 'hooks';

import Text from './Text';
import UserItem from './UserItem';

import {
  EKeyAsyncStorage,
  IVisitReason,
  MobileAppOptions,
} from 'constants/types';

interface ModalReasonProps {
  visible: boolean;
  onBackdropPress(): void;
  onPress?: (item: IVisitReason) => void;
}

function ModalReason({ onPress, visible, onBackdropPress }: ModalReasonProps) {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'home']);
  const { top, width, height, bottom } = useLayout();
  const { getItem: getUser } = useAsyncStorage(EKeyAsyncStorage.user);

  const [user, setUser] = React.useState<MobileAppOptions>();

  React.useEffect(() => {
    (async () => {
      const _user = await getUser();

      if (_user) {
        setUser(JSON.parse(_user));
      }
    })();
  }, []);

  return (
    <Modal
      visible={visible}
      onBackdropPress={onBackdropPress}
      style={styles.container}
      backdropStyle={styles.backdropStyle}
    >
      <Layout
        level="2"
        style={[
          styles.modal,
          { paddingTop: top, width: width, height: height },
        ]}
      >
        <TopNavigation
          style={styles.topNavigation}
          appearance="control"
          accessoryLeft={
            <View style={styles.header}>
              <TouchableOpacity activeOpacity={0.7} onPress={onBackdropPress}>
                <Icon
                  pack="assets"
                  name="delete"
                  style={[
                    styles.icon,
                    { tintColor: theme['icon-basic-color'] },
                  ]}
                />
              </TouchableOpacity>
              <UserItem />
            </View>
          }
        />
        <Text category="b2" marginHorizontal={24} marginTop={16}>
          {t('home:quick_code')}
        </Text>
        <Text
          category="c3"
          marginTop={2}
          status="placeholder"
          marginHorizontal={24}
        >
          {t('home:quick_code_description')}
        </Text>
        <Layout style={[styles.modalReason, { marginBottom: bottom + 24 }]}>
          {user && (
            <FlatList
              data={user.visitReasons || []}
              keyExtractor={(i, index) => `${i.id} ${index}`}
              showsVerticalScrollIndicator={false}
              scrollEventThrottle={16}
              renderItem={({ item }) => {
                return (
                  <TouchableOpacity
                    style={[
                      styles.item,
                      { borderColor: theme['background-basic-color-12'] },
                    ]}
                    activeOpacity={0.7}
                    onPress={() => {
                      onPress && onPress(item);
                      onBackdropPress();
                    }}
                  >
                    <View style={styles.box}>
                      <Text category="t3">{item.reason}</Text>
                    </View>
                    <Icon
                      pack="assets"
                      name="right-arrow"
                      style={[
                        styles.icon24,
                        { tintColor: theme['icon-basic-color'] },
                      ]}
                    />
                  </TouchableOpacity>
                );
              }}
            />
          )}
        </Layout>
      </Layout>
    </Modal>
  );
}

export default React.forwardRef(ModalReason) as (
  props: ModalReasonProps & {
    ref?: React.ForwardedRef<{ show: () => void; hide: () => void }>;
  },
) => ReturnType<typeof ModalReason>;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
  backdropStyle: {
    flex: 1,
    backgroundColor: 'rgba(30, 31, 32, 0.86)',
  },
  modal: {
    flex: 1,
  },
  modalReason: {
    marginHorizontal: 16,
    marginTop: 12,
    borderRadius: 16,
    paddingHorizontal: 6,
    flex: 1,
    shadowColor: 'rgba(29, 30, 44, 0.3)',
    shadowOffset: {
      width: 2,
      height: 4,
    },
    shadowOpacity: 0.4,
    shadowRadius: 5.0,
    elevation: 2,
  },
  item: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    borderBottomWidth: 1,
    paddingHorizontal: 24,
    maxHeight: 60,
    paddingTop: 24,
    paddingBottom: 16,
  },
  box: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 24,
    height: 24,
    marginHorizontal: 8,
  },
  icon24: {
    width: 24,
    height: 24,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  topNavigation: {
    marginTop: 12,
  },
});
