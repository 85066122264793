import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useTheme } from '@ui-kitten/components';

import Text from './Text';

interface SelectItemProps {
  title: string;
  onPress?(): void;
  last?: boolean;
}

const SelectItem: React.FC<SelectItemProps> = ({
  title,
  last = false,
  onPress,
}) => {
  const theme = useTheme();

  const borderBottomColor = last ? 'transparent' : theme['border-input-color'];

  return (
    <TouchableOpacity
      activeOpacity={0.7}
      style={[styles.container, { borderBottomColor }]}
      onPress={onPress}
    >
      <Text category="t1" status="primary">
        {title}
      </Text>
    </TouchableOpacity>
  );
};

export default SelectItem;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
  },
});
