import React from 'react';
import {
  TouchableOpacity,
  StyleSheet,
  View,
  Image,
  ImageBackground,
  Platform,
} from 'react-native';
import { useTheme, Icon } from '@ui-kitten/components';
import {
  createDrawerNavigator,
  DrawerContentComponentProps,
} from '@react-navigation/drawer';
import { Container, ModalConfirm, Text } from 'components';
import {
  useNavigation,
  NavigationProp,
  DrawerActions,
} from '@react-navigation/native';
import { useAppTheme, useAuth, useLayout, useModal } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';

import MainBottomTab from './MainBottomTab';
import UpdateProfile from 'screens/profile/UpdateProfile';
import DeleteAccount from 'screens/profile/DeleteAccount';

import Configs from 'configs';
import { isEmpty } from 'lodash';
import {
  DrawerItemProps,
  EKeyAsyncStorage,
  IEmergencyNumber,
  MobileAppOptions,
} from 'constants/types';
import { Images } from 'assets/images';
import { clearVisitors } from 'store/slices/visitorSlice';
import { clearInvites } from 'store/slices/inviteSlice';
import { requestSelector } from 'store/slices/requestSlide';
import { documentSelector } from 'store/slices/documentSlice';
import { DrawerStackParamList, MainStackParamList } from './types';

const { Navigator, Screen } = createDrawerNavigator<DrawerStackParamList>();

const DrawerContent: React.FC<DrawerContentComponentProps> = () => {
  const { t } = useTranslation(['common', 'home', 'request']);
  const { logout } = useAuth();
  const { bottom } = useLayout();
  const theme = useTheme();
  const { theme: themes } = useAppTheme();
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const { visible, hide, show } = useModal();
  const { navigate } = useNavigation<NavigationProp<DrawerStackParamList>>();
  const { navigate: _navigate } =
    useNavigation<NavigationProp<MainStackParamList>>();

  const { services, bookings } = useAppSelector(requestSelector);
  const { userDocuments, siteDocuments } = useAppSelector(documentSelector);
  const { getItem: getUser } = useAsyncStorage(EKeyAsyncStorage.user);
  const { getItem } = useAsyncStorage(EKeyAsyncStorage.emergencyNumbers);

  const [user, setUser] = React.useState<MobileAppOptions>();
  const [listEmergencyNumber, setListEmergencyNumber] = React.useState<
    Array<IEmergencyNumber>
  >([]);

  React.useEffect(() => {
    (async () => {
      const emergencyNumbers = await getItem();
      const _user = await getUser();
      if (emergencyNumbers) {
        setListEmergencyNumber(JSON.parse(emergencyNumbers));
      }
      if (_user) {
        setUser(JSON.parse(_user));
      }
    })();
  }, []);

  const menu: DrawerItemProps[] = [
    {
      title: t('common:account_settings'),
      icon: 'settingFill',
      status: Configs.PROFILE_BUTTON_STATUS,
      onPress: () => navigate('UpdateProfile'),
    },
    {
      title: t('common:home'),
      icon: 'home',
      status: 'success',
      onPress: () => _navigate('Home', { screen: 'HomeScreen' }),
    },
    {
      title: t('common:visitors'),
      icon: 'babyFill',
      status: 'green',
      onPress: () => _navigate('Visitors', { screen: 'VisitorsScreen' }),
    },
    {
      title: t('common:invitations'),
      icon: 'calendarFill',
      status: 'basic',
      onPress: () => _navigate('Invitations', { screen: 'InvitationsScreen' }),
    },
    {
      title: t('request:approve_requests'),
      icon: 'request',
      status: 'basic',
      onPress: () => _navigate('Requests', { screen: 'ApproveRequests' }),
    },
    {
      title: t('request:bookings'),
      icon: 'wishlist',
      status: 'basic',
      onPress: () => _navigate('Home', { screen: 'BookingsScreen' }),
      isEmpty: isEmpty(bookings),
    },
    {
      title: t('request:services'),
      icon: 'calendarFill',
      status: 'basic',
      onPress: () => _navigate('Home', { screen: 'ServicesScreen' }),
      isEmpty: isEmpty(services),
    },
    {
      title: t('request:activity'),
      icon: 'activity',
      status: 'basic',
      onPress: () => _navigate('Home', { screen: 'EventsScreen' }),
    },
    {
      title: t('request:documents'),
      icon: 'document',
      status: 'basic',
      onPress: () => _navigate('Home', { screen: 'DocumentsScreen' }),
      isEmpty: isEmpty(userDocuments) && isEmpty(siteDocuments),
    },
    {
      title: t('request:emergency_numbers'),
      icon: 'phone',
      status: 'danger',
      onPress: () => _navigate('Home', { screen: 'EmergencyNumbersScreen' }),
      isEmpty: listEmergencyNumber.length === 0,
    },
    {
      title: t('common:logout'),
      icon: 'logout',
      status: 'basic',
      onPress: () => show(),
    },
  ];

  const handleLogout = React.useCallback(() => {
    hide();
    dispatch(clearVisitors());
    dispatch(clearInvites());
    logout().then();
  }, []);

  const getColor = (
    status:
      | 'primary'
      | 'success'
      | 'green'
      | 'danger'
      | 'basic'
      | 'secondary'
      | 'dark',
  ): string => {
    switch (status) {
      case 'basic':
        return theme['background-basic-color-13'];
      case 'primary':
        return theme['color-primary-500'];
      case 'success':
        return theme['color-success-500'];
      case 'green':
        return theme['color-info-500'];
      case 'danger':
        return theme['color-danger-500'];
      case 'secondary':
        return theme['color-secondary-500'];
      case 'dark':
        return theme['background-basic-color-10'];
      default:
        return theme['background-basic-color-13'];
    }
  };

  const DrawerItem = React.useCallback(
    ({ icon, title, status, onPress }: DrawerItemProps) => {
      return (
        <TouchableOpacity
          onPress={() => {
            navigation.dispatch(DrawerActions.closeDrawer());
            setTimeout(() => onPress(), 550);
          }}
          activeOpacity={0.7}
          style={styles.drawerItem}
        >
          <View style={styles.flexRow}>
            <TouchableOpacity
              activeOpacity={0.7}
              disabled={!onPress}
              onPress={onPress}
              style={[styles.iconView]}
            >
              <ImageBackground
                source={Images.shape}
                imageStyle={{
                  tintColor: getColor(status),
                  width: '100%',
                  height: '100%',
                }}
                style={[
                  styles.item,
                  {
                    width: '100%',
                    height: '100%',
                  },
                ]}
              >
                <Icon
                  pack="assets"
                  name={icon ? icon : 'back'}
                  style={[
                    {
                      width: 20,
                      height: 20,
                      tintColor: theme['color-basic-100'],
                    },
                  ]}
                />
              </ImageBackground>
            </TouchableOpacity>
            <View style={styles.text}>
              <Text marginLeft={24} category="b2" center>
                {title}
              </Text>
            </View>
          </View>
          <Icon
            pack="assets"
            name="arrow-right"
            style={[styles.icon, { tintColor: theme['icon-basic-color'] }]}
          />
        </TouchableOpacity>
      );
    },
    [theme],
  );

  return (
    <Container style={[styles.container, { paddingBottom: bottom + 16 }]}>
      <View style={styles.header}>
        <Image
          source={{
            uri: themes === 'dark' ? Configs.APP_LOGO_LIGHT : Configs.APP_LOGO,
          }}
          resizeMode="contain"
          style={Configs.DRAWER_LOGO_STYLE}
        />
        <TouchableOpacity
          onPress={() => navigation.dispatch(DrawerActions.closeDrawer())}
          activeOpacity={0.7}
          style={[
            styles.closeDraw,
            { marginTop: Platform.OS === 'web' ? 16 : 0 },
          ]}
        >
          <Icon
            pack="assets"
            name="close"
            style={{
              tintColor: theme['icon-basic-color'],
              width: 24,
              height: 24,
            }}
          />
        </TouchableOpacity>
      </View>
      <Text category="h7">{user?.siteName}</Text>
      <Text category="b4" marginBottom={12}>
        {t('common:hi', { name: user?.name, surname: user?.surname })}!
      </Text>
      <View style={styles.flexOne}>
        {menu.map((item, index) => {
          const { isEmpty = false } = item;
          return !isEmpty && <DrawerItem key={index} {...item} />;
        })}
      </View>
      {/* <SwitchMode /> */}
      <ModalConfirm
        visible={visible}
        onBackdropPress={hide}
        title={`${t('common:log_out')}`}
        description={t('common:description_log_out')}
        buttonLeft={{ onPress: hide, title: t('common:cancel') }}
        buttonRight={{ onPress: handleLogout, title: t('common:log_out') }}
      />
    </Container>
  );
};

const DrawerNavigator: React.FC = () => (
  <Navigator
    initialRouteName="MainBottomTab"
    screenOptions={{
      headerShown: false,
      drawerStyle: styles.drawerStyle,
      swipeEnabled: false,
    }}
    drawerContent={(props: DrawerContentComponentProps) => (
      <DrawerContent {...props} />
    )}
  >
    <Screen name="MainBottomTab" component={MainBottomTab} />
    <Screen name="UpdateProfile" component={UpdateProfile} />
    <Screen name="DeleteAccount" component={DeleteAccount} />
  </Navigator>
);

export default DrawerNavigator;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 24,
  },
  iconView: {
    height: '100%',
    aspectRatio: 1 / 1,
  },
  drawerStyle: {
    width: '100%',
  },
  drawerItem: {
    paddingVertical: 4,
    flexDirection: 'row',
    flex: 1,
    height: '100%',
    maxHeight: 50,
  },
  flexRow: {
    flexDirection: 'row',
    flex: 1,
  },
  icon: {
    width: 16,
    height: 16,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  logoEv: {
    width: 92,
    height: 24,
  },
  logoStation: {
    width: 40,
    height: 40,
  },
  closeDraw: {
    width: 40,
    height: 40,
    marginRight: -16,
  },
  item: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    flex: 9,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  flexOne: {
    flex: 1,
    marginBottom: 16,
  },
});
