import React from 'react';
import { StyleSheet, FlatList, View, Linking, Image } from 'react-native';
import {
  Container,
  HStack,
  NavigationAction,
  Shape,
  Text,
  TopTab,
  UserItem,
} from 'components';
import {
  Icon,
  TopNavigation,
  useTheme,
  ViewPager,
} from '@ui-kitten/components';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useTranslation } from 'react-i18next';
import { useDrawer, useLayout } from 'hooks';

import { Images } from 'assets/images';
import { IDocument } from 'constants/types';
import { setAppLoading } from 'store/slices/appSlice';
import { requestGetDocumentPreSignedDownloadURL } from 'api/document';
import { documentSelector, getListDocuments } from 'store/slices/documentSlice';

const DocumentsScreen = React.memo(() => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { bottom } = useLayout();
  const { openDrawer } = useDrawer();
  const { t } = useTranslation(['common', 'request']);
  const { userDocuments, siteDocuments } = useAppSelector(documentSelector);

  const [selectIndex, setSelectIndex] = React.useState<number>(0);
  const [offsetChange, setOffsetChange] = React.useState<number>(0);
  const [isRefresh, setIsRefresh] = React.useState<boolean>(false);

  const handleDownload = React.useCallback(async (item: IDocument) => {
    try {
      dispatch(setAppLoading(true));
      const res = await requestGetDocumentPreSignedDownloadURL({
        uuid: item.uuid,
      });

      if (res) {
        dispatch(setAppLoading(false));
        Linking.openURL(res.url);
      }
    } catch (error) {
      dispatch(setAppLoading(false));
    }
  }, []);

  const handleRefresh = () => {
    setIsRefresh(true);
    dispatch(getListDocuments());
    setIsRefresh(false);
  };

  const listEmptyComponent = React.useCallback(() => {
    return (
      <View style={styles.emptyPage}>
        <Image source={Images.image_document_empty} style={styles.empty} />
        <Text category="b1" status="placeholder" marginTop={24}>
          {t('request:empty_documents')}
        </Text>
      </View>
    );
  }, []);

  const renderItem = React.useCallback(
    ({ item }: { item: IDocument }) => {
      const { filename, effectiveDate } = item;
      return (
        <HStack
          level="1"
          style={[styles.docItem, styles.shadow]}
          itemsCenter
          onPress={() => handleDownload(item)}
        >
          <Icon
            pack="assets"
            name="paper"
            style={[styles.iconPaper, { tintColor: theme['icon-basic-color'] }]}
          />
          <View style={styles.content}>
            <View>
              <Text
                category="c2"
                marginBottom={8}
                numberOfLines={1}
                ellipsizeMode="middle"
              >
                {filename}
              </Text>
            </View>
            <Text category="c5-m">{effectiveDate}</Text>
          </View>
          <Shape
            onPress={() => handleDownload(item)}
            icon="download"
            status="dark"
            size="medium"
          />
        </HStack>
      );
    },
    [theme],
  );

  return (
    <Container level={'2'}>
      <TopNavigation
        style={styles.topNavigation}
        appearance="control"
        //title={t('request:documents')}
        accessoryLeft={
          <View style={styles.header}>
            <NavigationAction icon="menu" status="basic" onPress={openDrawer} />
            <UserItem />
          </View>
        }
      />
      <TopTab
        appearance="control"
        selectedIndex={selectIndex}
        onChange={setSelectIndex}
        tabs={[t('request:site_documents'), t('request:my_documents')]}
      />
      <ViewPager
        style={[styles.viewPager]}
        selectedIndex={selectIndex}
        onSelect={setSelectIndex}
        onOffsetChange={setOffsetChange}
      >
        <View style={styles.flexOne}>
          <FlatList
            data={siteDocuments || []}
            renderItem={renderItem}
            keyExtractor={(i) => i.uuid}
            contentContainerStyle={[
              styles.contentContainerStyle,
              {
                paddingBottom: bottom + 16,
              },
            ]}
            refreshing={isRefresh}
            onRefresh={handleRefresh}
            ListEmptyComponent={listEmptyComponent}
          />
        </View>
        <View style={styles.flexOne}>
          <FlatList
            data={userDocuments || []}
            renderItem={renderItem}
            keyExtractor={(i) => i.uuid}
            contentContainerStyle={[
              styles.contentContainerStyle,
              {
                paddingBottom: bottom + 16,
              },
            ]}
            refreshing={isRefresh}
            onRefresh={handleRefresh}
            ListEmptyComponent={listEmptyComponent}
          />
        </View>
      </ViewPager>
    </Container>
  );
});

export default DocumentsScreen;

const styles = StyleSheet.create({
  viewPager: {
    flex: 1,
  },
  contentContainerStyle: {
    paddingTop: 16,
  },
  docItem: {
    marginHorizontal: 24,
    borderRadius: 12,
    padding: 16,
    marginBottom: 16,
  },
  shadow: {
    shadowColor: 'rgba(29, 30, 44, 0.3)',
    shadowOffset: {
      width: 2,
      height: 4,
    },
    shadowOpacity: 0.48,
    shadowRadius: 12.0,
  },
  iconPaper: {
    width: 40,
    height: 40,
  },
  emptyPage: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 100,
  },
  empty: {
    width: 48,
    height: 48,
  },
  flexOne: {
    flex: 1,
  },
  content: {
    flex: 1,
    width: '100%',
    marginLeft: 12,
    marginRight: 12,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  topNavigation: {
    marginTop: 12,
  },
});
