import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useTheme, Button, Layout, Modal } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import { useLayout } from 'hooks';

import Text from './Text';

import dayjs from 'dayjs';
import { EvaStatus } from '@ui-kitten/components/devsupport';
import { IShare } from 'constants/types';
interface ModalConfirmProps {
  visible: boolean;
  onBackdropPress(): void;
  title: string;
  description: string;
  content?: Pick<IShare, 'activeDate' | 'expiryDate'>;
  buttonLeft?: {
    title?: string;
    onPress?: () => void;
    status?: EvaStatus;
  };
  buttonRight?: {
    title?: string;
    onPress?: () => void;
    status?: EvaStatus;
  };
}

function ModalConfirm({
  visible,
  title,
  description,
  content,
  buttonLeft,
  buttonRight,
  onBackdropPress,
}: ModalConfirmProps) {
  const theme = useTheme();
  const { width } = useLayout();
  const { t } = useTranslation('common');

  return (
    <Modal
      visible={visible}
      onBackdropPress={onBackdropPress}
      backdropStyle={styles.container}
    >
      <Layout level="1" style={[styles.modal, { width: width - 32 * 2 }]}>
        <Text marginHorizontal={32} category="b2" center>
          {title}
        </Text>
        <Text marginTop={16} center marginHorizontal={24}>
          {description}
        </Text>
        {!!content && (
          <View style={styles.bottom}>
            <Text category="c2">When</Text>
            <View
              style={[
                styles.dot,
                { backgroundColor: theme['background-basic-color-4'] },
              ]}
            />
            <Text category="c2">
              {dayjs(content.activeDate).format('YYYY/MM/DD')} -{' '}
              {dayjs(content.expiryDate).format('YYYY/MM/DD')}
            </Text>
          </View>
        )}
        <View style={styles.buttonView}>
          <Button
            style={{ flex: 1 }}
            status={buttonLeft?.status || 'primary'}
            size="medium"
            onPress={buttonLeft?.onPress}
          >
            {buttonLeft?.title || t('cancel').toString()}
          </Button>
          <Button
            size="medium"
            style={styles.button}
            onPress={buttonRight?.onPress}
            status={buttonRight?.status || 'basic'}
          >
            {buttonRight?.title || t('save').toString()}
          </Button>
        </View>
      </Layout>
    </Modal>
  );
}

export default React.forwardRef(ModalConfirm) as (
  props: ModalConfirmProps & {
    ref?: React.ForwardedRef<{ show: () => void; hide: () => void }>;
  },
) => ReturnType<typeof ModalConfirm>;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.86)',
  },
  modal: {
    borderRadius: 24,
    paddingTop: 48,
    paddingBottom: 40,
    alignSelf: 'center',
    marginHorizontal: 16,
  },
  buttonView: {
    paddingHorizontal: 24,
    marginTop: 32,
    flexDirection: 'row',
  },
  button: {
    flex: 1,
    marginLeft: 8,
  },
  bottom: {
    flexDirection: 'row',
    marginTop: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  dot: {
    width: 3,
    height: 3,
    borderRadius: 3,
    marginHorizontal: 8,
  },
});
