import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import {
  Container,
  Content,
  LoadingIndicator,
  NavigationAction,
  Text,
} from 'components';
import {
  useTheme,
  TopNavigation,
  Input,
  Icon,
  Button,
} from '@ui-kitten/components';
import {
  useNavigation,
  DrawerActions,
  NavigationProp,
} from '@react-navigation/native';
import { useAuth, useBoolean } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useToast } from 'react-native-toast-notifications';

import { ruleCountersign } from 'utils/rules';
import { DrawerStackParamList } from 'navigation/types';

interface FormValues {
  countersign: string;
}

const DeleteAccount: React.FC = () => {
  const theme = useTheme();
  const toast = useToast();
  const { t } = useTranslation(['account_settings', 'common', 'sign_in']);
  const navigation = useNavigation();
  const { navigate } = useNavigation<NavigationProp<DrawerStackParamList>>();
  const { setToken, deactivateAccount } = useAuth();

  const [showCountersignValue, { toggle: toggleCountersignValue }] =
    useBoolean(true);
  const [loading, setLoading] = React.useState<boolean>(false);

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      countersign: '',
    },
  });

  const handleAccountDelete = React.useCallback(async (data: FormValues) => {
    try {
      setLoading(true);

      await deactivateAccount(data.countersign).then((i) => {
        if (i.reason === 'success') {
          toast.show(t('account_settings:message_success_delete_account'), {
            type: 'success',
            placement: 'top',
            successColor: theme['color-primary-500'],
            duration: 2000,
            animationType: 'slide-in',
            onClose: () => setToken(undefined),
          });
        } else {
          toast.show(i.message, {
            type: 'danger',
            placement: 'top',
            duration: 2000,
            dangerColor: theme['color-danger-500'],
            animationType: 'slide-in',
          });
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const onPressHandleCancel = () => () => {
    navigate('UpdateProfile');
  };

  const styles = StyleSheet.create({
    input: {
      marginTop: 24,
    },
    button: {
      marginTop: 24,
    },
    button_danger: {
      backgroundColor: theme['color-danger-500'],
      borderColor: theme['color-danger-500'],
    },
    button_clear: {
      marginRight: 16,
    },
    textStyle: {
      height: '100%',
    },
  });

  return (
    <Container level="2">
      <TopNavigation
        appearance="control"
        title={t('account_settings:heading')}
        accessoryLeft={
          <NavigationAction
            icon="menu"
            status="basic"
            onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
          />
        }
      />
      <Content isPadding>
        <Text category="h6" status="danger" marginTop={36}>
          {t('account_settings:heading_important_information')}
        </Text>
        <Text category="h7" marginTop={48} status="danger">
          {t('account_settings:heading_proceed_and_delete_acc')}
        </Text>
        <Text marginTop={12} category="b5-b">
          {t('account_settings:delete_warning_1')}
        </Text>
        <Text marginTop={12} category="b8">
          {t('account_settings:delete_warning_2')}
        </Text>
        <Text marginTop={12} category="b8">
          {t('account_settings:delete_warning_3')}
        </Text>
        <Text marginTop={12} category="b8">
          {t('account_settings:delete_warning_4')}
        </Text>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              label={t('account_settings:countersign_current_password')}
              style={styles.input}
              value={value}
              onChangeText={onChange}
              status={errors.countersign ? 'danger' : 'primary'}
              onBlur={onBlur}
              textStyle={styles.textStyle}
              secureTextEntry={showCountersignValue}
              textContentType={'none'}
              autoCapitalize="none"
              caption={errors.countersign?.message}
              placeholder={t('account_settings:current_password')}
              accessoryRight={(props) => (
                <TouchableOpacity
                  activeOpacity={0.7}
                  onPress={toggleCountersignValue}
                >
                  <Icon
                    pack="assets"
                    name={showCountersignValue ? 'eyeOff' : 'eyeOn'}
                    {...props}
                  />
                </TouchableOpacity>
              )}
            />
          )}
          name="countersign"
          rules={ruleCountersign}
        />

        <View style={{ marginTop: 24, marginBottom: 24, flexDirection: 'row' }}>
          <Button
            children={t('common:back')}
            style={styles.button_clear}
            onPress={onPressHandleCancel()}
            status="basic"
          />
          <Button
            children={t('account_settings:delete_account_btn')}
            style={styles.button_danger}
            onPress={handleSubmit(handleAccountDelete)}
            disabled={loading || isSubmitting}
            accessoryLeft={
              isSubmitting || loading ? (
                <LoadingIndicator size="small" />
              ) : undefined
            }
          />
        </View>
      </Content>
    </Container>
  );
};

export default DeleteAccount;
