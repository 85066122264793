import Configs from 'configs';
import dayjs from 'dayjs';
import { AppThunk } from 'store/store';
import { RootState } from '../rootReducer';
import { setAppLoading } from 'store/slices/appSlice';
import {
  requestListUserNotifications,
  requestReadNotificationMessage,
} from 'api/notification';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EKeyAsyncStorage, INotification } from 'constants/types';
import AsyncStorage from '@react-native-async-storage/async-storage';

export interface INotificationState {
  isLoading: boolean;
  notifications: INotification[];
  notification: INotification | null;
  notifications_unread: number;
  notificationUpdatedAt: number;
}

export const initialState: INotificationState = {
  isLoading: false,
  notifications: [],
  notification: null,
  notifications_unread: 0,
  notificationUpdatedAt: 0,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotifications: (
      state: INotificationState,
      { payload }: PayloadAction<INotification[]>,
    ) => {
      state.notifications = payload;
      state.notificationUpdatedAt = dayjs().unix();
    },
    setNotification: (
      state: INotificationState,
      { payload }: PayloadAction<INotification>,
    ) => {
      state.notification = payload;
    },
    setNotificationsUnread: (
      state: INotificationState,
      { payload }: PayloadAction<number>,
    ) => {
      state.notifications_unread = payload;
    },
    setNotificationLoading: (
      state: INotificationState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isLoading = payload;
    },
  },
});

export const {
  setNotifications,
  setNotification,
  setNotificationsUnread,
  setNotificationLoading,
} = notificationSlice.actions;

type GetAllNotificationsPayLoad = {
  start?: number;
  limit?: number;
};

export const getAllNotifications =
  ({
    payload,
    refresh = false,
    force = false,
  }: {
    payload: GetAllNotificationsPayLoad;
    refresh?: boolean;
    force?: boolean;
  }): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { notificationUpdatedAt } = notificationSelector(getState());

      if (!force) {
        const notificationsCache = await AsyncStorage.getItem(
          EKeyAsyncStorage.notifications,
        );
        if (
          notificationsCache &&
          dayjs().unix() < notificationUpdatedAt + Configs.CACHE_TTL_SECS_EVENT
        ) {
          const _notificationsCache: INotification[] =
            JSON.parse(notificationsCache);

          const unreadNotification = _notificationsCache.filter(
            (i) => i.read === false,
          );

          dispatch(setNotificationsUnread(unreadNotification.length));
          dispatch(setNotifications(_notificationsCache));
        }
      }

      if (!refresh) {
        dispatch(setAppLoading(true));
      }

      const result = await requestListUserNotifications(payload).catch(() => {
        dispatch(setAppLoading(false));
      });

      if (result) {
        const notifications = result.entries;

        const unreadNotification = notifications.filter(
          (i) => i.read === false,
        );

        dispatch(setNotificationsUnread(unreadNotification.length));
        dispatch(setNotifications(notifications));

        await AsyncStorage.setItem(
          EKeyAsyncStorage.notifications,
          JSON.stringify(notifications),
        );

        if (!refresh) {
          dispatch(setAppLoading(false));
        }
      }
    } catch (error) {
      dispatch(setAppLoading(false));
    }
  };

type ReadNotificationMessagePayLoad = { uuid: string };

export const readNotificationMessage =
  (payload: ReadNotificationMessagePayLoad): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setNotificationLoading(true));
      const result = await requestReadNotificationMessage(payload);

      if (result) {
        dispatch(setNotification(result));
        let _payload: any = {
          payload: {
            start: 0,
            limit: 500,
          },
          refresh: true,
          force: true,
        };
        dispatch(getAllNotifications(_payload));
      }
    } catch (error) {
      dispatch(setNotificationLoading(false));
      console.log('error read notification message', error);
    }
  };

export const notificationSelector = (state: RootState) => state.notification;

export default notificationSlice.reducer;
