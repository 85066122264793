import React from 'react';
import {
  View,
  StyleSheet,
  FlatList,
  Modal,
  TouchableOpacity,
} from 'react-native';
import {
  Container,
  Content,
  ModalCalendar,
  ModalProcess,
  NavigationAction,
  Text,
  UserItem,
} from 'components';
import {
  Button,
  CheckBox,
  Icon,
  Layout,
  TopNavigation,
  useTheme,
} from '@ui-kitten/components';
import {
  useRoute,
  useNavigation,
  DrawerActions,
  NavigationProp,
  RouteProp,
} from '@react-navigation/native';
import {useAsyncStorage} from '@react-native-async-storage/async-storage';
import {useBoolean, useLayout, useModal} from 'hooks';
import {useAppDispatch} from 'store/store';
import {useTranslation} from 'react-i18next';

import dayjs from 'dayjs';
import keyExtractor from 'utils/keyExtractor';
import parsePhoneNumber from 'libphonenumber-js';

import {requestCreateNewInvite} from 'api/invite';
import {getAllInvites} from 'store/slices/inviteSlice';
import {
  EKeyAsyncStorage,
  ICreateInvite,
  IShare,
  IVisitReason,
  MobileAppOptions,
} from 'constants/types';
import {InvitesMainParamList, MainStackParamList} from 'navigation/types';
import {setAppLoading} from 'store/slices/appSlice';
import {getAllVisitors, setIsLoadingVisitor} from 'store/slices/visitorSlice';
import moment from "moment";

type InviteNewRouteProp = RouteProp<InvitesMainParamList, 'InviteNew'>;

const InviteNew: React.FC = () => {
  const theme = useTheme();
  const {top} = useLayout();
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const {t} = useTranslation(['common', 'invite_visitor']);
  const {navigate} = useNavigation<NavigationProp<MainStackParamList>>();

  const {
    params: {visitor, path},
  } = useRoute<InviteNewRouteProp>();

  const [showReasonModal, {on: onReasonModal, off: offReasonModal}] =
    useBoolean(false);
  const {
    visible: modalActiveRef,
    show: showActiveModal,
    hide: hideActiveModal,
  } = useModal();
  const {
    visible: modalExpiryRef,
    show: showExpiryModal,
    hide: hideExpiryModal,
  } = useModal();
  const {
    visible: modalReasonProcess,
    show: showReasonProcess,
    hide: hideReasonProcess,
  } = useModal();
  const {getItem: getUser} = useAsyncStorage(EKeyAsyncStorage.user);

  const [reason, setReason] = React.useState<IVisitReason | undefined>(
    undefined,
  );

  const [user, setUser] = React.useState<MobileAppOptions>();
  const [maxDate, setMaxDate] = React.useState<string>(dayjs().format());
  const [activeDate, setActiveDate] = React.useState<string>(dayjs().format());
  const [expiryDate, setExpiryDate] = React.useState<string>(dayjs().format());

  React.useEffect(() => {
    (async () => {
      const _user = await getUser();

      if (_user) {
        setUser(JSON.parse(_user));
      }
    })();
  }, []);

  React.useEffect(() => {
    if (visitor.visitReasonId || visitor.visitReason) {
      user?.visitReasons.find((i) => {
        if (
          i.id === visitor.visitReasonId ||
          i.id === visitor.visitReason?.id
        ) {
          setReason(i);
        }
      });
    }
  }, [user, visitor]);

  React.useEffect(() => {
    if (user) {
      const epoch: number = new Date(activeDate).getSeconds();
      const expiryDate: Date = new Date(activeDate);
      const maxExpiryDate: Date = new Date(activeDate);
      expiryDate.setSeconds(epoch + Number(user?.defaultDurationOfStaySecs));
      maxExpiryDate.setSeconds(epoch + Number(user?.maxDurationOfStaySecs));
      const suggestedExpiryDate = expiryDate.toISOString();
      const maxDate = maxExpiryDate.toISOString();
      setExpiryDate(suggestedExpiryDate);
      setMaxDate(maxDate);
    }
  }, [user, activeDate]);

  const handleInvite = React.useCallback(async () => {
    try {
      if (user?.visitorRegRequiredReason && !reason) {
        showReasonProcess();
        return;
      }
      dispatch(setAppLoading(true));

      const invite: ICreateInvite = user?.visitorRegRequiredReason
        ? {
          visitorId: visitor.visitorId || visitor.id || 0,
          activeDate: dayjs(activeDate).format(),
          expiryDate: dayjs(expiryDate).format(),
          visitReasonId: reason?.id || 0,
        }
        : {
          visitorId: visitor.visitorId || visitor.id || 0,
          activeDate: dayjs(activeDate).format(),
          expiryDate: dayjs(expiryDate).format(),
        };

      const result = await requestCreateNewInvite(invite);

      if (!!result && result.code === '200') {
        dispatch(getAllInvites(true));
        dispatch(getAllVisitors({force: true}));

        const share: IShare = {
          uuid: result.uuid,
          activeDate: result.activeDate,
          expiryDate: result.expiryDate,
          id: result.id,
          firstName: result.firstName,
          lastName: result.lastName,
          mobileNumber: visitor.mobileNumber,
          message: result.message,
        };
        dispatch(setAppLoading(false));

        navigate('Invitations', {screen: 'InviteShare', params: {share}});
      }
    } finally {
      dispatch(setAppLoading(false));
    }
  }, [visitor, activeDate, expiryDate, reason]);

  const renderReason = React.useCallback(({item}: { item: IVisitReason }) => {
    return (
      <CheckBox
        style={styles.checkBox}
        checked={reason?.id === item.id}
        status="round"
        children={item.reason}
        activeOpacity={0.7}
        onChange={() => {
          setReason(item);
          offReasonModal();
        }}
      />
    );
  }, []);

  const handleCancel = React.useCallback(() => {
    if (visitor.id && path === 'VisitorProfile') {
      dispatch(setIsLoadingVisitor(true));
      navigate('Visitors', {
        screen: 'VisitorProfile',
        params: {id: visitor.id},
      });
    } else if (path === 'VisitorsScreen') {
      navigate('Visitors', {screen: 'VisitorsScreen'});
    }
  }, [path, visitor]);

  return (
    <Container level="2">
      <TopNavigation
        style={styles.topNavigation}
        appearance="control"
        //title={t('invite_visitor:invite_visitor')}
        accessoryLeft={
          <View style={styles.header}>
            <NavigationAction
              icon="menu"
              status="basic"
              onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
            />
            <UserItem/>
          </View>
        }
      />
      <Content isPadding>
        <Text category="b2" marginTop={16}>
          {t('invite_visitor:invite_visitor')}
        </Text>
        <Text category="c3" marginTop={4} status="placeholder">
          {t('invite_visitor:invite_visitor_description')}
        </Text>
        <Layout style={styles.box}>
          <View style={styles.name}>
            <Text category="b5" status="placeholder">
              {t('invite_visitor:who')}
            </Text>
            <Text category="b4">
              {visitor.firstName && `${visitor.firstName} `}
              {visitor.lastName}
            </Text>
          </View>
          <View style={styles.name}>
            <Text category="b5" status="placeholder">
              {t('invite_visitor:mobile')}
            </Text>
            <Text category="b4">
              {visitor.mobileNumber
                ? parsePhoneNumber(visitor.mobileNumber)?.format('E.164')
                : ''}
            </Text>
          </View>
          <View style={styles.name}>
            <Text category="b5" status="placeholder">
              {t('common:reason')}
            </Text>
            <TouchableOpacity
              style={styles.row}
              onPress={onReasonModal}
              activeOpacity={0.7}
            >
              <Text category="b4">{reason?.reason}</Text>
              <Icon name="caret-down" pack="assets" style={styles.icon}/>
            </TouchableOpacity>
          </View>
          <View style={styles.name}>
            <Text category="b5" status="placeholder">
              {t('invite_visitor:when')}
            </Text>
            <TouchableOpacity
              style={styles.row}
              onPress={showActiveModal}
              activeOpacity={0.7}
            >
              <Text category="b4">
                {dayjs(activeDate).format('DD/MM/YYYY')}
              </Text>
            </TouchableOpacity>
          </View>
          <View style={[styles.name, {marginBottom: 0}]}>
            <Text category="b5" status="placeholder">
              {t('invite_visitor:until')}
            </Text>
            <TouchableOpacity
              style={styles.row}
              onPress={showExpiryModal}
              activeOpacity={0.7}
            >
              <Text category="b4">
                {dayjs(expiryDate).format('DD/MM/YYYY')}
              </Text>
            </TouchableOpacity>
          </View>
        </Layout>
        <View style={styles.viewButton}>
          <Button
            children={t('common:back')}
            status="basic"
            style={styles.buttonCancel}
            onPress={handleCancel}
          />
          <Button
            children={t('common:invite')}
            style={styles.buttonInvite}
            onPress={handleInvite}
          />
        </View>
      </Content>
      <ModalCalendar
        visible={modalActiveRef}
        onBackdropPress={hideActiveModal}
        title="When"
        date={dayjs(activeDate).toDate()}
        onChangeDate={(date) => setActiveDate(dayjs(date).format())}
        min={new Date()}
        max={moment().add(1, 'years').toDate()}
      />
      <ModalCalendar
        visible={modalExpiryRef}
        onBackdropPress={hideExpiryModal}
        title="Until"
        date={dayjs(expiryDate).toDate()}
        onChangeDate={(date) => setExpiryDate(dayjs(date).format())}
        min={new Date(activeDate)}
        max={new Date(maxDate)}
      />
      <ModalProcess
        visible={modalReasonProcess}
        onBackdropPress={hideReasonProcess}
        title="Action Required"
        description="Please select a visit reason to proceed!"
        button={{onPress: hideReasonProcess}}
      />
      <Modal
        visible={showReasonModal}
        transparent
        onRequestClose={offReasonModal}
      >
        <Layout level="2" style={[styles.modal, {paddingTop: top}]}>
          <TopNavigation
            appearance="control"
            title={t('common:model_title_reason')}
            accessoryLeft={
              <NavigationAction
                icon="close"
                status="basic"
                onPress={offReasonModal}
              />
            }
          />
          <FlatList
            data={user?.visitReasons || []}
            keyExtractor={keyExtractor}
            renderItem={renderReason}
            scrollEventThrottle={16}
            showsVerticalScrollIndicator={false}
            refreshing={false}
            style={[
              styles.modalReason,
              {backgroundColor: theme['background-basic-color-1']},
            ]}
          />
        </Layout>
      </Modal>
    </Container>
  );
};

export default InviteNew;

const styles = StyleSheet.create({
  box: {
    marginTop: 6,
    borderRadius: 16,
    paddingVertical: 24,
    paddingHorizontal: 16,
    shadowColor: 'rgba(0, 64, 128, 1)',
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.1,
    shadowRadius: 40,
    elevation: 5,
  },
  name: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
  },
  modal: {
    flex: 1,
  },
  modalReason: {
    marginHorizontal: 16,
    borderTopLeftRadius: 16,
    marginTop: 16,
    paddingTop: 12,
    borderTopRightRadius: 16,
    paddingHorizontal: 24,
  },
  checkBox: {
    paddingVertical: 12,
  },
  icon: {
    width: 16,
    height: 16,
    marginLeft: 8,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minWidth: 120,
  },
  buttonCancel: {
    flex: 1,
    marginRight: 12,
  },
  buttonInvite: {
    flex: 1,
  },
  viewButton: {
    marginTop: 24,
    flexDirection: 'row',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  topNavigation: {
    marginTop: 12,
  },
});
