import React from 'react';
import * as Clipboard from 'expo-clipboard';

type CopiedValue = string | null;
type CopyFn = (text: string) => void;

function useCopy(): [CopiedValue, CopyFn] {
  const [copiedText, setCopiedText] = React.useState<CopiedValue>(null);

  React.useEffect(() => {
    (async () => {
      const text = await Clipboard.getStringAsync();
      setCopiedText(text);
    })();
  }, []);

  const copy: CopyFn = async (text) => {
    if (!Clipboard.setString) {
      console.log('Clipboard not supported');
      return;
    }
    await Clipboard.setString(text);
  };

  return [copiedText, copy];
}

export default useCopy;
