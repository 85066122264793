import React from 'react';
import * as Contacts from 'expo-contacts';
import { View, StyleSheet, TouchableOpacity, ViewStyle } from 'react-native';
import { useTheme } from '@ui-kitten/components';

import Text from './Text';

import { extractContactNumber } from '../utils/extractContactNumber';
// @ts-ignore
import UserAvatar from 'react-native-user-avatar';

interface ContactItemProps {
  style?: ViewStyle;
  defaultCountryCode?: string;
  item: Contacts.Contact;

  onPress?(): void;
}

const ContactItem: React.FC<ContactItemProps> = ({
  style,
  item,
  onPress,
  defaultCountryCode,
}) => {
  const theme = useTheme();
  const { name, phoneNumbers, lastName, firstName } = item;

  return (
    <TouchableOpacity
      activeOpacity={1}
      onPress={onPress}
      style={[styles.container, style]}
    >
      <UserAvatar
        size={48}
        style={{
          width: 48,
          height: 48,
          borderRadius: 16,
          overflow: 'hidden',
        }}
        imageStyle={{ borderRadius: 0 }}
        src=""
        name={`${firstName} ${lastName}`}
        colors={[
          theme['color-primary-500'],
          theme['color-info-500'],
          theme['color-warning-500'],
          theme['color-danger-500'],
          theme['background-basic-color-6'],
          theme['background-basic-color-7'],
          theme['background-basic-color-8'],
          theme['background-basic-color-13'],
          theme['background-basic-color-14'],
        ]}
      />
      <View style={styles.phone}>
        <Text category="b4">{name}</Text>
        {phoneNumbers && (
          <Text category="c2" status="placeholder">
            {extractContactNumber(phoneNumbers, defaultCountryCode)}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default ContactItem;

const styles = StyleSheet.create({
  container: {
    height: 56,
    flexDirection: 'row',
    alignItems: 'center',
  },
  phone: {
    flex: 1,
    marginLeft: 12,
  },
});
