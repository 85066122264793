import React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  Platform,
  Linking,
  Image,
  View,
} from 'react-native';
import {
  Container,
  HideWithKeyboard,
  LoadingIndicator,
  Text,
} from 'components';
import { useTheme, Button, Icon, Input } from '@ui-kitten/components';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import { useNavigation, NavigationProp } from '@react-navigation/native';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppTheme, useAuth, useBoolean, useLayout } from 'hooks';

import Configs from 'configs';
import { Images } from 'assets/images';
import { rulePassword } from 'utils/rules';
import { EKeyAsyncStorage } from 'constants/types';
import { AuthStackParamList, RootStackParamList } from 'navigation/types';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

interface FormValues {
  username: string;
  password: string;
}

const SignIn: React.FC = () => {
  const theme = useTheme();
  const { bottom } = useLayout();
  const { theme: themes } = useAppTheme();
  const ref = React.useRef<KeyboardAwareScrollView>(null);
  const passwordRef = React.useRef<Input>(null);
  const { login } = useAuth();
  const { t } = useTranslation('sign_in');
  const { reset } = useNavigation<NavigationProp<RootStackParamList>>();
  const { navigate } = useNavigation<NavigationProp<AuthStackParamList>>();
  const { getItem: getEmail } = useAsyncStorage(EKeyAsyncStorage.email);
  //const { getItem: getApprovals } = useAsyncStorage(EKeyAsyncStorage.approvals);

  const [show, { toggle }] = useBoolean(true);
  const [loading, setLoading] = React.useState<boolean>(false);

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      username: '',
      password: '',
      // username: 'support+google-pre-launch@evtrack.com',
      // password: 'Lcgcaqe4',
      // username: 'timistudio.official@gmail.com',
      // password: 'Timi1234',
    },
  });

  React.useEffect(() => {
    (async () => {
      const email = await getEmail();
      if (email) {
        setValue('username', email);
      }
    })();
  }, []);

  React.useEffect(() => {
    if (ref) {
      ref.current?.scrollForExtraHeightOnAndroid(120);
    }
  }, [ref]);

  const handleSignIn = React.useCallback(async (data: FormValues) => {
    try {
      setLoading(true);
      const authResult = await login(data.username, data.password);
      if (authResult?.token) {
        setTimeout(() => {
          reset({
            index: 0,
            routes: [{ name: 'Drawer' }],
          });
        }, 500);

        //const approvals = await getApprovals();
        // if (approvals && JSON.parse(approvals).length > 0) {
        //   reset({
        //     index: 0,
        //     routes: [{ name: 'Drawer' }],
        //   });
        //   // setTimeout(() => {
        //   //     navigate('Requests', {screen: 'ApproveRequests'});
        //   // }, 1000);
        // } else {
        //   reset({
        //     index: 0,
        //     routes: [{ name: 'Drawer' }],
        //   });
        // }
      }
    } catch (error: any) {
      setLoading(false);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, []);

  const onPressHandleSignUp = React.useCallback(() => {
    if (Platform.OS === 'web') {
      Linking.openURL(Configs.REGISTER_LINK);
    } else {
      navigate('RegisterScreen');
    }
  }, []);

  const handlePrivacyPolicy = React.useCallback(() => {
    if (Platform.OS === 'web') {
      Linking.openURL(Configs.PRIVACY_POLICY_LINK);
    } else {
      navigate('PrivacyPolicy');
    }
  }, []);

  return (
    <Container level="1">
      <View style={styles.imageView}>
        <Image
          style={[
            styles.image,
            {
              tintColor: theme['color-primary-500'],
            },
          ]}
          resizeMode="cover"
          source={Images.image_login_background}
        />
      </View>
      <KeyboardAwareScrollView
        ref={ref}
        enableOnAndroid
        extraHeight={120}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          paddingHorizontal: 24,
          paddingBottom: bottom + 16,
        }}
      >
        <View>
          <Image
            source={{
              uri:
                themes === 'dark' ? Configs.APP_LOGO_LIGHT : Configs.APP_LOGO,
            }}
            style={Configs.LOGIN_LOGO_STYLE}
            resizeMode="contain"
          />
        </View>
        <Text category="h7" marginTop={32}>
          {t('welcome')}
        </Text>
        <Text category="b4" marginTop={8} marginBottom={56}>
          {t('sign_in_to_continue')}
        </Text>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              label={t('email')}
              value={value}
              onChangeText={onChange}
              placeholder={t('your_mail')}
              returnKeyType="next"
              status={errors.username ? 'danger' : 'primary'}
              onBlur={onBlur}
              keyboardType="email-address"
              caption={errors.username?.message}
              textContentType={'username'}
              testID={'sign_in_username_input'}
              accessibilityLabel={'sign_in_username_input'}
              textStyle={styles.textStyle}
              autoCapitalize="none"
              blurOnSubmit={false}
              onSubmitEditing={() => passwordRef.current?.focus()}
            />
          )}
          name="username"
          rules={{
            required: { value: true, message: t('email_required') },
            pattern: {
              value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$/i,
              message: t('please_enter_email'),
            },
          }}
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              ref={passwordRef}
              label={t('password')}
              style={styles.input}
              value={value}
              onChangeText={onChange}
              placeholder={t('your_password')}
              returnKeyType="done"
              status={errors.password ? 'danger' : 'primary'}
              onBlur={onBlur}
              secureTextEntry={show}
              caption={errors.password?.message}
              textContentType={'password'}
              testID={'sign_in_password_input'}
              accessibilityLabel={'sign_in_password_input'}
              textStyle={styles.textStyle}
              autoCapitalize="none"
              onSubmitEditing={handleSubmit(handleSignIn)}
              accessoryRight={(props) => (
                <TouchableOpacity
                  style={{
                    height: 40,
                    width: 40,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  activeOpacity={0.7}
                  onPress={toggle}
                >
                  <Icon
                    {...props}
                    pack="assets"
                    name={show ? 'eyeOff' : 'eyeOn'}
                  />
                </TouchableOpacity>
              )}
            />
          )}
          name="password"
          rules={rulePassword}
        />
        <Button
          children={t('log_in')}
          style={[styles.button, { shadowColor: Configs.BUTTON_SHADOW_COLOR }]}
          onPress={handleSubmit(handleSignIn)}
          disabled={loading || isSubmitting}
          accessoryLeft={
            isSubmitting || loading
              ? () => (
                  <LoadingIndicator style={{ marginRight: 8 }} size="small" />
                )
              : undefined
          }
        />
        <TouchableOpacity
          style={styles.forgot}
          activeOpacity={0.7}
          onPress={() =>
            Configs.FORGOT_LINK && Linking.openURL(Configs.FORGOT_LINK)
          }
        >
          <Text category="c2" status={Configs.FORGOT_PASSWORD_TEXT_STATUS}>
            {t('forget_password')}
          </Text>
        </TouchableOpacity>
        <Text category="c1" style={styles.do_have_account} status="placeholder">
          {t('do_not_have_account')}
        </Text>
        <Button
          style={styles.button_sign_up}
          status="basic"
          children={t('sign_up')}
          onPress={onPressHandleSignUp}
        />
      </KeyboardAwareScrollView>
      <HideWithKeyboard
        style={[
          styles.bottom,
          {
            paddingBottom: bottom + 8,
            backgroundColor: theme['background-basic-color-1'],
          },
        ]}
      >
        <TouchableOpacity activeOpacity={0.7} onPress={handlePrivacyPolicy}>
          <Text category="c2" status="placeholder">
            {t('privacy_policy')}
          </Text>
        </TouchableOpacity>
      </HideWithKeyboard>
    </Container>
  );
};

export default SignIn;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
  },
  logo: {
    width: 120,
    height: 120,
    aspectRatio: 1 / 1,
    marginTop: 24,
  },
  evTrack: {
    width: 180,
    height: 48,
    marginTop: 96,
  },
  input: {
    marginTop: 24,
  },
  textStyle: {
    height: '100%',
  },
  button: {
    marginTop: 24,
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 1,
    shadowRadius: 20,
    elevation: 24,
  },
  button_sign_up: {
    flex: 1,
    marginTop: 24,
    marginBottom: 24,
  },
  forgot: {
    marginTop: 24,
    alignSelf: 'center',
  },
  do_have_account: {
    marginTop: 24,
    alignSelf: 'center',
  },
  imageView: {
    aspectRatio: 1 / 1.22,
    width: 222,
    position: 'absolute',
    right: 0,
  },
  image: {
    width: '100%',
    height: '100%',
  },
  bottom: {
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    paddingTop: 8,
  },
});
