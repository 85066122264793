import React from 'react';
import { View, TouchableOpacity, StyleSheet, ViewStyle } from 'react-native';
import { Icon, Input, InputProps } from '@ui-kitten/components';

interface InputSelectProps extends InputProps {
  iconStyle?: ViewStyle;
  onPress?(): void;
}

const InputSelect: React.FC<InputSelectProps> = ({
  onPress,
  iconStyle,
  disabled,
  style,
  ...props
}) => {
  return (
    <TouchableOpacity
      disabled={disabled}
      activeOpacity={0.7}
      style={style}
      onPress={onPress}
    >
      <View style={styles.container} />
      <Input
        {...props}
        style={styles.input}
        disabled={disabled}
        // @ts-ignore
        accessoryRight={
          props.accessoryRight
            ? () => props.accessoryRight
            : () => (
                <Icon
                  name="caret-down"
                  pack="assets"
                  style={[styles.icon, iconStyle]}
                />
              )
        }
      />
    </TouchableOpacity>
  );
};

export default InputSelect;

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 10,
  },
  input: {
    backgroundColor: 'transparent',
  },
  icon: {
    width: 16,
    height: 16,
  },
});
