import React from 'react';
import { LogBox, View } from 'react-native';
import * as SplashScreen from 'expo-splash-screen';
import { enableScreens } from 'react-native-screens';
import { NavigationContainer, NavigationState } from '@react-navigation/native';
import { useAppDispatch } from 'store/store';
import { useTheme } from '@ui-kitten/components';
import { useAuth } from 'hooks';

import AuthNavigator from './AuthNavigator';
import DrawerNavigator from './DrawerNavigator';

import Loading from './Loading';
import NotFoundScreen from 'screens/NotFoundScreen';
import LinkingConfiguration from './LinkingConfiguration';

import { RootStackParamList } from './types';
import { navigationRef } from './RootNavigation';
import { setCurrentRoute } from 'store/slices/appSlice';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

enableScreens();

interface AppContainerProps {
  cachedResources: boolean;
}

LogBox.ignoreAllLogs();

const Stack = createNativeStackNavigator<RootStackParamList>();

const AppContainer: React.FC<AppContainerProps> = ({ cachedResources }) => {
  const themes = useTheme();
  const dispatch = useAppDispatch();
  const { isInitializing, isAuthenticated } = useAuth();

  React.useEffect(() => {
    const stopAutoHideSplash = async () => {
      try {
        await SplashScreen.preventAutoHideAsync();
      } catch (e) {
        console.warn(e);
      }
    };
    stopAutoHideSplash();
  }, []);

  const hiddenLoading = cachedResources && !isInitializing;

  if (!hiddenLoading) {
    return <Loading />;
  }

  const getCurrentRoute = (
    state: NavigationState | Required<NavigationState['routes'][0]>['state'],
  ): any | undefined => {
    if (state.index === undefined || state.index < 0) {
      return undefined;
    }
    const nestedState = state.routes[state.index].state;
    if (nestedState !== undefined) {
      return getCurrentRoute(nestedState);
    }
    return state.routes[state.index].name;
  };

  const onStateChange = (state: NavigationState | undefined) => {
    if (state === undefined) {
      dispatch(setCurrentRoute(undefined));
    } else {
      dispatch(setCurrentRoute(getCurrentRoute(state)));
    }
  };

  return (
    <NavigationContainer
      onStateChange={onStateChange}
      ref={navigationRef}
      linking={LinkingConfiguration}
    >
      <View
        style={{ backgroundColor: themes['background-basic-color-1'], flex: 1 }}
      >
        <Stack.Navigator
          initialRouteName={isAuthenticated ? 'Drawer' : 'Auth'}
          screenOptions={{
            headerShown: false,
          }}
        >
          <Stack.Screen name="Auth" component={AuthNavigator} />
          <Stack.Screen
            name="Drawer"
            component={DrawerNavigator}
            options={{ headerShown: false }}
          />
          <Stack.Screen name="NotFound" component={NotFoundScreen} />
        </Stack.Navigator>
      </View>
    </NavigationContainer>
  );
};

export default AppContainer;
