export const rulePassword = {
  required: { value: true, message: 'Password is required.' },
  pattern: {
    value: /^.{8,30}$/,
    message:
      'Password must be at least one lowercase letter, a number and no contain white spaces.',
  },
  minLength: {
    value: 8,
    message: 'Password must be at least 8 characters.',
  },
  maxLength: {
    value: 30,
    message: 'Password must be a maximum length of 30 characters.',
  },
};

export const ruleNewPassword = {
  required: { value: true, message: 'New Password is required.' },
  pattern: {
    value: /^(?=.*[A-Z])(?=.*\d)\S{8,30}$/,
    message:
      'Password must be at least one uppercase letter, a number and no contain white spaces.',
  },
  minLength: {
    value: 8,
    message: 'Password must be at least 8 characters.',
  },
  maxLength: {
    value: 30,
    message: 'Password must be a maximum length of 30 characters.',
  },
};

export const RuleName = {
  require: { value: true, message: '' },
  pattern: {
    value: /^[a-zA-Z]{2,40}( [a-zA-Z]{2,40})+$/,
    message: '',
  },
};

export const ruleConfirmPassword = {
  required: { value: true, message: 'Confirm Password is required.' },
  minLength: {
    value: 8,
    message: 'Confirm password must be at least 8 characters.',
  },
  maxLength: {
    value: 30,
    message: 'Confirm Password must be a maximum length of 30 characters.',
  },
};
export const ruleEmail = {
  pattern: {
    value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
    message: 'Please enter a valid email address',
  },
};

export const ruleCountersign = {
  required: { value: true, message: 'Password is required.' },
  minLength: {
    value: 8,
    message: 'Password must be at least 8 characters.',
  },
  maxLength: {
    value: 30,
    message: 'Password must be a maximum length of 30 characters.',
  },
};
