import React from 'react';
import createStackNavigator from './createStackNavigator';
import { InvitesMainParamList } from './types';

import InvitationsScreen from 'screens/invitations/InvitationsScreen';
import InviteShare from 'screens/invitations/InviteShare';
import InviteNew from 'screens/invitations/InviteNew';

const Stack = createStackNavigator<InvitesMainParamList>();

const InvitesMainNavigator = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName="InvitationsScreen">
      <Stack.Screen name="InvitationsScreen" component={InvitationsScreen} />
      <Stack.Screen name="InviteNew" component={InviteNew} />
      <Stack.Screen name="InviteShare" component={InviteShare} />
    </Stack.Navigator>
  );
};

export default InvitesMainNavigator;
