import { ERequest, request } from 'utils/request';
import { EV_TRACK_ENDPOINT } from './api';
import { IDocument } from 'constants/types';

/**
 * List Documents
 */

type ListDocumentsResult = {
  userDocuments: IDocument[];
  siteDocuments: IDocument[];
};

export const requestListDocuments = async (): Promise<
  ListDocumentsResult | undefined
> => {
  const url = `${EV_TRACK_ENDPOINT}/documents`;

  const { data } = await request(ERequest.GET, url);

  return data;
};

/**
 * Get Document PreSigned Download URL
 */

type GetDocumentPreSignedDownloadURLPayload = {
  uuid: string;
};

type GetDocumentPreSignedDownloadURLResult = {
  filename: string;
  url: string;
  uuid: string;
};

export const requestGetDocumentPreSignedDownloadURL = async (
  payload: GetDocumentPreSignedDownloadURLPayload,
): Promise<GetDocumentPreSignedDownloadURLResult | undefined> => {
  const url = `${EV_TRACK_ENDPOINT}/documents/pre-signed/uuid/${payload.uuid}`;

  const { data } = await request(ERequest.GET, url, payload);

  return data;
};
