import { IEvent, IBooking, IService } from 'constants/types';
import { ERequest, request } from 'utils/request';
import { EV_TRACK_ENDPOINT } from './api';

/**
 * List All Site Services Listings
 */

export const requestListAllSiteServiceListings = async (): Promise<
  IService[] | undefined
> => {
  const url = `${EV_TRACK_ENDPOINT}/site-services`;

  const { data } = await request(ERequest.GET, url);

  return data;
};

/**
 * List All Site Booking Listings
 */

export const requestListAllSiteBookingListings = async (): Promise<
  IBooking[] | undefined
> => {
  const url = `${EV_TRACK_ENDPOINT}/site-bookings`;

  const { data } = await request(ERequest.GET, url);

  return data;
};

/**
 * List User Events
 */

type ListUserEventsPayload = {
  start?: number;
  limit?: number;
  from: string;
  until: string;
};

export const requestListUserEvents = async ({
  start = 0,
  limit = 500,
  from,
  until,
}: ListUserEventsPayload): Promise<IEvent | undefined> => {
  const url = `${EV_TRACK_ENDPOINT}/events`;

  const { data } = await request(ERequest.GET, url, {
    start,
    limit,
    from,
    until,
  });

  return data;
};
