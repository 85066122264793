import React from 'react';
import {
    Image,
    Platform,
    StyleSheet,
    TouchableOpacity,
    View,
} from 'react-native';
import {
    Action,
    Container,
    Content,
    ModalConfirm,
    ModalReason,
    NavigationAction,
    ServiceItem,
    Text,
    UserItem,
} from 'components';
import QRCode from 'react-qr-code';
import {Button, Icon, Layout, TopNavigation, useTheme} from '@ui-kitten/components';
import {
    NavigationProp,
    useFocusEffect,
    useNavigation,
} from '@react-navigation/native';
import {useAuth, useDrawer, useLayout, useModal} from 'hooks';
import {useAppDispatch, useAppSelector} from 'store/store';
import {useTranslation} from 'react-i18next';
import AsyncStorage, {
    useAsyncStorage,
} from '@react-native-async-storage/async-storage';

import dayjs from 'dayjs';
import Configs from 'configs';
import {isEmpty} from 'lodash';
import {
    EKeyAsyncStorage,
    IAction,
    ICreateInvite,
    IEmergencyNumber,
    IQrCodeMobile,
    IService,
    IShare,
    IVisitReason,
    MobileAppOptions,
} from 'constants/types';
import {MainStackParamList} from 'navigation/types';
import {requestSelector} from 'store/slices/requestSlide';
import {requestCreateNewInvite} from 'api/invite';
import {getAllInvites} from 'store/slices/inviteSlice';
import {setAppLoading} from 'store/slices/appSlice';
// import { getAllVisitors } from 'store/slices/visitorSlice';
import {documentSelector} from 'store/slices/documentSlice';
import {
    credentialSelector,
    getQrCodeObservable,
} from 'store/slices/credentialSlice';
//import { getListPending } from 'store/slices/processSlide';
import {doSimulateApprovalRequest} from 'api/visitor';

import * as ImageCache from 'react-native-expo-image-cache';
import {notificationSelector} from 'store/slices/notificationSlice';
import {Toast} from 'react-native-toast-notifications';
import * as Notifications from 'expo-notifications';

const HomeScreen: React.FC = () => {
    const {openDrawer} = useDrawer();
    const dispatch = useAppDispatch();
    const navigation = useNavigation();
    const theme = useTheme();
    const activeDate = dayjs().format();
    const {bottom, height, width} = useLayout();
    const {t} = useTranslation(['common', 'home']);
    const {visible, show, hide} = useModal();
    const {
        visible: modalRefConfirm,
        show: showConfirm,
        hide: hideConfirm,
    } = useModal();
    const {navigate} = useNavigation<NavigationProp<MainStackParamList>>();
    const {services, bookings} = useAppSelector(requestSelector);
    const {notifications_unread} = useAppSelector(notificationSelector);
    const {userDocuments, siteDocuments} = useAppSelector(documentSelector);
    const {getItem} = useAsyncStorage(EKeyAsyncStorage.emergencyNumbers);
    const {getItem: getUser} = useAsyncStorage(EKeyAsyncStorage.user);
    const {qrCode: qrCodeSlice} = useAppSelector(credentialSelector);

    const [user, setUser] = React.useState<MobileAppOptions>();
    const [qrCodeData, setQRCodeData] = React.useState<
        IQrCodeMobile | undefined
    >();

    const [emergencyNumbers, setEmergencyNumbers] = React.useState<
        IEmergencyNumber[]
    >([]);
    const [reason, setReason] = React.useState<IVisitReason | undefined>(
        undefined,
    );
    const [expiryDate, setExpiryDate] = React.useState<string>(dayjs().format());

    const isHideHomeSide =
        isEmpty(bookings) &&
        isEmpty(userDocuments) &&
        isEmpty(siteDocuments) &&
        isEmpty(emergencyNumbers)
        && !user?.enableActivityHistory
    ;

    React.useEffect(() => {
        // on focus getQrCodeObservable();
        return navigation.addListener('focus', () => {
            dispatch(getQrCodeObservable({force: false}));
        });
    }, [navigation]);

    React.useEffect(() => {
        setQRCodeData(qrCodeSlice);
    }, [qrCodeSlice]);

    useFocusEffect(() => {
        // Schedule Refresh
        const qrCodeRefreshInterval = setInterval(refreshQRCode, refreshQrCodeTime);
        return () => clearInterval(qrCodeRefreshInterval);
    });
    const refreshQrCodeTime = 30 * 1000;
    const refreshQRCode = async () => {
        // only if we have a previous qrCode try and refresh
        AsyncStorage.getItem(EKeyAsyncStorage.qrCode).then((qrCode) => {
            if (qrCode) {
                dispatch(getQrCodeObservable({force: false}));
            }
        });
    };

    React.useEffect(() => {
        (async () => {
            const numbers = await getItem();
            const _user = await getUser();
            if (numbers) {
                setEmergencyNumbers(JSON.parse(numbers));
            }
            if (_user) {
                setUser(JSON.parse(_user));
            }
        })();
    }, []);

    React.useEffect(() => {
        if (user) {
            const epoch: number = new Date(activeDate).getSeconds();
            const expiryDate: Date = new Date(activeDate);
            const maxExpiryDate: Date = new Date(activeDate);
            expiryDate.setSeconds(epoch + Number(user.defaultDurationOfStaySecs));
            maxExpiryDate.setSeconds(epoch + Number(user.maxDurationOfStaySecs));
            const suggestedExpiryDate = expiryDate.toISOString();
            setExpiryDate(suggestedExpiryDate);
        }
    }, [user, activeDate]);

    // removed by JH, to many calls to server
    // React.useEffect(() => {
    //   const unsubscribe = navigation.addListener('focus', () => {
    //     dispatch(getListPending({ refresh: true }));
    //   });
    //
    //   return unsubscribe;
    // }, [navigation]);

    Notifications.setNotificationHandler({
        handleNotification: async () => ({
            shouldShowAlert: true,
            shouldPlaySound: false,
            shouldSetBadge: false,
        }),
    });

    async function schedulePushNotification() {
        await doSimulateApprovalRequest();
        Notifications.scheduleNotificationAsync({
            content: {
                title: "Approve Simulation Title",
                body: 'Approve Simulation Body',
                data: {
                    data: 'Approve Simulation Extra DATA',
                    action: 'approve'
                },
            },
            trigger: {
                seconds: 1,
            },
        });
    }

    const visitorActions: IAction[] = [
        {
            icon: 'person',
            title: t('home:quick_code'),
            status: 'yellow',
            enable: user?.enableQuickCode || false,
            onPress: () => {
                show();
            },
        },
        {
            icon: 'calendar',
            title: t('home:invitations'),
            status: Configs.INVITATIONS_BUTTON_STATUS,
            enable: true,
            onPress: () => navigate('Invitations', {screen: 'InvitationsScreen'}),
        },
        {
            icon: 'add-child',
            title: t('home:new'),
            status: 'green',
            enable: true,
            onPress: () =>
                navigate('Visitors', {
                    screen: 'VisitorNewUpdate',
                    params: {id: undefined},
                }),
        },
        {
            icon: 'people',
            title: t('home:visitors'),
            status: 'orange',
            enable: true,
            onPress: () => navigate('Visitors', {screen: 'VisitorsScreen'}),
        },
    ];

    const siteActions: IAction[] = [
        {
            icon: 'wishlist',
            title: t('home:bookings'),
            status: 'basic',
            onPress: () => navigate('Home', {screen: 'BookingsScreen'}),
        },
        {
            icon: 'my-post',
            title: t('home:documents'),
            status: 'basic',
            onPress: () => navigate('Home', {screen: 'DocumentsScreen'}),
        },
        {
            icon: 'activity',
            title: t('home:activity'),
            status: 'basic',
            onPress: () => navigate('Home', {screen: 'EventsScreen'}),
        },
        {
            icon: 'phone',
            title: t('home:emergency'),
            status: 'red',
            onPress: () => navigate('Home', {screen: 'EmergencyNumbersScreen'}),
        },
    ];

    const handleInvite = React.useCallback(async () => {
        try {
            hideConfirm();
            dispatch(setAppLoading(true));
            const invite: ICreateInvite = {
                visitorId: null,
                activeDate: dayjs(activeDate).format(),
                expiryDate: dayjs(expiryDate).format(),
                visitReasonId: reason?.id || 0,
            };

            const result = await requestCreateNewInvite(invite);

            if (!!result && result.code === '200') {
                const share: IShare = {
                    uuid: result.uuid,
                    activeDate: result.activeDate,
                    expiryDate: result.expiryDate,
                    id: result.id,
                    firstName: result.firstName,
                    lastName: result.lastName,
                    mobileNumber: '',
                    message: result.message,
                };

                dispatch(getAllInvites(true));
                navigate('Invitations', {screen: 'InviteShare', params: {share}});
            } else {
                Toast.show(
                    'Unable to generate the quick code. Please try again later.',
                    {type: 'danger'},
                );
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    }, [activeDate, expiryDate, reason]);

    return (
        <Container level="2">
            <TopNavigation
                style={styles.topNavigation}
                //appearance="control"
                // title={() => (
                //   <Image
                //     source={{ uri: Configs.APP_LOGO }}
                //     resizeMode="contain"
                //     style={styles.logo}
                //   />
                // )}
                accessoryLeft={
                    <View style={styles.header}>
                        <NavigationAction icon="menu" status="basic" onPress={openDrawer}/>
                        <UserItem/>
                    </View>
                }
                accessoryRight={
                    <TouchableOpacity
                        activeOpacity={0.7}
                        onPress={() => navigate('Home', {screen: 'Notification'})}
                    >
                        <Icon
                            pack="assets"
                            name="notification"
                            style={{
                                tintColor: theme['icon-basic-color'],
                                width: 24,
                                height: 24,
                            }}
                        />
                        {notifications_unread > 0 && (
                            <View
                                style={[
                                    styles.box1,
                                    {backgroundColor: theme['color-danger-500']},
                                ]}
                            >
                                <Text category="c4" status="white">
                                    {notifications_unread}
                                </Text>
                            </View>
                        )}
                    </TouchableOpacity>
                }
            />
            <Content
                level="2"
                contentContainerStyle={[
                    {
                        paddingBottom: Platform.OS === 'ios' ? bottom + 58 : bottom + 72,
                        paddingHorizontal: 16,
                        paddingTop: 12,
                    },
                ]}
            >
                {/* <Text category="h5-l" marginBottom={16}>
          {t('home:hi')}
          <Text category="h5" marginTop={24}>
            {user?.name} {user?.surname}
          </Text>
        </Text> */}
                {qrCodeData ? (
                    <View style={{marginBottom: 8}}>
                        <View style={[styles.viewBanner, {height: height * 0.13}]}>
                            {user?.siteBanner &&
                                (Platform.OS === 'web' ? (
                                    <Image
                                        source={{uri: user.siteBanner}}
                                        style={styles.image}
                                    />
                                ) : (
                                    <ImageCache.Image
                                        uri={user.siteBanner}
                                        style={styles.image}
                                    />
                                ))}
                        </View>
                        <View
                            style={[
                                styles.qrCodeView,
                                {
                                    width: width - 57 * 2,
                                    maxWidth: 300,
                                    paddingHorizontal: 32,
                                    paddingVertical: 40,
                                    backgroundColor: theme['color-basic-100'],
                                },
                            ]}
                        >
                            <QRCode
                                style={{
                                    height: 'auto',
                                    maxWidth: '100%',
                                    width: '100%',
                                }}
                                level="Q"
                                value={qrCodeData.uuid}
                            />
                            <Text
                                category="b5"
                                marginTop={16}
                                status="black"
                                center
                                style={{position: 'absolute', bottom: 4}}
                            >
                                {t('home:scan_qr_for_entry')}
                            </Text>
                        </View>
                    </View>
                ) : (
                    <View
                        style={[
                            styles.viewBanner,
                            {height: height * 0.21, marginBottom: 24},
                        ]}
                    >
                        {user?.siteBanner &&
                            (Platform.OS === 'web' ? (
                                <Image source={{uri: user.siteBanner}} style={styles.image}/>
                            ) : (
                                <ImageCache.Image uri={user.siteBanner} style={styles.image}/>
                            ))}
                    </View>
                )}
                {user?.enableVisitorShortcuts && (
                    <Layout style={[styles.box, {marginTop: 0}]}>
                        <Text category="b2">{t('common:visitors')}</Text>
                        <View style={styles.viewActions}>
                            {visitorActions.map((item, key) => {
                                return (
                                    item.enable && (
                                        <Action appearance="control" item={item} key={key}/>
                                    )
                                );
                            })}
                        </View>
                    </Layout>
                )}
                {!isHideHomeSide && (
                    <Layout style={[styles.box]}>
                        <Text category="b2">{t('home:site')}</Text>
                        <View style={styles.viewActions}>
                            {!isEmpty(bookings) && <Action item={siteActions[0]}/>}
                            {(!isEmpty(userDocuments) || !isEmpty(siteDocuments)) && (
                                <Action item={siteActions[1]}/>
                            )}
                            {user?.enableActivityHistory && <Action item={siteActions[2]}/>}
                            {!isEmpty(emergencyNumbers) && <Action item={siteActions[3]}/>}
                        </View>
                    </Layout>
                )}
                {!isEmpty(services) && (
                    <Layout style={styles.box}>
                        <View style={styles.row}>
                            <Text category="b2">{t('home:discover')}</Text>
                            <TouchableOpacity
                                activeOpacity={0.7}
                                style={styles.seeAll}
                                onPress={() => navigate('Home', {screen: 'ServicesScreen'})}
                            >
                                <Text right category="b5" status={Configs.SEE_ALL_TEXT_STATUS}>
                                    {t('common:see_all')}
                                </Text>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.content}>
                            {services.map((item: IService, index: number) => {
                                return index < 6 && <ServiceItem item={item} key={index}/>;
                            })}
                        </View>
                        {/*<Button*/}
                        {/*    title="Press to schedule a notification"*/}
                        {/*    onPress={async () => {*/}
                        {/*        //await schedulePushNotification();*/}
                        {/*        // server will sleep for 5000ms before sending push notification*/}
                        {/*        doSimulateApprovalRequest();*/}
                        {/*    }}*/}
                        {/*/>*/}
                    </Layout>
                )}
            </Content>
            <ModalReason
                visible={visible}
                onBackdropPress={hide}
                onPress={(e) => {
                    setReason(e);
                    setTimeout(() => {
                        showConfirm();
                    }, 200);
                }}
            />
            {!!reason && (
                <ModalConfirm
                    visible={modalRefConfirm}
                    onBackdropPress={hideConfirm}
                    title={t('common:confirm')}
                    description={t('home:generate_code_for', {reason: reason?.reason})}
                    buttonLeft={{onPress: hideConfirm, title: t('common:cancel')}}
                    buttonRight={{onPress: handleInvite, title: t('home:generate')}}
                />
            )}
        </Container>
    );
};

export default HomeScreen;

const styles = StyleSheet.create({
    item: {
        marginBottom: 24,
    },
    viewBanner: {
        width: '100%',
        height: 170,
        borderRadius: 16,
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        width: '100%',
        height: '100%',
    },
    logo: {
        width: 92,
        height: 40,
    },
    box: {
        padding: 16,
        borderRadius: 16,
        shadowColor: 'rgba(0, 64, 128, 1)',
        shadowOffset: {
            width: 0,
            height: 10,
        },
        shadowOpacity: 0.1,
        shadowRadius: 10,
        elevation: 5,
        marginTop: 24,
    },
    viewActions: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 12,
        marginHorizontal: -8,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    content: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginHorizontal: -8,
        marginBottom: -16,
    },
    seeAll: {
        height: '100%',
        width: '20%',
        marginBottom: 12,
    },
    qrCodeView: {
        //justifyContent: 'flex-end',
        justifyContent: 'center',
        alignItems: 'center',
        aspectRatio: 1 / 1,
        alignSelf: 'center',
        borderRadius: 12,
        marginTop: 12,
        shadowColor: 'rgba(0, 64, 128, 1)',
        shadowOffset: {
            width: 0,
            height: 10,
        },
        shadowOpacity: 0.1,
        shadowRadius: 10,
        elevation: 5,
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    topNavigation: {
        marginTop: 12,
    },
    box1: {
        position: 'absolute',
        width: 20,
        height: 20,
        borderRadius: 20,
        justifyContent: 'center',
        alignItems: 'center',
        top: -5,
        right: -5,
    },
});
