import React from 'react';
import { View, StyleSheet, TouchableOpacity, ViewStyle } from 'react-native';
import { useTheme } from '@ui-kitten/components';

import Text from './Text';
import Shape from './Shape';

import { IEmergencyNumber } from 'constants/types';
import { parsePhoneNumber } from 'libphonenumber-js';

interface EmergencyItemProps {
  style?: ViewStyle;
  item: IEmergencyNumber;
  onPress?(): void;
}

const EmergencyItem: React.FC<EmergencyItemProps> = ({
  style,
  item,
  onPress,
}) => {
  const theme = useTheme();
  const { telephoneNumber, name } = item;

  const mobileNumber =
    typeof telephoneNumber === 'string'
      ? parsePhoneNumber(telephoneNumber)?.format('INTERNATIONAL')
      : '';

  return (
    <TouchableOpacity
      onPress={onPress}
      activeOpacity={0.7}
      style={[
        styles.container,
        { backgroundColor: theme['background-basic-color-1'] },
        style,
      ]}
    >
      <View>
        <Text marginBottom={8} category="t3">
          {name}
        </Text>
        <Text category="c2">{mobileNumber}</Text>
      </View>
      <Shape icon="call" status="green" size="medium" />
    </TouchableOpacity>
  );
};

export default EmergencyItem;

const styles = StyleSheet.create({
  container: {
    borderRadius: 16,
    padding: 16,
    paddingRight: 24,
    flexDirection: 'row',
    justifyContent: 'space-between',
    shadowColor: 'rgba(29, 30, 44, 0.3)',
    shadowOffset: {
      width: 2,
      height: 4,
    },
    shadowOpacity: 0.4,
    shadowRadius: 5.0,
    elevation: 2,
  },
});
