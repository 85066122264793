import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../rootReducer';
import {AppThunk} from 'store/store';

import {IDocument} from 'constants/types';
import {requestListDocuments} from 'api/document';

export interface IDocumentState {
    userDocuments: IDocument[];
    siteDocuments: IDocument[];
}

export const initialState: IDocumentState = {
    userDocuments: [],
    siteDocuments: [],
};

export const documentSlice = createSlice({
    name: 'document',
    initialState,
    reducers: {
        setDocuments: (
            state: IDocumentState,
            {payload}: PayloadAction<IDocumentState>,
        ) => {

            if (payload != null) {
                // null check server response for NPE, replace null with empty list
                state.userDocuments = payload.userDocuments != null ? payload.userDocuments : [];
                state.siteDocuments = payload.siteDocuments != null ? payload.siteDocuments : [];
            }
        },
    },
});

export const {setDocuments} = documentSlice.actions;

export const documentSelector = (state: RootState) => state.document;

export const getListDocuments = (): AppThunk => async (dispatch) => {
    const result = await requestListDocuments();

    if (result) {
        dispatch(setDocuments(result));
    }
};

export default documentSlice.reducer;
