import React from 'react';
import { View, StyleSheet, ViewStyle, Platform, Image } from 'react-native';
import { useTheme } from '@ui-kitten/components';

import Text from './Text';
//@ts-ignore
import UserAvatar from 'react-native-user-avatar';
import * as ImageCache from 'react-native-expo-image-cache';
import { EKeyAsyncStorage, MobileAppOptions } from 'constants/types';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';

interface UserItemProps {
  style?: ViewStyle;
  isShow?: boolean;
  marginLeft?: number;
}

const UserItem: React.FC<UserItemProps> = ({ marginLeft, isShow = true }) => {
  const theme = useTheme();
  const { getItem: getUser } = useAsyncStorage(EKeyAsyncStorage.user);

  const [user, setUser] = React.useState<MobileAppOptions>();

  React.useEffect(() => {
    (async () => {
      const _user = await getUser();

      if (_user) {
        setUser(JSON.parse(_user));
      }
    })();
  }, []);

  return (
    <View
      style={[
        styles.container,
        {
          marginLeft,
        },
      ]}
    >
      {user?.thumbnail ? (
        Platform.OS === 'web' ? (
          <Image source={{ uri: user.thumbnail }} style={styles.avatar} />
        ) : (
          <ImageCache.Image uri={user.thumbnail} style={styles.avatar} />
        )
      ) : (
        <UserAvatar
          size={28}
          style={styles.avatar}
          src=""
          name={`${user?.name} ${user?.surname}`}
          colors={[
            theme['color-primary-500'],
            theme['color-info-500'],
            theme['color-warning-500'],
            theme['color-danger-500'],
            theme['background-basic-color-6'],
            theme['background-basic-color-7'],
            theme['background-basic-color-8'],
            theme['background-basic-color-13'],
            theme['background-basic-color-14'],
          ]}
        />
      )}
      {isShow && (
        <View>
          <Text category="c3-b">
            {user?.name} {user?.surname}
          </Text>
          <Text category="c5">{user?.siteName}</Text>
        </View>
      )}
    </View>
  );
};

export default UserItem;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    width: 28,
    height: 28,
    borderRadius: 10,
    marginRight: 4,
  },
});
