import React from 'react';
import { View, StyleSheet, ViewStyle } from 'react-native';
import { Icon } from '@ui-kitten/components';

import Text from './Text';

interface CheckItemProps {
  style?: ViewStyle;
  title: string;
  is_check: boolean;
}

const CheckItem: React.FC<CheckItemProps> = ({
  style,
  title,
  is_check = false,
}) => {
  return (
    <View style={[styles.container, style]}>
      <Icon
        pack="assets"
        name={is_check ? 'correct' : 'un-correct'}
        style={styles.icon}
      />
      <Text category="c3">{title}</Text>
    </View>
  );
};

export default CheckItem;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    width: 12,
    height: 12,
    marginRight: 8,
  },
});
