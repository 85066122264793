import * as React from 'react';
import Svg, { SvgProps, Ellipse, Circle, Path } from 'react-native-svg';
import { memo } from 'react';

const SvgComponent = (props: SvgProps) => (
  <Svg width={151.801} height={158.844} {...props}>
    <Ellipse
      data-name="Elipse 131"
      cx={58.722}
      cy={10.374}
      rx={58.722}
      ry={10.374}
      transform="translate(0 138.097)"
      fill="#3f3d56"
    />
    <Circle
      data-name="Elipse 132"
      cx={57.39}
      cy={57.39}
      r={57.39}
      transform="translate(37.022)"
      fill="#3f3d56"
    />
    <Circle
      data-name="Elipse 133"
      cx={47.377}
      cy={47.377}
      r={47.377}
      transform="translate(47.035 10.013)"
      opacity={0.05}
    />
    <Circle
      data-name="Elipse 134"
      cx={38.83}
      cy={38.83}
      r={38.83}
      transform="translate(55.582 18.56)"
      opacity={0.05}
    />
    <Circle
      data-name="Elipse 135"
      cx={27.84}
      cy={27.84}
      r={27.84}
      transform="translate(66.572 29.549)"
      fill="#1d1d1b"
      opacity={0.05}
    />
    <Path
      data-name="Trazado 932"
      d="M37.531 62.525s-4.512 12.7-2.507 17.212a56.025 56.025 0 0 0 5.18 8.857s-1.169-25.233-2.673-26.069Z"
      fill="#d0cde1"
    />
    <Path
      data-name="Trazado 933"
      d="M37.531 62.525s-4.512 12.7-2.507 17.212a56.025 56.025 0 0 0 5.18 8.857s-1.169-25.233-2.673-26.069Z"
      opacity={0.1}
    />
    <Path
      data-name="Trazado 934"
      d="M40.706 91.937a27.086 27.086 0 0 1-.5 3.342c-.167.167.167.5 0 1s-.334 1.17 0 1.337-1.836 14.876-1.836 14.876-5.348 7.019-3.175 18.048l.668 11.2s5.18.334 5.18-1.5a30.617 30.617 0 0 1-.334-3.175c0-1 .836-1 .334-1.5s-.5-.836-.5-.836.836-.668.668-.836 1.5-12.032 1.5-12.032 1.838-1.838 1.838-2.841v-1s.836-2.172.836-2.339 4.512-10.361 4.512-10.361l1.838 7.353 2.005 10.528s1 9.525 3.008 13.2c0 0 3.509 12.032 3.509 11.7s5.849-1.17 5.682-2.674-3.509-22.56-3.509-22.56l.836-31.25Z"
      fill="#2f2e41"
    />
    <Path
      data-name="Trazado 935"
      d="M36.192 140.9s-4.512 8.857-1.5 9.191 4.178.334 5.515-1a22.253 22.253 0 0 1 3.4-2.45 4.4 4.4 0 0 0 2.085-4.189c-.088-.818-.394-1.491-1.146-1.554a10.264 10.264 0 0 1-4.345-2.005Z"
      fill="#2f2e41"
    />
    <Path
      data-name="Trazado 936"
      d="M61.094 147.417s-4.512 8.857-1.5 9.191 4.178.334 5.515-1a22.244 22.244 0 0 1 3.4-2.45 4.4 4.4 0 0 0 2.088-4.184c-.088-.818-.394-1.491-1.146-1.554a10.265 10.265 0 0 1-4.345-2.005Z"
      fill="#2f2e41"
    />
    <Circle
      data-name="Elipse 136"
      cx={7.025}
      cy={7.025}
      r={7.025}
      transform="translate(49.3 33.982)"
      fill="#ffb8b8"
    />
    <Path
      data-name="Trazado 937"
      d="M51.809 43.315s-5.018 9.233-5.419 9.233 9.032 3.01 9.032 3.01 2.609-8.831 3.011-9.634Z"
      fill="#ffb8b8"
    />
    <Path
      data-name="Trazado 938"
      d="M59.507 53.418S49.48 47.903 48.478 48.07s-11.7 9.525-11.531 13.369a82.6 82.6 0 0 0 1.5 10.194s.5 17.714 1.5 17.881-.167 3.175.167 3.175 23.4 0 23.562-.5-4.169-38.771-4.169-38.771Z"
      fill="#d0cde1"
    />
    <Path
      data-name="Trazado 939"
      d="M65.104 93.107s3.175 9.692.5 9.358-3.844-8.356-3.844-8.356Z"
      fill="#ffb8b8"
    />
    <Path
      data-name="Trazado 940"
      d="M56.58 52.833s-6.183 1.337-5.18 9.692 2.841 16.711 2.841 16.711l6.183 13.536.668 2.507 4.512-1.17-3.342-19.385s-1.17-20.722-2.674-21.39a6.472 6.472 0 0 0-3.008-.501Z"
      fill="#d0cde1"
    />
    <Path data-name="Trazado 941" d="m52.821 78.986 7.687 13.7-6.477-14.439Z" opacity={0.1} />
    <Path
      data-name="Trazado 942"
      d="m63.318 38.939.023-.537 1.068.266a1.193 1.193 0 0 0-.478-.878l1.137-.064a12.273 12.273 0 0 0-8.209-5.073c-2.46-.357-5.2.159-6.887 1.985a8.3 8.3 0 0 0-1.7 3.161c-.674 2.116-.811 4.639.594 6.359 1.428 1.748 3.922 2.091 6.168 2.307a4.871 4.871 0 0 0 2.352-.16 5.658 5.658 0 0 0-.315-2.482 1.653 1.653 0 0 1-.167-.79c.1-.668.991-.837 1.661-.747s1.475.228 1.915-.284a2.274 2.274 0 0 0 .325-1.332c.112-1.259 2.501-1.467 2.513-1.731Z"
      fill="#2f2e41"
    />
    <Circle
      data-name="Elipse 137"
      cx={8.185}
      cy={8.185}
      r={8.185}
      transform="translate(98.219 133.528)"
      fill="#00a273"
    />
    <Circle
      data-name="Elipse 138"
      cx={8.185}
      cy={8.185}
      r={8.185}
      transform="translate(2.094 130.673)"
      fill="#00a273"
    />
    <Circle
      data-name="Elipse 139"
      cx={5.901}
      cy={5.901}
      r={5.901}
      transform="translate(4.378 122.107)"
      fill="#00a273"
    />
    <Circle
      data-name="Elipse 140"
      cx={4.188}
      cy={4.188}
      r={4.188}
      transform="translate(6.091 114.684)"
      fill="#00a273"
    />
  </Svg>
);

const SvgInvitations = memo(SvgComponent);
export default SvgInvitations;
