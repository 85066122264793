import React from 'react';
import { ImageBackground, StyleSheet, TouchableOpacity } from 'react-native';
import { useTheme, Icon } from '@ui-kitten/components';

import { Images } from 'assets/images';
import { ShapeProps } from 'constants/Types';

const Shape: React.FC<ShapeProps> = ({
  icon = 'back',
  size = 'medium',
  status = 'basic',
  style,
  onPress,
}) => {
  const theme = useTheme();

  const getSize = (
    size: 'giant' | 'large' | 'medium' | 'small' | 'tiny',
  ): number => {
    switch (size) {
      case 'giant':
        return 56;
      case 'large':
        return 48;
      case 'medium':
        return 40;
      case 'small':
        return 32;
      case 'tiny':
        return 24;
      default:
        return 40;
    }
  };
  const getSizeIcon = (
    size: 'giant' | 'large' | 'medium' | 'small' | 'tiny',
  ): number => {
    switch (size) {
      case 'giant':
        return 24;
      case 'large':
        return 20;
      case 'medium':
        return 20;
      case 'small':
        return 12;
      case 'tiny':
        return 12;
      default:
        return 24;
    }
  };
  const getColor = (
    status:
      | 'primary'
      | 'success'
      | 'green'
      | 'danger'
      | 'basic'
      | 'secondary'
      | 'dark',
  ): string => {
    switch (status) {
      case 'basic':
        return theme['background-basic-color-13'];
      case 'primary':
        return theme['color-primary-500'];
      case 'success':
        return theme['color-success-500'];
      case 'green':
        return theme['color-info-500'];
      case 'danger':
        return theme['color-danger-500'];
      case 'secondary':
        return theme['color-secondary-500'];
      case 'dark':
        return theme['background-basic-color-10'];
      default:
        return theme['background-basic-color-13'];
    }
  };
  return (
    <TouchableOpacity
      activeOpacity={0.7}
      disabled={!onPress}
      onPress={onPress}
      style={[styles.container, style]}
    >
      <ImageBackground
        source={Images.shape}
        imageStyle={{
          width: getSize(size),
          height: getSize(size),
          tintColor: getColor(status),
        }}
        style={[
          styles.item,
          {
            width: getSize(size),
            height: getSize(size),
          },
        ]}
      >
        <Icon
          pack="assets"
          name={icon ? icon : 'back'}
          style={[
            {
              width: getSizeIcon(size),
              height: getSizeIcon(size),
              tintColor:
                status === 'dark'
                  ? theme['background-basic-color-1']
                  : theme['color-basic-100'],
            },
          ]}
        />
      </ImageBackground>
    </TouchableOpacity>
  );
};

export default Shape;

const styles = StyleSheet.create({
  container: {},
  item: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});
