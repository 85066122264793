import { setAppLoading } from 'store/slices/appSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';
import {
  EKeyAsyncStorage,
  IBooking,
  IService,
  IEntry,
  EventType,
  FILTER_ENUM,
} from 'constants/types';
import { AppThunk } from 'store/store';
import {
  requestListAllSiteBookingListings,
  requestListAllSiteServiceListings,
  requestListUserEvents,
} from 'api/listings';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Configs from 'configs';
import dayjs from 'dayjs';
import { eventTypes } from 'constants/data';
import { CalendarRange } from '@ui-kitten/components';

export interface IRequestState {
  services: IService[];
  bookings: IBooking[];
  events: IEntry[];
  eventsUpdatedAt: number;
  eventType: EventType;
  range: CalendarRange<Date>;
}

export const initialState: IRequestState = {
  services: [],
  bookings: [],
  events: [],
  eventsUpdatedAt: 0,
  eventType: eventTypes[1],
  range: {
    startDate: dayjs().subtract(30, 'day').startOf('day').toDate(),
    endDate: dayjs().endOf('day').toDate(),
  },
};

export const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    setServices: (
      state: IRequestState,
      { payload }: PayloadAction<IService[]>,
    ) => {
      state.services = payload;
    },
    setBookings: (
      state: IRequestState,
      { payload }: PayloadAction<IBooking[]>,
    ) => {
      state.bookings = payload;
    },
    setEvents: (state: IRequestState, { payload }: PayloadAction<IEntry[]>) => {
      state.events = payload;
    },
    setEventType: (
      state: IRequestState,
      { payload }: PayloadAction<EventType>,
    ) => {
      state.eventType = payload;
    },
    setRange: (
      state: IRequestState,
      { payload }: PayloadAction<CalendarRange<Date>>,
    ) => {
      state.range = payload;
    },
  },
});

export const { setServices, setBookings, setEvents, setEventType, setRange } =
  requestSlice.actions;

export const getAllServices = (): AppThunk => async (dispatch) => {
  const result = await requestListAllSiteServiceListings();

  if (result) {
    dispatch(setServices(result));
  }
};

export const getAllBookings = (): AppThunk => async (dispatch) => {
  const result = await requestListAllSiteBookingListings();

  if (result) {
    dispatch(setBookings(result));
  }
};

export const getAllEvents =
  ({
    from,
    until,
    refresh = false,
    force = false,
  }: {
    from?: Date;
    until?: Date;
    refresh?: boolean;
    force?: boolean;
  }): AppThunk =>
  async (dispatch, getState) => {
    try {
      const formatDate = 'YYYY-MM-DD';
      const { eventsUpdatedAt, eventType } = requestSelector(getState());

      if (!force) {
        const eventsCache = await AsyncStorage.getItem(EKeyAsyncStorage.events);
        if (
          eventsCache &&
          dayjs().unix() < eventsUpdatedAt + Configs.CACHE_TTL_SECS_EVENT
        ) {
          const _eventsCache: IEntry[] = JSON.parse(eventsCache);

          dispatch(setEvents(_eventsCache));
          return;
        }
      }

      if (!refresh) {
        dispatch(setAppLoading(true));
      }

      let _payload: any = {};

      switch (eventType.type) {
        case FILTER_ENUM.TODAY:
          _payload.start = 0;
          _payload.limit = 500;
          _payload.from = dayjs().startOf('day').format(formatDate);
          _payload.until = dayjs().endOf('day').format(formatDate);
          break;
        case FILTER_ENUM.LAST_7_DAYS:
          _payload.start = 0;
          _payload.limit = 500;
          _payload.from = dayjs()
            .subtract(7, 'days')
            .startOf('day')
            .format(formatDate);
          _payload.until = dayjs().endOf('day').format(formatDate);
          break;
        case FILTER_ENUM.LAST_14_DAYS:
          _payload.start = 0;
          _payload.limit = 500;
          _payload.from = dayjs()
            .subtract(14, 'days')
            .startOf('day')
            .format(formatDate);
          _payload.until = dayjs().endOf('day').format(formatDate);
          break;
        case FILTER_ENUM.LAST_30_DAYS:
          _payload.start = 0;
          _payload.limit = 500;
          _payload.from = dayjs()
            .subtract(30, 'days')
            .startOf('day')
            .format(formatDate);
          _payload.until = dayjs().endOf('day').format(formatDate);
          break;
        case FILTER_ENUM.CUSTOM_DATE:
          _payload.start = 0;
          _payload.limit = 500;
          _payload.from = dayjs(from).startOf('day').format(formatDate);
          _payload.until = dayjs(until).endOf('day').format(formatDate);
          break;
        default:
          _payload.start = 0;
          _payload.limit = 500;
          _payload.from = dayjs()
            .subtract(7, 'days')
            .startOf('day')
            .format(formatDate);
          _payload.until = dayjs().endOf('day').format(formatDate);
          break;
      }

      const result = await requestListUserEvents(_payload).catch(() => {
        dispatch(setAppLoading(false));
      });

      if (result) {
        dispatch(setAppLoading(false));
        dispatch(setEvents(result.entries));

        await AsyncStorage.setItem(
          EKeyAsyncStorage.events,
          JSON.stringify(result.entries),
        );

        if (!refresh) {
          dispatch(setAppLoading(false));
        }
      }
    } catch (error) {
      dispatch(setAppLoading(false));
    }
  };

export const requestSelector = (state: RootState) => state.request;

export default requestSlice.reducer;
