import { EventType, FILTER_ENUM } from './types';

export const eventTypes: EventType[] = [
  {
    type: FILTER_ENUM.TODAY,
    title: 'Today',
  },
  {
    type: FILTER_ENUM.LAST_7_DAYS,
    title: 'Last 7 days',
  },
  {
    type: FILTER_ENUM.LAST_14_DAYS,
    title: 'Last 14 days',
  },
  {
    type: FILTER_ENUM.LAST_30_DAYS,
    title: 'Last 30 days',
  },
  {
    type: FILTER_ENUM.CUSTOM_DATE,
    title: 'Custom Date',
  },
];
