import React from 'react';
import { StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import { useTheme, Layout } from '@ui-kitten/components';
import { useLayout } from 'hooks';

import Text from './Text';

interface TopTabProps {
  style?: ViewStyle;
  disabled?: boolean;
  selectedIndex: number;
  onChange(index: number): void;
  heightBar?: number;
  tabs: Array<string> | Array<number>;
  appearance: 'control' | 'basic';
}

const TopTab: React.FC<TopTabProps> = ({
  style,
  selectedIndex,
  disabled,
  heightBar,
  appearance,
  onChange,
  tabs,
}) => {
  const theme = useTheme();
  const { width } = useLayout();

  const getColorBackground = () => {
    switch (appearance) {
      case 'basic':
        return theme['background-basic-color-1'];
      case 'control':
        return 'transparent';
      default:
        break;
    }
  };

  return (
    <Layout
      style={[
        styles.container,
        {
          height: heightBar ? heightBar : 28,
          width: width,
          backgroundColor: getColorBackground(),
        },
        style,
      ]}
    >
      {tabs.map((item, index) => {
        return (
          <TouchableOpacity
            key={index}
            activeOpacity={0.7}
            style={[styles.btn]}
            disabled={disabled}
            onPress={() => onChange(index)}
          >
            <Text
              capitalize
              category="c1"
              uppercase
              marginBottom={8}
              status={selectedIndex === index ? 'primary' : 'placeholder'}
            >
              {item}
            </Text>
            <View
              style={[
                styles.boxAni,
                { width: `${100 / tabs.length}%`, height: 2 },
              ]}
            >
              <Layout
                style={{
                  backgroundColor:
                    selectedIndex === index
                      ? theme['color-primary-500']
                      : 'transparent',
                  ...styles.sticky,
                }}
              />
            </View>
          </TouchableOpacity>
        );
      })}
      <Layout style={styles.divider} level="4" />
    </Layout>
  );
};

export default TopTab;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    overflow: 'visible',
    alignSelf: 'center',
  },
  boxAni: {
    position: 'absolute',
    bottom: 0,
  },
  btn: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  sticky: {
    height: 2,
    width: 32,
    alignSelf: 'center',
    borderRadius: 99,
  },
  divider: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: 1,
    bottom: 0,
  },
});
