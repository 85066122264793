import React from 'react';
import { View, StyleSheet, FlatList, Platform, Image } from 'react-native';
import {
  Container,
  Credential,
  ModalConfirm,
  NavigationAction,
  Text,
  UserItem,
} from 'components';
import { useTheme, Button, TopNavigation } from '@ui-kitten/components';
import {
  useRoute,
  useNavigation,
  RouteProp,
  NavigationProp,
} from '@react-navigation/native';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useTranslation } from 'react-i18next';
import { useAppTheme, useLayout, useModal } from 'hooks';

//@ts-ignore
import UserAvatar from 'react-native-user-avatar';
import * as ImageCache from 'react-native-expo-image-cache';

import keyExtractor from 'utils/keyExtractor';
import { MainStackParamList, VisitorsMainParamList } from 'navigation/types';
import {
  EKeyAsyncStorage,
  ICredential,
  IInvite,
  IShare,
  IVisitor,
} from 'constants/types';
import { deleteInviteByUUID } from 'store/slices/inviteSlice';
import { parsePhoneNumber } from 'libphonenumber-js';
import { setAppLoading } from 'store/slices/appSlice';
import { requestGetVisitorById } from 'api/visitor';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import {
  setIsLoadingVisitor,
  visitorSelector,
} from 'store/slices/visitorSlice';
import { Skeleton } from 'moti/skeleton';

type VisitorProfileRouteProp = RouteProp<
  VisitorsMainParamList,
  'VisitorProfile'
>;

type DataType = {
  title: string;
  description?: string;
};

const VisitorProfile: React.FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { bottom } = useLayout();
  const { theme: themes } = useAppTheme();
  const { visible, hide, show } = useModal();
  const { t } = useTranslation(['common']);
  const { isLoadingVisitor } = useAppSelector(visitorSelector);
  const navigation = useNavigation<NavigationProp<VisitorsMainParamList>>();
  const { navigate } = useNavigation<NavigationProp<MainStackParamList>>();

  const [credential, setCredential] = React.useState<ICredential>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [visitorProfile, setVisitorProfile] = React.useState<IInvite>();

  const { setItem, getItem } = useAsyncStorage(EKeyAsyncStorage.visitors);

  const {
    params: { id },
  } = useRoute<VisitorProfileRouteProp>();

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', async () => {
      dispatch(setIsLoadingVisitor(true));
      const visitorCache = await getItem();
      let _visitors: IInvite[] = [];
      let _visitor: any = {};

      if (visitorCache) {
        _visitors = JSON.parse(visitorCache);

        _visitor = _visitors.filter((i) => `${i.id}` === `${id}`);
      }

      if (visitorCache && _visitor) {
        setVisitorProfile(_visitor[0]);
        setTimeout(() => {
          dispatch(setIsLoadingVisitor(false));
        }, 1000);
      }
    });

    return unsubscribe;
  }, [navigation, id]);

  let mobileNumber = '';

  if (
    visitorProfile &&
    visitorProfile?.mobileNumber &&
    typeof visitorProfile.mobileNumber === 'string' &&
    visitorProfile.mobileNumber !== undefined
  ) {
    mobileNumber = `+${visitorProfile.mobileNumber.replace(
      /[^a-zA-Z\d]/g,
      '',
    )}`;

    mobileNumber = parsePhoneNumber(mobileNumber).format('INTERNATIONAL') || '';
  }

  const data: DataType[] = [
    {
      title: t('common:full_name'),
      description: visitorProfile?.firstName
        ? `${visitorProfile.firstName} ${visitorProfile.lastName}`
        : visitorProfile?.lastName
        ? `${visitorProfile?.lastName}`
        : '',
    },
    {
      title: t('common:email'),
      description: visitorProfile?.email || '',
    },
    {
      title: t('common:phone_number'),
      description: mobileNumber,
    },
  ];

  const handleRefetch = React.useCallback(async () => {
    try {
      setLoading(true);
      dispatch(setIsLoadingVisitor(true));
      const visitorFound = await requestGetVisitorById({
        id: `${id}`,
      });

      if (visitorFound) {
        setVisitorProfile(visitorFound as IInvite);
        const item = await getItem();
        if (item) {
          const itemFilter = JSON.parse(item).filter(
            (i: IInvite) => i.id !== id,
          );

          setItem(JSON.stringify([...itemFilter, visitorFound]));
          setLoading(false);
          setTimeout(() => {
            dispatch(setIsLoadingVisitor(false));
          }, 500);
        }
      }
    } catch {
      setLoading(false);
      dispatch(setIsLoadingVisitor(false));
    }
  }, [id]);

  const handleDelete = React.useCallback(() => {
    try {
      hide();
      if (credential && visitorProfile) {
        dispatch(deleteInviteByUUID({ uuid: credential.uuid }));
        setTimeout(() => {
          handleRefetch();
        }, 3000);
      }
    } finally {
      dispatch(setAppLoading(false));
    }
  }, [credential, visitorProfile]);

  const renderItem = React.useCallback(
    ({ item }: { item: ICredential }) => {
      if (isLoadingVisitor) {
        return <Credential isLoading item={undefined} />;
      }

      return (
        <Credential
          item={item}
          onCancel={() => {
            setCredential(item);
            setTimeout(() => {
              show();
            }, 200);
          }}
          onShare={() => {
            if (visitorProfile) {
              const share: IShare = {
                activeDate: item.activeDate,
                expiryDate: item.expiryDate,
                uuid: item.uuid,
                firstName: visitorProfile.firstName,
                lastName: visitorProfile.lastName,
                mobileNumber: visitorProfile.mobileNumber || '',
                message: item.inviteMessage || '',
              };
              navigate('Invitations', {
                screen: 'InviteShare',
                params: { share },
              });
            }
          }}
        />
      );
    },
    [visitorProfile, isLoadingVisitor],
  );

  const listHeaderComponent = React.useCallback(() => {
    if (isLoadingVisitor) {
      return (
        <View style={styles.content}>
          <View style={styles.avatar}>
            <Skeleton colorMode={themes} height={120} width={120} radius={36} />
          </View>
          {data &&
            data.map((item, index) => {
              return (
                <View key={index} style={styles.input}>
                  <Skeleton colorMode={themes} height={16} width="40%" />
                  <View style={{ height: 12 }} />
                  <Skeleton colorMode={themes} height={16} width="80%" />
                </View>
              );
            })}
          <View style={{ height: 24 }} />
          <Skeleton colorMode={themes} height={48} radius={16} width="100%" />
          <View style={{ height: 16 }} />
        </View>
      );
    }
    return (
      <View style={styles.content}>
        {visitorProfile?.thumbnail ? (
          Platform.OS === 'web' ? (
            <Image
              source={{ uri: visitorProfile.thumbnail }}
              style={styles.avatar}
            />
          ) : (
            <ImageCache.Image
              uri={visitorProfile.thumbnail}
              style={styles.avatar}
            />
          )
        ) : (
          <UserAvatar
            size={120}
            style={styles.avatar}
            src="" // visitor avatar uri
            name={`${visitorProfile?.firstName} ${visitorProfile?.lastName}`}
            colors={[
              theme['color-primary-500'],
              theme['color-info-500'],
              theme['color-warning-500'],
              theme['color-danger-500'],
              theme['background-basic-color-6'],
              theme['background-basic-color-7'],
              theme['background-basic-color-8'],
              theme['background-basic-color-13'],
              theme['background-basic-color-14'],
            ]}
          />
        )}
        {data &&
          data.map((item, index) => {
            const { title, description } = item;
            return (
              <View key={index} style={styles.input}>
                <Text category="c1" status="placeholder">
                  {title}
                </Text>
                <Text marginTop={12} category="t3">
                  {description}
                </Text>
              </View>
            );
          })}
        <Button
          children={t('common:invite')}
          style={styles.buttonInvite}
          onPress={() => {
            if (visitorProfile) {
              navigate('Invitations', {
                screen: 'InviteNew',
                params: {
                  visitor: visitorProfile as IVisitor,
                  path: 'VisitorProfile',
                },
              });
            }
          }}
        />
      </View>
    );
  }, [visitorProfile, data, isLoadingVisitor]);

  return (
    <Container level="2">
      {/* @ts-ignore */}
      <TopNavigation
        style={styles.topNavigation}
        appearance="control"
        title={t('common:visitor_profile')}
        accessoryLeft={
          <View style={styles.header}>
            <NavigationAction
              onPress={() => navigation.navigate('VisitorsScreen')}
            />
            <UserItem isShow={false} />
          </View>
        }
        accessoryRight={
          <NavigationAction
            icon="edit_1"
            status="placeholder"
            onPress={() => {
              navigate('Visitors', {
                screen: 'VisitorNewUpdate',
                params: { id: visitorProfile?.id },
              });
            }}
          />
        }
      />
      <FlatList
        ListHeaderComponent={listHeaderComponent}
        data={
          isLoadingVisitor
            ? Array(4).fill(4)
            : visitorProfile?.credentials || []
        }
        renderItem={renderItem}
        showsVerticalScrollIndicator={false}
        scrollEventThrottle={16}
        refreshing={loading}
        onRefresh={handleRefetch}
        keyExtractor={keyExtractor}
        contentContainerStyle={{ paddingBottom: bottom + 32 }}
      />
      {!!credential && !!visitorProfile && (
        <ModalConfirm
          visible={visible}
          onBackdropPress={hide}
          title={`${t('common:confirm')}`}
          description={t('common:delete_invitation_for', {
            firstName: visitorProfile.firstName || '',
            lastName: visitorProfile.lastName || '',
          })}
          content={{
            activeDate: credential.activeDate,
            expiryDate: credential.expiryDate,
          }}
          buttonLeft={{ onPress: hide, title: t('common:cancel') }}
          buttonRight={{ onPress: handleDelete, title: t('common:ok') }}
        />
      )}
    </Container>
  );
};

export default VisitorProfile;

const styles = StyleSheet.create({
  input: {
    marginTop: 32,
  },
  content: {
    paddingHorizontal: 24,
  },
  buttonInvite: {
    marginTop: 24,
    marginBottom: 16,
  },
  avatar: {
    width: 120,
    height: 120,
    borderRadius: 36,
    alignSelf: 'center',
    marginTop: 40,
    marginBottom: 16,
    overflow: 'hidden',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  topNavigation: {
    marginTop: 12,
  },
});
